'use strict';

function ODataDataSourceProxy() { }

ODataDataSourceProxy.prototype = {
    init: function (id, url, datasourcename, datamodel, columns, pkey) {
        this.id = id;
        this.url = url;
        this.dataSourceName = datasourcename;
        this.datamodel = datamodel;
        this.columns = columns;
        this.primarykey = pkey;
    },

    /**
     * Funkcja tworzy dataSource dla kontrolki Tree
     * @param {boolean} paged - obecnie do niczego nie wykorzystywany
     * @param {function} onChangeFn - funkcja, która ma się wykonywać na zmianę w datasource
     * @param {string} keyField - Pole klucza
     * @param {string} parentField - Pole rodzica
     * @param {string} textField - Pole z któego ma być pobierana nazwa
     * @param {string} order - typ sortowania
     */
    TreeListDataSource: function (paged, onChangeFn, keyField, parentField, textField, order) {
        paged = !!paged;//convert undefined/null/false to false

        var entityUrl = this.url + this.dataSourceName;
        var self = this;
        this.datamodel.id = keyField;
        this.datamodel.fields[keyField].nullable = false;
        this.datamodel.fields[keyField].editable = false;

        //dodajemy w ktorym przekazujemy informacje na pola ktore trzyma informacje na temat rodzica
        this.datamodel.fields["parentId"] = {};
        // W przypadku gdy parentField jest typu string, Neos dla elementów najwyższego poziomu wysyła "". 
        // Aby drzewo dobrze się wyświetlało Kendo też musi się spodziewać "".
        if (this.datamodel.fields[parentField].type == "string"){
            this.datamodel.fields["parentId"].nullable = false;
            this.datamodel.fields["parentId"].defaultValue = "";
        } else {
            this.datamodel.fields["parentId"].nullable = true;    
        }
        this.datamodel.fields["parentId"].field = parentField;

        //na razie zawsze zakładamy, że ma dzieci

        var val = "";
        if (this.datamodel.fields[parentField].type == 'number')
            val = null;

        var customHeaders = App.communication.getOdataCustomHeaders();

        var datasource = {
            type: "odata-v4",
            transport: {
                read: {
                    url: entityUrl,
                    dataType: "json",
                    cache: false,
                    data : customHeaders
                }
            },
            schema: {
                model: this.datamodel
            },
            error: function (a) {
                console.error("TREE error: " + JSON.stringify(a));
            },
            requestEnd: function (e) {
            },
            sort: order,
            serverPaging: false,
            serverFiltering: true,
            serverSorting: true,
            change: onChangeFn
        };

        this.datasource = new kendo.data.TreeListDataSource(datasource);
        return this.datasource;
    },

    /**
     * Funkcja tworzy dataSource dla kontroleki flowchart
     * @param {boolean} paged - ilość rekordów na stronie grida
     */
    getFlowchartDataSource: function (schema, onChangeFn, onSyncFn, schemaParser, dataSerializer, filterFn) {
        var entityUrl = this.url + this.dataSourceName;

        var datasource = {
            type: "odata-v4",
            transport: {
                read: {
                    url: entityUrl,
                    dataType: "json",
                    cache: false,
                    data: function() {
                        var result = App.communication.getOdataCustomHeaders();
                        if(filterFn) {
                            filterFn(result);
                        }
                        return result;
                    }
                },
                update: {
                    url: entityUrl + "?" + $.param(App.communication.getOdataCustomHeaders()),
                    dataType: "text",
                    async: false,
                    beforeSend: function(req, opts) {
                        if(dataSerializer) {
                            opts.data = dataSerializer(opts.data);
                        }
                    }            
                },
                destroy: function(opts) {

                },
                create: function(opts) {

                }
            },
            change: onChangeFn,
            sync: onSyncFn,
            schema: {
                model: schema,
                data: function (data) {
                    if (!data.value || data.value.length == 0) {
                        return [];
                    }
                    else
                        return data.value;
                },
                parse: schemaParser
            },
            error: function (a) {
                console.error("GRID error: " + JSON.stringify(a));
            }
        };


        this.datasource = new kendo.data.DataSource(datasource);
        return this.datasource;
    },

    /**
     * Funkcja tworzy dataSource dla kontrolek (grid, combobox, dropdown, checkcombobox)
     * @param {boolean} paged - ilość rekordów na stronie grida
     * @param {function} onChangeFn - funkcja, która ma się wykonywać na zmianę w datasource
     * @param {string} groups - obiekt konfigurujący grupowanie w gridzie
     * @param {string} order - typ sortowania
     * @param {string} onErrorFn - funkcja, która ma się wykonywać na wystąpienie błędu w datasource
     * @param {string} pageSize - ilość pozycji na stronie
     */
    getKendoDataSource: function (paged, onChangeFn, groups, order, onErrorFn, pageSize) {
        paged = !!paged;//convert undefined/null/false to false

        var entityUrl = this.url + this.dataSourceName;

        //TODO: czy poprawić ?
        this.datamodel.id = this.primarykey;
        this.datamodel.fields[this.primarykey].nullable = true;
        this.datamodel.fields[this.primarykey].editable = false;

        for (var property in this.datamodel.fields) {
            this.datamodel.fields[property].editable = true;
        }

        var aggregatedFields = getAggregateFunc(this.datamodel.fields);

        var datasource = {
            type: "odata-v4",
            transport: {
                read: {
                    url: entityUrl,
                    dataType: "json",
                    cache: false,
                    data: function() {
                      return App.communication.getOdataCustomHeaders();
                    }
                }
            },
            schema: {
                model: this.datamodel,
                data: function (data) {
                    if (!data.value || data.value.length == 0) {
                        if (onErrorFn) {
                            onErrorFn();
                        }
                        return [];
                    }
                    else
                        return data.value;
                },
                errors: "error"
            },
            error: function (a) {
                console.error("GRID error: " + JSON.stringify(a));
            },
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            sort: order,
            change: onChangeFn,
            aggregate: aggregatedFields
        };

        if (paged)
           datasource.pageSize = parseInt(pageSize ? pageSize : App.Settings.ODataPageSize);

        if (groups)
            datasource.group = groups;

        this.datasource = new kendo.data.DataSource(datasource);
        this.datasource.aggregatedFields = aggregatedFields;
        return this.datasource;
    }
}

function getAggregateFunc(fields) {
    var agr = [];
    for (var f in fields) {
        if (fields[f].type == "number") {
            agr.push({ field: f.toString(), aggregate: "sum" });
        }
    }
    return agr;
}

function ODataProxy() { }

ODataProxy.prototype = {

    init: function (url) {
        this.serviceUrl = url;
        this.serviceMetadataUrl = url + "/$metadata";
        this.cache = new Hashmap(
        function (item) {
            return item.id;
        });
    },

    mapEdmToType: function (edmdatatype) {
        switch (edmdatatype) {
            case 'Edm.Int16':
            case 'Edm.Int32':
            case 'Edm.Double':
            case 'Edm.Single':
            case 'Edm.Decimal':
                return { name: 'number', defaultValue: 0 };
                break;
            case 'Edm.DateTimeOffset':
                return { name: 'date', defaultValue: '' }
                break;
            case 'Edm.String':
            default:
                return { name: 'string', defaultValue: '' };
                break;
        }
    },

    _build: function (data, entityName, entityType) {
        var split = entityType.split('.');
        var namespace = split[0];
        var type = split[1];
        var idno = entityName.substring(15);
        if (idno) {
            var entityTypes = data.find("Schema[Namespace='" + namespace + "']");
            if (entityTypes.length > 0) {
                entityTypes = entityTypes.find("EntityType[Name='" + type + "']");
                if (entityTypes.length > 0) {
                    var properties = $("Property", entityTypes);
                    var keys = $("Key>PropertyRef", entityTypes);

                    var columns = [];
                    var model = { fields: {} };

                    for (var i = 0; i < properties.length; i++) {
                        var $p = $(properties[i]);
                        var colname = $p.attr('Name');
                        var coltype = $p.attr('Type');
                        var nullable = $p.attr('Nullable');
                        if (nullable && nullable === 'false') {
                            nullable = false;
                        } else nullable = true;

                        columns.push(colname);
                        var type = this.mapEdmToType(coltype);
                        var descobject = { type: type.name, nullable: nullable };

                        if (!nullable) {
                            descobject.defaultValue = type.defaultValue;
                        }
                        model.fields[colname] = descobject;
                    }

                    var pkey;

                    if (keys.length != 1)
                        console.warn("SGRID: Kendo support only sipmple primary keys, first will be used.");
                    else {
                        pkey = $(keys[0]).attr('Name');
                    }

                    var dsproxy = new ODataDataSourceProxy();
                    dsproxy.init(idno, this.serviceUrl, entityName, model, columns, pkey);
                    this.cache.add(dsproxy);
                }
            }
        }
    },



    _fetchMetadata: function(onSuccess) {
        var self = this;
        var customHeaders = App.communication.getOdataCustomHeaders() 
        $.ajax({
            async: false,
            url: self.serviceMetadataUrl,
            converters: { "text xml": jQuery.parseXML },
            dataType: "xml",
            headers: customHeaders,
            success: onSuccess
        })
    },

    build: function(id) {

        if(this.lastRequest) {
            if(this._searchAndBuild(this.lastRequest,id))
                return;
        }

        var self = this;
        this._fetchMetadata(function (data) {
            var $data = $(data);
            if(typeof data !== 'undefined')
            {
                  self.lastRequest = $data
                  self._searchAndBuild($data,id)
            }
        });
    },

    _searchAndBuild : function(data, id) {
        var name = "DATASETINSTANCE"+id;
        var entitySets = data.find("EntitySet[Name='"+name+"']");
        if(entitySets.length==1) {
            var entityType = $(entitySets[0]).attr("EntityType");
            this._build(data, name, entityType);
            if(App.debug.odata.stat) {
                App.debug.odata.statdata.l2++;
            }
            return true;
        } else {
            if(App.debug.odata.stat) {
                App.debug.odata.statdata.miss++;
            }
            //to że nie znaleziono to niekoniecznie jest błąd
            //komentuje, żeby ludzi nie myliło, jednocześnie zostawiam, bo
            //to może być błąd
            //console.info("Znaleziono "+entitySets.length+" źródeł danych "+name);
        }
        return false;
    },

    buildAll : function () {
        if (this.cache)
            this.cache.clear();

        var self = this;
        this._fetchMetadata(function (data) {
            var $data = $(data);
            var entitySets = $data.find("EntitySet");
            for (var i = 0; i < entitySets.length; i++) {
                var entityName = $(entitySets[i]).attr("Name");
                var entityType = $(entitySets[i]).attr("EntityType");
                self._build($data, entityName, entityType);
            }
        });
    },

    getAll: function () {
        if (this.cache)
            return this.cache.getAll();
    },

    get: function (id) {
        if(App.debug.odata.stat) {
            if(typeof App.debug.odata.statdata == 'undefined')
            {
                App.debug.odata.statdata = {
                    found:0,
                    notfound:0,
                    l1:0,
                    l2:0,
                    miss:0
                };
            }
        }

        var ret;
        if (this.cache)
            ret = this.cache.getValue(id);
        if(!ret) {
            this.build(id);
            ret = this.cache.getValue(id);
        } else {
            if(App.debug.odata.stat) {
                App.debug.odata.statdata.l1++;
            }
        }
        return ret;
    },

    /**
     * Metoda usuwa dane źródło danych z cache'a
     * @param  {string} id Id źródła jest do usunięcia
     */
    remove: function(id) {
        if(this.cache) {
            this.cache.remove({id:id});
        }
    },
}

'use strict';

var FormStack = {
    windowForms: [],
    stackForms: [],
    bufferedForms: [],

    init: function (navigator) {
        this.navigator = navigator;
    },

    isEmpty:function() {
      return this.windowForms.length==0 && this.stackForms.length==0;
    },

    callStaticMethod: function (objectname, methodname, parameters, xmlparameters) {
      App.communication.BP.staticMethod(objectname, methodname, parameters, xmlparameters);
    },

    addWindowToStack: function (id, formStyle, formXml, formUUID, messageId, mode) {
        //ustalamy jaki rodzaj okna będzie uruchomiony
        var targetObject;

        var style = App.gui.currentCss.attr('href');
        if (style.indexOf("tablet") >= 0) {
            //if (App.gui.device.tablet || App.gui.device.mobile) {
            targetObject = SMDIForm;
        }
        else if (mode == "D") {
            /*if (App.gui.device.tablet || App.gui.device.mobile) {
                targetObject = SDictMobileForm;
            }
            else {*/
            if (!App.gui.mobileVersion) {
                if (messageId && $("[actionInstanceId='" + messageId + "']").parent().is('.SBUTTON')) {
                    targetObject = SDictForm;
                } else {
                    targetObject = SWindowForm;
                }
            } else {
                targetObject = SMobileWindowForm;
            }
            //}
        }
        else if (formStyle != "M") {
            if (formStyle == "G") {
              if (!App.gui.mobileVersion)
                  targetObject = SGlobalSearchWindowForm;
              else
                  targetObject = SMDIForm;
            } else
            if (!App.gui.mobileVersion)
                targetObject = SWindowForm;
            else
                targetObject = SMobileWindowForm;
        }
        else {
            targetObject = SMDIForm;
        }

        //tworzymy nową formę i podpinamy pod DOM
        var newForm = Object.create(targetObject);
        newForm.init(formXml, id, formUUID);
        newForm.render(false, messageId);
        if (formStyle == "G") {
          App.gui.searchForm = newForm;
          newForm.afterRender.push(function() {
            App.globalSearch.setFocus();
          })
        }
        newForm.doAfterRender();

        this.appendWindowToStack(newForm,
          targetObject == SMDIForm || targetObject == SMobileWindowForm,
          targetObject == SWindowForm || targetObject == SGlobalSearchWindowForm
        )
    },

    appendWindowToStack: function(newForm, ismdi, iswindow) {
        //jeżeli nowa forma nie jest MDI to dodajemy ją do stosu
        if (ismdi) {
            this.stackForms.push(newForm);

            //odpinamy biezaca forme
            if (this.currentMDIForm) {
                this.currentMDIForm.detach();
                this.currentMDIForm.stackCurrentForm = false;
            }

            this.currentMDIForm = newForm;
            this.currentMDIForm.stackCurrentForm = true

            App.gui.navigator.addFormTab(newForm); //dodajemy zakładkę dla formy
            App.gui.navigator.switchFormTab(newForm.formInstanceID);

            //ustawiamy nazwę formy na toolbarze
            if (Object.getPrototypeOf(this.navigator) !== SLooseNavigator)
                this.navigator.showFormName(newForm.caption || newForm.label || " ");
        } else
        if (iswindow) {
            this.windowForms.push(newForm);
        }
        
        //rejestrujemy konieczność obsługi przycisku wstecz, bo może posłuzy on do zamknięcia nowo otwartej formy
        App.historyHelper.captureBackButton();

        newForm.objectDOM.trigger('appendWindowToStack')
    },

    getPosition: function (collection, form) {
        for (var i = 0; i < collection.length; i++) {
            if (collection[i].formInstanceID == form.formInstanceID) {
                return i;
            }
        }

        //console.info("Nie znaleziono formy w podanej kolekcji: " + collection);
        return -1;
    },

    findForm: function (id) {
        var form;
        var result = {}
        result.form;
        result.type;

        //szukamy w buforowanych
        form = $.grep(this.bufferedForms, function (e) { return (e.formInstanceID == id) });
        if (form.length > 0) {
            result.form = form[0];
            result.type = "BUFFERED";
            return result;
        }

        //szukamy formy na stosie
        form = $.grep(this.stackForms, function (e) { return (e.formInstanceID == id) });
        if (form.length > 0) {
            result.form = form[0];
            result.type = "MDI";
            return result;
        }

        //jeśli nie znaleziono na stosie formy to szukamy w okienkach
        if (form.length < 1) {
            form = $.grep(this.windowForms, function (e) { return (e.formInstanceID == id) });

            if (form.length > 0) {
                result.form = form[0];
                result.type = "WINDOW";
                return result;
            }
        }

        //jesli nie znaleziono formy na stosie i w okienkach to szukamy w słownikach wszystkich form
        if (form.length < 1) {
            form = this.findDictForm(id);
            if (form.length > 0) {
                result.form = form[0];
                result.type = "DICT";
                return result;
            }
        }

        if (form.length > 1) {
            console.error("Znaleziono więcej niż jedną formę o id = " + id + ". Została zwrócona pierwsza znaleziona forma w funkcji findForm");
        }

        return result;
    },

    findDictForm: function(id) {
        var result = [];

        //szukamy słownika w formach mdi
        result = this.findDictFormInCollection(this.stackForms, id);

        //szukamy słownika w formach okienek
        if (result.length < 1) {
            result = this.findDictFormInCollection(this.windowForms, id);
        }

        if (result.length > 1) {
            console.error("Znaleziono więcej niż jeden słownik o id: " + id + ". Została zwrócony pierwszy znaleziony słownik w funkcji findDictForm");
        }

        return result;
    },

    findDictFormInCollection: function (colection, id) {
        var result = [];
        var len = colection.length;

        for (var i = 0 ; i < len ; i++) {
            var dict = [];
            var form = colection[i];
            dict = $.grep(form.dictForms, function (e) { return (e.formInstanceID == id) });

            $.merge(result, dict)
        }

        return result;
    },

    showWindowFromStack: function (form) {
        var index = this.getPosition(this.stackForms, form);
        if (index >= 0) {
            //uruchamiamy jakąś formę z środka stosu
            if (index < this.stackForms.length - 1) {
                this.stackForms.splice(index, 1);
                this.stackForms.push(form);
                //ukrywamy cssem aby nie robic relayoutu
                //form.objectDOM.css("display", "none");
                this.currentMDIForm.detach();
                //this.currentMDIForm.stackCurrentForm = false;
            }

            this.currentMDIForm = form;
            this.currentMDIForm.stackCurrentForm = true;
            this.currentMDIForm.attach();
            this.layoutCurrentForm();
            App.gui.navigator.switchFormTab(form.formInstanceID); //ustawiamy aktywną zakładkę
        }
        else {
            console.error("Nie znaleziono formy na stosie.");
        }

        //ustawiamy nazwę formy na toolbarze
        if (Object.getPrototypeOf(this.navigator) !== SLooseNavigator)
            this.navigator.showFormName(form.caption || form.label || " ");
    },

    closeForm: function (id, symbol) {
        var obj = this.findForm(id);
        var form = obj.form;
        var type = obj.type;

        if (form) {
            if(form.buffered == "Y" && symbol != "CLOSEBUFFERED" && form.formStyle != "S" && form.formStyle != "D") {
                this.bufferedForms.push(form);
                form.detach();
            } else {
                form.close();
                var idx = this.getPosition(this.bufferedForms, form);
                if(idx>-1) {
                    this.bufferedForms.splice(idx,1)
                }
            }

            var skipwarning = false;
            if(type == "BUFFERED") {
                if(form.formStyle == "M" || App.gui.mobileVersion){
                    type = "MDI";
                } else {
                    type = "WINDOW";
                }
                skipwarning = true;
            }

            if (type == "MDI") {
                //usuwanie formy z kolekcji
                var index = this.getPosition(this.stackForms, form);
                var len = this.stackForms.length;
                if (index >= 0) {
                    //usuwamy z stosu formę
                    App.gui.navigator.removeFormTab(form.formInstanceID); //usuwamy zakłądkę formy, którą zamykamy
                    this.stackForms.splice(index, 1);

                    //sprawdzamy czy zamykana forma jest pierwsza na stosie
                    if (index == len - 1 && this.stackForms.length > 0) {
                        //wyświetlamy kolejną formę z stosu
                        var nextForm = this.stackForms[len - 2];
                        this.showWindowFromStack(nextForm);
                    }
                } else {
                    if(!skipwarning) {
                        console.error("Nie znaleziono formy dla danego okienka");
                    }
                }
                if (this.stackForms.length == 0) {
                  var middlePaneHeight = $("#middle-pane").height() || 0;
                  var footerHeight = $('.footer').height() || 0;
                  var margin_top = middlePaneHeight - footerHeight;
                    $('.footer').css('margin-top', margin_top);
                }
            }
            else if (type == "DICT") {
                //Jeżeli została znaleziona forma a jej pozycja jest -1 oznacza to że jest to słownik
                if (form.dictParentForm) {
                    var index = this.getPosition(form.dictParentForm.dictForms, form);
                    $(obj.form.dictElement).data("SObject").tooltipVisible = true;
                    if (index >= 0) {
                        form.dictParentForm.dictForms.splice(index, 1);
                    }

                }
                else {
                    console.error("Nie znaleziono formy dla której został otworzony słownik");
                }
            }
            else if (type == "WINDOW") {
                var index = this.getPosition(this.windowForms, form);

                if (index >= 0) {
                    this.windowForms.splice(index, 1);
                }
                else {
                    if(!skipwarning) {
                        console.error("Nie znaleziono formy dla danego okienka");
                    }
                }
            }
            else {
                if(!skipwarning) {
                    console.error("Nieznany typ formy");
                }
            }

            if (this.stackForms.length == 0) {
                if (Object.getPrototypeOf(this.navigator) !== SLooseNavigator)
                    this.navigator.showFormName(" ");

                this.navigator.hideBackButton();
                this.currentMDIForm = undefined;
            }

            if(form.formStyle == "G") {
              if(App.gui.searchForm)
              {
                App.gui.searchForm = undefined
                if(App.gui.mobileVersion)
                  App.globalSearch.visible(false)
              }
            }
        } else {
            console.error("Nie znaleziono formy o id:" + id + " podczas próby zamknięcia okna");
        }
    },

    /*
    * Metoda zwraca okno, które obecnie jest najbardziej na wierzchu
    */
    getTopmostForm: function () {
      var self = this;
      var minzIndex = 0;
      var topmostForm = undefined;
      //weryfikuje czy mam uruchomione okna typu message
      //jesli tak to nic nie zwracam
      if($('.message').length > 0)
        return undefined;
      //najpierw szukamy otwartej formy słownika
      if(!topmostForm) {
          var anydictform = $('.SDICTFORM');
          if (anydictform.length > 0) {
            topmostForm = $(anydictform[0]).data("SObject");
          }
      }
      //jeśli nie ma słownika to szukamy okna modalnego o nawyższym zindex
      if (!topmostForm) {
        $('.k-window').each(function () {
          if (this.style.zIndex > minzIndex) {
            minzIndex = this.style.zIndex;
            topmostForm = $($(this).find(".SWINDOWFORM")[0]).data("SObject");
          }
        });
      }
      if (topmostForm)
        return topmostForm;
      //jeśli nic nie znaleziono to zwracamy bieżącą formę MDI
      return this.currentMDIForm;
    },

    back: function () {
        //zamykamy wszystkie słowniki dla danej formy
        if (this.currentMDIForm) {
            //<BS79316 to forma powinna decydować co się ma dziać przy zamknięciu
            //a to się wykona jak serwer odeśle polecenie zamknięcia formy
            //</BS79316>
            App.communication.BP.closeForm(this.currentMDIForm.formInstanceID);
        }
    },

    showForm: function (id, uuid, formXml, formUUID, messageId) {
        // obsługa callbacka dla nawigatora do zrobienia - sprawdzic czy potrzebna
        App.gui.resizeMiddlePane();
        var formStyle = $(formXml).attr("FS");
        var mode = $(formXml).attr("M");
        var obj = this.findForm(id)
        var form = obj.form;
        var type = obj.type;
        var isProfileNameVisible = this.stackForms.length == 0;

        //sprawdzam czy istnieje juz taka forma
        if (!form) {
            //tworzymy nową formę
            this.addWindowToStack(id, formStyle, formXml, formUUID, messageId, mode);
        }
        else {
            //uruchamiamy już istniejącą formę
            if (type == "MDI") {
                this.showWindowFromStack(form);
            } else
            if (type == "WINDOW") {
                //czy potrzeba to opragromywać - zastanowić się
            } else
            if (type == "BUFFERED") {
                form.attach();
                var ismdi = form.formStyle == "M" || App.gui.mobileVersion;
                var iswindow = form.formStyle == "D" || form.formStyle == "S"
                  || form.formStyle == "F" || form.formStyle == "G"
                this.appendWindowToStack(form, ismdi, iswindow)
                var position = this.getPosition(this.bufferedForms, form)
                this.bufferedForms.splice(position,1)
                if(form.formStyle == "G") {
                  if(!App.gui.searchForm)
                  {
                    App.gui.searchForm = form
                  }
                }
            } else {
                console.info("Nieobsługiwany typ formy "+type)
            }
        }

        if (isProfileNameVisible && this.stackForms.length > 0) {
            if (App.gui.mobileVersion) {
                this.navigator.showBackButton();
            } else {
                this.navigator.hideProfileName();
            }
        }
    },

    checkIfAppended: function (id) {
        return jQuery("[fiid='" + id + "']").length > 0;
    },

    /**
     * Funkcja zamyka wszyskie formy MDI i wolne
     * @param {bool} forceclose - jeżeli ustawiony na true to zamykamy okna bez pytania o to uzytkownika
     */
    closeAllForms: function (forceclose) {
        //tu jest ok, bo chcemy zamykać te formy tylko po stronie serwera
        //bo w kliencie po przeładowaniu i tak ich nie ma
        var clone = this.stackForms.slice();
        var len = clone.length;

        for (var i = 0; i < len; i++) {
            var form = clone[i];
            App.communication.BP.closeForm(form.formInstanceID, forceclose);
        }

       clone = this.windowForms.slice();
       len = clone.length;

        for (var i = 0; i < len; i++) {
            var form = clone[i];
            App.communication.BP.closeForm(form.formInstanceID, forceclose);
        }

        this.currentMDIForm = undefined;
    },

    layoutCurrentForm: function () {
      //zmieniono szerokosc nawigatora wiec bezwzglednie layoutujemy widoczną formę MDI
        if (this.currentMDIForm) {
            this.currentMDIForm.refreshLayout();
        }
    },

    prepareAllFormsFromCollection: function(collection) {
        var formsAmount = collection.length;

        for (var i = 0; i < formsAmount; i++) {
            var form = collection[i];
            var dictformsAmount = form.dictForms.length;

            for (var j = 0; j < dictformsAmount; j++) {
                var dictform = form.dictForms[j];
                dictform.needLayout = true;
            }

            form.needLayout = true;
        }
    },

    prepareAllFormsForReLayout: function () {
        this.prepareAllFormsFromCollection(this.stackForms);
        this.prepareAllFormsFromCollection(this.windowForms);
    },

    /**
     * Funkcja wyświetla formę MDI o zadanym ID instancji
     * @param {string} formInstanceID - ID instacji formy którą chcemy wyświetlić
     */
    showMDIFormWithInstanceID: function (formInstanceID) {
        var form = this.findForm(formInstanceID);
        if (form.type == "MDI") {
            this.showWindowFromStack(form.form);
          }
    }
}


'use strict'
//historyHelper - obiekt pozwalajacy na zarzadzanie historia

var HistoryHelper = {
    initialized: false,
    backButtonCaptured: false,
    
    /**
    * Funkcja INIT inicjujaca obiekt oraz nadpisujaca zdarzenie zmiany historii przegladania
    */
    init: function(){
      if (!this.initialized) {
        //Nadpisanie zdarzenia wlasna funkcja do pobierania ze stosu. Obsluga HistoryAPI
        window.onpopstate = this.popstate;
        this.initialized = true;
      }
    },

    /**
    * Funkcja przejmuje przycisk "Wstecz" przeglądarki na jego jednorazowe naciśnięcie
    */
    captureBackButton: function() {
      // Dodaje wpis aby pokazac przycisk wstecz    
      if (!this.backButtonCaptured) {
        //window.history.pushState(0, null, window.location.origin);
        window.history.pushState(NaN,NaN);
        this.backButtonCaptured = true;
      }
    },

    /**
    * Funkcja wyszukuje okna typu message i zwraca obiekt kendoWindow
    */
    searchMessageWindow: function(){
        var kMessageForm = undefined;
        kMessageForm = $('.message').data("kendoWindow");
        return kMessageForm
    },

    /**
    * Funkcja wywoływana na event zarządzania historią przeglądania. Back, Forward
    */
    popstate: function (event) {
      //zaznaczamy, że przycisk "Wstecz" już nie jest przejęty przez tą funkcję
      App.historyHelper.backButtonCaptured = false;
      //szukamy formy MDI
      var form = FormStack.getTopmostForm();
      if(form != null) {
        App.communication.BP.closeForm(form.formInstanceID);
      } else {
        // gdy nie znalazlem formy to sprawdz czy mam message aby go pokazac
        var kMessageForm = HistoryHelper.searchMessageWindow();
        // jesli istnieje message to pokaz go na pierwszy plan
        if (kMessageForm != null) {
          kMessageForm.toFront();
        } else {
          // wszystkie okna zostały już wcześniej pozamykane, a znów naciśnięto "Wstecz"
          App.gui.showBalloonHint("Naciśnij ponownie 'Wstecz' aby wyjść");
          //tutaj już nie robimy captureBackButton, aby kolejne "Wstecz" zamknęło przeglądarkę
          return;
        }
      }
      //przejmujemy "Wstecz" na kolejne naciśnięcie
      App.historyHelper.captureBackButton();
    }
}
'use strict';

var KendoFixes = {
    //w kendo Q1 2015 jest babol (Q2 2014 w też był) w combo
    //jeżeli w combo wyświetlimy jako pole tekstowe pole klucza (koniecznie musi być typu int),
    //czyli mamy obiekt O pole P w relacji do obiektu O' po polu O'.REF.
    //obiekt O' ma też pole tekstowe O'.TEXT
    //i na formę O wystawiamy pole P jako dwa combo, jedno pokazujące O'.REF jako WARTOŚĆ
    //drugie pokazujące O'.TEXT jako wartość
    //to przy zmianie tej wartości w pierwszym combo, w drugim combo pojawi się wartość klucza wpisana jako WARTOŚĆ
    //to źle zostanie ustawiony selectedIndex przez co funkcja
    //pobiera złą wartość, która jest przekazywana do kontrolek przez model
    //
    //inaczej
    // 1 - aaa
    // 2 - bbb
    //w pierwszym combo po REF zmieniasz wybór z 1 na 2
    //w drugim combo zamiast 'bbb' widać 2
    //wszystko przez porównanie w komentarzu poniżej
    kendoComboBoxText: function (text) {
        text = text === null ? '' : text;
        var that = this;
        var input = that.input[0];
        var ignoreCase = that.options.ignoreCase;
        var loweredText = text;
        var dataItem;
        var value;
        if (text === undefined) {
            return input.value;
        }
        if (that.options.autoBind === false && !that.listView.bound()) {
            that._setText(text);
            return;
        }
        dataItem = that.dataItem();
        if (dataItem && that._text(dataItem) == text) { //tutaj było === text
            value = that._value(dataItem);
            if (value === kendo.ui.List.unifyType(that._old, typeof value)) {
                that._triggerCascade();
                return;
            }
        }
        if (ignoreCase && !that.listView.value().length) {
            loweredText = loweredText.toLowerCase();
        }
        that._select(function (data) {
            data = that._text(data);
            if (ignoreCase && !that.listView.value().length) {
                data = (data + '').toLowerCase();
            }
            return data === loweredText;
        }).done(function () {
            if (that.selectedIndex < 0) {
                input.value = text;
                if (that.options.syncValueAndText) {
                    that._accessor(text);
                }
                that._triggerCascade();
            }
            that._prev = input.value;
        });
        
        //funkcja wzięta z obiektu List
        function unifyType(value, type) {
            if (value !== undefined && value !== '' && value !== null) {
                if (type === 'boolean') {
                    value = Boolean(value);
                } else if (type === 'number') {
                    value = Number(value);
                } else if (type === 'string') {
                    value = value.toString();
                }
            }
            return value;
        }
    },
    
    //Nadpisujemy funkcję kendo aby sortować elemnty po stronie klienta
    //gdy mamy włączone grupowanie
    kendoGridDataSourceProcess: function (data, e) {
        var that = this,
        options = {},
        result;
        
        if (that.options.serverPaging !== true) {
            options.skip = that._skip;
            options.take = that._take || that._pageSize;
            
            if (options.skip === undefined && that._page !== undefined && that._pageSize !== undefined) {
                options.skip = (that._page - 1) * that._pageSize;
            }
        }
        
        if (that.options.serverSorting !== true) {
            options.sort = that._sort;
        }
        
        if (that.options.serverFiltering !== true) {
            options.filter = that._filter;
        }
        
        if (that.options.serverGrouping !== true) {
            options.group = that._group;
        }
        
        if (that.options.serverAggregates !== true) {
            options.aggregate = that._aggregate;
            that._aggregateResult = that._calculateAggregates(data, options);
        }
        
        result = that._queryProcess(data, options);
        
        that.view(result.data);
        
        if (result.total !== undefined && !that.options.serverFiltering) {
            that._total = result.total;
        }
        
        e = e || {};
        
        e.items = e.items || that._view;
        
        //Domyślnie było that.trigger(CHANGE, e);
        //trzeba uważać przy aktualizacji czy zmienna
        //CHANGE nie zmieniła wartości
        that.trigger("change", e);
    },
    
    //Kiedy klikamy po gridzie czasami serwer w odpoweidzi wysyła nam komunikaty, które powodują
    //odświeżenie formy. Skutkuje to tym że jest robiony layout kontrolki grid który wywołuje
    //funkcję refresh na kednoGridzie. Wyniku tego rekord który klikneliśmy ustawia się na dole
    //kontrolki co daje efekt skakania. Aby tego problemu się pozbyć nadpisaliśmy funkcję _scrollCurrent
    //kendoGrida dodając flagę od której zależy czy będzie wykonywany kod odpowiedzialny za
    //scrollowanie grida.
    
    //Ponadto na stałe wykomentowany został kod odpowiedzialny za scrollowanie do aktualnej komórki (w poziomie).
    //Ponieważ gdy komórka wystawała poza grida, to kliknięcie na nią powodowało przesunięcie poziomego scrolla.
    kendoGridScrollCurrent: function () {
        var current = this._current;
        var scrollable = this.options.scrollable;
        if (!current || !scrollable || this.disableAutoScroll) { // dodanie flagi disableAutoScroll
            return;
        }
        var row = current.parent();
        var tableContainer = row.closest('table').parent();
        var isInLockedContainer = tableContainer.is('.k-grid-content-locked,.k-grid-header-locked');
        var isInContent = tableContainer.is('.k-grid-content-locked,.k-grid-content,.k-virtual-scrollable-wrap');
        var scrollableContainer = $(this.content).find('>.k-virtual-scrollable-wrap').addBack().last()[0];
        if (isInContent) {
            if (scrollable.virtual) {
                var rowIndex = Math.max(inArray(row[0], this._items(row.parent())), 0);
                this._rowVirtualIndex = this.virtualScrollable.itemIndex(rowIndex);
                this.virtualScrollable.scrollIntoView(row);
            } else {
                this._scrollTo(this._relatedRow(row)[0], scrollableContainer);
            }
        }
        if (this.lockedContent) {
            this.lockedContent[0].scrollTop = scrollableContainer.scrollTop;
        }
        //wykomentowanie kodu odpowiedzialnego za scrollowanie do aktualnej komórki (w poziomie)
        //if (!isInLockedContainer) {
        //	this._scrollTo(current[0], scrollableContainer);
        //}
    },
    
    
    positionColumnResizeHandle: function() {
        var that = this,
        indicatorWidth = that.options.columnResizeHandleWidth,
        lockedHead = that.lockedHeader ? that.lockedHeader.find("thead:first") : $();
        
        that.thead.add(lockedHead).on("mousemove" + ".kendoGrid", "th", function (e) {
            var th = $(this);
            if (th.hasClass("k-group-cell") || th.hasClass("k-hierarchy-cell")) {
                return;
            }
            that._createResizeHandle(th.closest("div"), th);
        });
  },

  //BS138027 - Błąd w kendo przy htmledit tylko do odczytu przy wolnym ładowaniu dziedziny
  //Próba ustawienia nowej wartości w modelu, w momencie gdy jesteśmy przełączeni na inna zakładke 
  //kończy się błędem, bo głębiej w kendo wywołanie kendo.ui.editor.Dom.windowFromDocument(this.document)
  //zwraca undefined. Dlatego w takiej sytuacji lepiej nie wywoływać refreshTools
  kendoEditorValue: function (html) {
	var body = this.body, editorNS = kendo.ui.editor, options = this.options, currentHtml = editorNS.Serializer.domToXhtml(body, options.serialization);
	if (html === undefined) {
	  return currentHtml;
	}
	if (html == currentHtml) {
	  return;
	}
	editorNS.Serializer.htmlToDom(html, body, options.deserialization);
	this.selectionRestorePoint = null;
	this.update();
	if (kendo.ui.editor.Dom.windowFromDocument(this.document)) { //dodany warunek.
	  this.toolbar.refreshTools();
	}
    },

/**
 * Fix kontrolki kendo Slider aby prawidłowo obsługiwała zakres zmienno-przecinkowy. 
 * Kontrolka ta nie radzi sobie gdy jest ustawiana wartość z "," zamiast "." przez co 
 * Działa nieprawidłowo. Po zamianie "," na "." jest OK.
 */
  fixKendoSlider: function (slider) {
    var oldValue = slider.value;
    slider.value = function(value)
    {
    	if(value && typeof(value) == "string" && value.includes(","))
    	{
    		value = value.replace(",", ".");
    	}
        return oldValue.call(slider, value);
    };
 }
}

kendo.data.binders.customformat = kendo.data.binders.text.extend({
    init: function (element, bindings, options) {
        kendo.data.Binder.fn.init.call(this, element, bindings, options);
        
        this.customformat = $(element).data("customformat");
    },
    refresh: function () {
        var element = this.element;
        var path = this.bindings.customformat.path;
        var source = this.bindings.customformat.source;
		var format = "{0}";
		var val = source[path];
		
		if(val instanceof Date)
		{
			format = this.customformat;
		}      
		if(val !== null)
		{
			val = kendo.format(format, val);
		}
        $(element).text(val);
        $(element).val(val);
    }
});


'use strict';

var LocalStorage = {
    init: function () {
        if (typeof (Storage) !== "undefined") {
            return this;
        }
        else {
            console.warn("Twoja przeglądarka nie obsługuje Web Storage.");
            return undefined;
        }
    },

    set: function (key, value) {
        localStorage.setItem(key, value);
    },

    get: function (key) {
        return localStorage.getItem(key);
    },

    remove: function (key) {
        localStorage.removeItem(key);
    },

    setIfNotExist: function (key, value) {
        if (this.get(key) === null)
            this.set(key, value);
    },

    getAllKeys: function () {
        return Object.keys(localStorage);
    },

    getAllValues: function() {
        var allKeys = Object.keys(localStorage);
        var len = allKeys.length;
        var values = [];

        while (i--) {
            values.push(localStorage.getItem(keys[i]));
        }

        return values;
    },

    getValuesForKeysStartingWith: function (phrase) {
        var allKeys = Object.keys(localStorage);
        var len = allKeys.length;
        var selectedKeys = [];
        var values = [];

        while (len--) {
            var key = allKeys[len];
            if (key.indexOf(phrase) == 0)
                selectedKeys.push(key);
        }

        len = selectedKeys.length;
        while (len--) {
            values.push(localStorage.getItem(selectedKeys[len]));
        }

        return values
    }
}
'use strict';

var MessageTextValues = {
    SFile_Select: "Wybierz plik"
}
'use strict';

/**
* Obiekt do obsługi sessionStorage przeglądarki
*/
var RequestLog = {
    init: function () {
        var self = this;
        this.Logs = new Hashmap(function (log) {
            var key = self.getKeyName(log.profile, log.user, log.date);
            return key;
        });

        //Co minute zapis logów do Localstorage
        setInterval(function () {
            self.saveInLocalStorage();
        }, 60 * 1000);

        //co dzień usuwanie starych wpisów z localstorage
        setInterval(function () {
            self.clearLocalStorageFormOldLogs();
        }, 24 * 60 * 60 * 1000);

        return this;
    },

    /**
     * Dodanie nowego wpisu bądź aktualizacja istniejącego wpisu
     */
    add: function (good, error) {
        var keyName = this.getKeyName();
        var log;
        if (this.Logs.containsKey(keyName)) {
            log = this.Logs.getValue(keyName);
            this.updateLog(log, good, error);
        }
        else {
            log = this.createLog();
            this.updateLog(log, good, error);
            this.Logs.add(log);
        }
    },

    /**
     * Zapisanie wszystkich logów do LocalStorage
     */
    saveInLocalStorage: function () {
        var logs = this.Logs.getAll();
        var len = logs.length;
        for (var i = 0; i < len; i++) {
            var log = logs[i];
            if (!log.stored) {
                log.stored = true;
                var keyName = this.getKeyName(log.profile, log.user, log.date);
                App.localStorage.set(keyName, JSON.stringify(log));
            }
        }
    },

    /**
     * Funkcja uzupełnia obiekt js wpisami z localstorage
     */
    loadFromLocalStorage: function () {
        var logs = App.localStorage.getValuesForKeysStartingWith("log_");
        var len = logs.length;
        var addTimer = false;
        for (var i = 0; i < len; i++) {
            var log = JSON.parse(logs[i]);
            if (log.date == Utils.getCurrentDate() || log.send == true) {
                if (log.send)
                    addTimer = true;

                this.Logs.add(log);
            }
        }

        if (addTimer) {
            this.sendLogsToServer();
        }

        this.clearLocalStorageFormOldLogs();
    },

    /**
     * Funkcja usuwa z localstorage wszystkie logi które nie mają dzisiejszej daty
     * i zostały już wysłane na serwer
     */
    clearLocalStorageFormOldLogs: function () {
        var logs = App.localStorage.getValuesForKeysStartingWith("log_");
        var len = logs.length;
        var currentDate = Utils.getCurrentDate();
        for (var i = 0; i < len; i++) {
            var log = JSON.parse(logs[i]);
            if (log.date != currentDate && !log.send) {
                App.localStorage.remove(this.getKeyName(log.profile, log.user, log.date))
            }
        }
    },

    /**
     * FUnkcja wysyła logi do serwera
     */
    sendLogsToServer: function () {
        var logs = this.Logs.getAll();
        var len = logs.length;
        var Selected = {
            list: []
        }

        for (var i = 0; i < len; i++) {
            var log = logs[i];
            if (log.send == true) {
                Selected.list.push(log)
            }
        }

        App.communication.BP.requestLogs(JSON.stringify(Selected));


    },

    /**
    *  Pobranie nazwy klucza
    */
    getKeyName: function (profile, user, date) {
        if (typeof (profile) == "undefined" && typeof (user) == "undefined" && typeof (date) == "undefined") {
            profile = App.communication.getProfile();
            if(App.Settings) {
                user = App.Settings.UserName;
            } else {
                user = "unavailable";
            }
            date = Utils.getCurrentDate();
        }

        return "log_" + date + "_" + user + "_" + profile;
    },

    /**
     * Funkcja tworząca obiekt Loga
     */
    createLog: function () {
        var username = "unknown";
        if(App.Settings)
          username = App.Settings.UserName
        return {
            date : Utils.getCurrentDate(),
            user : username,
            profile : App.communication.getProfile(),
            good : 0,
            fail : 0,
            stored : false,
            send : false,
            errors : []
        }
    },

    /**
     * Aktualizacja loga o informacje o kolejnym requescie
     */
    updateLog: function (log, good, error) {
        if (good) {
            log.good++;
        }
        else {
            log.fail++;

            log.send = true;
            if (log.send) {
                this.addTimerSendToServer();
            }

            this.addErrorToLog(log, error);
        }

        log.stored = false;
    },

    /**
     * Funkcja służy do ustawienia wszystkim logom flagi send na false.
     * Wykorzystywana po udanym zapisie logów do pliku.
     */
    markLogsAsSent: function () {
        var logs = this.Logs.getAll();
        var len = logs.length;
        for (var i = 0; i < len; i++) {
            var log = logs[i];
            log.send = false;
        }
    },

    /**
     * Funkcja dodaje text błedu do kolekcji. W przypadku gdy w tekscie znajduje się
     * url to są wycinane z niego parametry, aby skrócić komunikat i uniknąć błędnego
     * zapytania podczas wysyłana logów na serwer.
     */
    addErrorToLog: function (log, error) {
        if (error && error != "") {
            var err = error.split("%3F")[0];
            log.errors.push(err);
        };
    },

    /**
     * Funkcja dodaje timer który cyklicznie próbuje zapisać logi do plików.
     * Po udanym zapisie timer jest usuwany.
     */
    addTimerSendToServer: function () {
        var self = this;
        if (!this.timerSendToServer && !App.login) {
            this.timerSendToServer = window.setInterval(function () {
                self.sendLogsToServer();
            }, 5 * 60 * 1000);
        }
    }
}

'use strict';


/**
* Obiekt do obsługi sessionStorage przeglądarki
*/
var SessionStorage = {
    /**
    * Funkcja inicializująca obiekt SessionStorage. Sprawdza czy nasza przegąldarka obsługuje Web Storage
    */
    init: function () {
        if (typeof (Storage) !== "undefined") {
            return this;
        }
        else {
            console.warn("Twoja przeglądarka nie obsługuje Web Storage.");
            return undefined;
        }
    },

    /**
    * Zapisuje wartość do sesionStorage
    */
    set: function (key, value) {
        sessionStorage.setItem(key, value);
    },

    /**
    * Pobiera wartość z sessionStorage
    */
    get: function (key) {
        return sessionStorage.getItem(key);
    },

    /**
    * Pobieranie wartości bool z storage
    */
    getBool: function (key) {
        var result = this.get(key);

        if (!result || result == "false")
            return false;
        else
            return true;
    }
}
/// <reference path="../jquery/jquery-1.10.1-vsdoc.js"/>
/// <reference path="../kendo/vsdoc/kendo.all-vsdoc.js"/>
'use strict';

var Utils = {
    showLoader: function () {
        $("body").css("cursor", "progress");
        $("#loader").show();
    },

    showProgress: function (fn, async) {
        if (async) {
            //App.gui.resizeMiddlePane();
            var ldr = $("#loader");

            //var navHeight = $("div#SNAVIGATOR").outerHeight();
            /*var navHeight = $("#menu-toggle").outerHeight();
            var htmlHeight = $("html").height();
            var htmlWidth = $("html").width();
            ldr.css({ "position": "fixed", "top": "-" + navHeight + "px" });
            ldr.width(htmlWidth);
            ldr.height(htmlHeight + navHeight);*/
            $("body").css("cursor", "progress");
            $("#loader").fadeIn(50)
            .queue(function () {
                fn();
                $(this).dequeue();
            });
        } else {
            fn();
        }
    },

    hideProgress: function () {
        $("#loader").hide();
        $("body").css("cursor", "default");
    },

    toFName: function (name) {
        if (name != App.Settings.ODataComplexKeyParameterName && name != App.Settings.ODataContextSearchParameterName && name) {
            if (name.indexOf("^") == -1) {
                name = App.Settings.ODataOrginalFieldsPrefix + name;
            }
            else {
                name = App.Settings.ODataDictFieldsPrefix + name.replace(/_/g, "__").replace("^", "_");
            }

        }
        return name;
    },

    fromFName: function (name) {
        if (name != App.Settings.ODataComplexKeyParameterName && name != App.Settings.ODataContextSearchParameterName && name) {
            name = name.substring(1);
            name = name.replace("_","^").replace("^^", "_");
        }
        return name;
    },

    transformName: function (name) {
        if (name) {
            var result = [];
            var previousIsFloor = false;
            var len = name.length;

            for (var i = 0; i < len; i++) {
                var c = name[i];
                if (c != "_") {
                    if (previousIsFloor) {
                        previousIsFloor = false;
                        result.push("^");
                    }

                    result.push(c);
                }
                else {
                    if (!previousIsFloor)
                        previousIsFloor = true;
                    else {
                        previousIsFloor = false;
                        result.push("_");
                    }
                }
            }
        }
        return result.join("");
    },

    extend: function (o1, o2) {
        var copy = Object.create(o1);
        var copy2 = Object.create(o2);
        var r = $.extend(copy, copy2);
        if (!r.__super)
            r.__super = [];
        r.__super.push({ obj: o2, base: o1 });
        r.__base = function (object) {
            if (typeof object === 'undefined') {
                return r.__super[0].base;
            }
            var len = r.__super.length;
            for (var i = 0; i < len; i++) {
                var item = r.__super[i];
                if (item.obj === object) {
                    return item.base;
                }
            }
            throw { messge: 'base not found', object: object };
        };
        copy.__parent = function (object) {
            if (typeof object === 'undefined') {
                return r.__super[0].obj;
            }
            var len = r.__super.length;
            for (var i = 0; i < len; i++) {
                var item = r.__super[i];
                if (item.base === object) {
                    return item.obj;
                }
            }
            throw { messge: 'base not found', base: object };
        };
        return r;
    },

    iconMap: {
      "ICON_1": "fa fa-check",
      "ICON_10": "sl3b-print-text",
      "ICON_11": "fa fa-copy overflowxfix",
      "ICON_12": "fa fa-copy overflowxfix",
      "ICON_13": "fa fa-bolt",
      "ICON_14": "fa fa-bolt",
      "ICON_2": "fa fa-times",
      "ICON_28": "sl3b-navigation-menu-1",
      "ICON_3": "fa fa-times",
      "ICON_30": "sl3b-receipt-1",
      "ICON_4": "fa fa-check",
      "ICON_48": "fa fa-lock",
      "ICON_49": "fa fa-question",
      "ICON_5": "fa fa-plus",
      "ICON_52": "sl3b-lock-unlock-1",
      "ICON_53": "fa fa-info-circle",
      "ICON_6": "fa fa-minus",
      "ICON_7": "sl3b-pencil",
      "ICON_8": "fa fa-search",
      "ICON_9": "sl3b-filter-1",
      "MI_1": "sl3b-common-file-empty",
      "MI_2": "sl3b-folder-open",
      "MI_3": "sl3b-floppy-disk-2",
      "MI_4": "fa fa-copy overflowxfix",
      "MI_5": "sl3b-scissors-2",
      "MI_6": "fa fa-caret-down",
      "MI_7": "sl3b-messages-bubble-square-question",
      "MI_8": "sl3b-copy-paste",
      "MI_9": "sl3b-print-text",
      "MI_AKCJE": "sl3b-envelope-postcard",
      "MI_ARCHCEN": "sl3b-tags-double",
      "MI_ARCHIVE": "sl3b-archive-folder",
      "MI_ARCHTOW": "sl3b-module-three-2",
      "MI_ARROWLEFT": "fa fa-arrow-left",
      "MI_ARROWRIGHT": "fa fa-arrow-right",
      "MI_ASORTYMENT": "sl3b-shipment-package",
      "MI_BANK": "sl3b-saving-bank",
      "MI_BANKS": "sl3b-saving-bank-international",
      "MI_BELL": "sl3b-alarm-bell-ring-1",
      "MI_BULLETGREEN": "fa fa-circle clgreen",
      "MI_BULLETRED": "fa fa-circle clred",
      "MI_BULLETYELLOW": "fa fa-circle clyellow",
      "MI_CENNIKI": "sl3b-tag-dollar-1",
      "MI_CHART": "sl3b-performance-increase",
      "MI_CIRCLEGREEN": "fa fa-circle clgreen bolder",
      "MI_CIRCLERED": "fa fa-circle clred bolder",
      "MI_CIRCLEYELLOW": "fa fa-circle clyellow bolder",
      "MI_CLO": "sl3b-road-sign-no-entry-alternate",
      "MI_CLOSE": "fa fa-times",
      "MI_CRM": "sl3b-office-business-card",
      "MI_CUBE": "sl3b-shape-cube",
      "MI_DASHBOARD": "sl3b-layout-dashboard",
      "MI_DATABASE": "sl3b-database-2-alternate",
      "MI_DIFFERENCE": "sl3b-angle-brackets",
      "MI_DOC": "sl3b-content-paper-edit",
      "MI_DOKMAG1": "sl3b-office-file-module-edit",
      "MI_DOKWIT": "sl3b-cloud-file",
      "MI_DOSTAWA": "sl3b-shipment-give",
      "MI_DOSTAWCY": "sl3b-project-building-shopping",
      "MI_DOWN": "fa fa-sort-down",
      "MI_ECOMMERCE": "sl3b-e-commerce-cart-browser",
      "MI_EDITALIGNCENTER": "sl3b-paragraph-center-align",
      "MI_EDITALIGNLEFT": "sl3b-paragraph-left-align",
      "MI_EDITALIGNRIGHT": "sl3b-paragraph-right-align-alternate",
      "MI_EDITBGCOLOR": "sl3b-font-size",
      "MI_EDITBOLD": "fa fa-bold bolder",
      "MI_EDITBULLETEDLIST": "sl3b-list-bullets-1",
      "MI_EDITBUTTON": "sl3b-keyboard-button",
      "MI_EDITCALC": "sl3b-accounting-calculator",
      "MI_EDITCHECK": "sl3b-check-square",
      "MI_EDITCOPY": "fa fa-copy overflowxfix",
      "MI_EDITCUT": "sl3b-scissors-2",
      "MI_EDITDATE": "sl3b-calendar-date",
      "MI_EDITFGCOLOR": "sl3b-text-options",
      "MI_EDITINDENTLEFT": "sl3b-indent-increase-1",
      "MI_EDITINDENTRIGHT": "sl3b-indent-decrease-1",
      "MI_EDITINSERTIMAGE": "sl3b-image-file-add",
      "MI_EDITINSERTLINK": "sl3b-hyperlink-1",
      "MI_EDITITALIC": "sl3b-text-italic",
      "MI_EDITLABEL": "sl3b-text-style",
      "MI_EDITMEMO": "sl3b-paragraph-normal",
      "MI_EDITNUMBEREDLIST": "sl3b-list-numbers",
      "MI_EDITPASTE": "sl3b-copy-paste",
      "MI_EDITREDO": "fa fa-undo fa-flip-horizontal",
      "MI_EDITTEXT": "sl3b-typing",
      "MI_EDITUNDERLINE": "sl3b-text-underline",
      "MI_EDITUNDO": "fa fa-undo",
      "MI_EMAILEDIT": "sl3b-email-action-edit",
      "MI_EMAILNEW": "sl3b-tag-new",
      "MI_EMAILOUT": "sl3b-email-action-upload",
      "MI_EMAILREAD": "sl3b-email-action-read-document",
      "MI_EMAILSENT": "sl3b-envelope",
      "MI_ERASER": "fa fa-eraser",
      "MI_EXCLAMATION": "sl3b-alert-triangle importantcolor",
      "MI_EXTENTION": "sl3b-composition-layout-2",
      "MI_FAK1": "sl3b-common-file-cash",
      "MI_FAK2": "sl3b-common-file-text-cash",
      "MI_FASTENER": "sl3b-attachment",
      "MI_FILTER": "sl3b-filter-1",
      "MI_FK": "sl3b-study-exam-math",
      "MI_FKAMORT": "sl3b-delivery-truck-clock",
      "MI_FKAMORTPLAN": "sl3b-common-file-text-clock",
      "MI_FKBKDOC": "sl3b-common-file-text-cash",
      "MI_FKBKDOCA": "sl3b-filter-text",
      "MI_FKBKDOCR": "sl3b-common-file-text-edit",
      "MI_FKBKDOCS": "sl3b-common-file-text-search",
      "MI_FKBKDOCV": "sl3b-accounting-invoice",
      "MI_FKBKDOCVS": "sl3b-cash-search",
      "MI_FKBOOK1": "sl3b-book-open-text",
      "MI_FKBOOK2": "sl3b-book-search",
      "MI_FKBOOK3": "sl3b-book-edit",
      "MI_FKBOOK4": "sl3b-notes-diary",
      "MI_FKBOOKA": "sl3b-read-search-alternate",
      "MI_FKDECREE": "sl3b-check-payment-sign",
      "MI_FKFXASSETS": "sl3b-shipment-truck-2",
      "MI_GRID": "sl3b-task-list-text-alternate",
      "MI_GRIDVIEW": "sl3b-design-tool-layout",
      "MI_GRUPCEN": "sl3b-tags-double-alternate",
      "MI_GRUPYKLI": "sl3b-multiple-users-1",
      "MI_HISTORIA": "sl3b-walkman-cassette",
      "MI_HOME": "fa fa-home",
      "MI_HORIZONTAL": "sl3b-layout-column",
      "MI_INFORMATION": "sl3b-messages-bubble-information-alternate",
      "MI_INSIDE": "sl3b-download-thick-bottom",
      "MI_KATTOW": "sl3b-office-file-module-edit",
      "MI_KONTAKTY": "sl3b-phone-type",
      "MI_KONTRAKTY": "sl3b-business-contract-handshake-sign",
      "MI_KORESPOND": "sl3b-read-email-laptop",
      "MI_LISTAPLAC": "sl3b-accounting-coins",
      "MI_LISTAPLACN": "sl3b-accounting-calculator",
      "MI_LISTAPLACR": "sl3b-pencil-write-3",
      "MI_LISTWYS1": "sl3b-shipment-open",
      "MI_LISTWYS2": "sl3b-shipment-check",
      "MI_LISTWYS3": "sl3b-shipment-in-transit",
      "MI_MAGAZYN": "sl3b-warehouse-storage-3",
      "MI_MAXIMIZE": "sl3b-app-window-expand-1",
      "MI_MENU": "sl3b-navigation-menu-vertical",
      "MI_MENU2": "sl3b-navigation-menu-1",
      "MI_MESSAGE": "sl3b-messages-people-user-bubble-circle",
      "MI_MESSAGEA": "sl3b-messages-people-user-clock",
      "MI_MIARA": "sl3b-measure-ruler-divider",
      "MI_MOBILE": "sl3b-mobile-phone-3",
      "MI_MODULE": "sl3b-module-four",
      "MI_MYSLNIK": "fa fa-minus",
      "MI_MYSLNIKZIEL": "fa fa-minus",
      "MI_NARZEDZIA": "sl3b-tools-wench-1",
      "MI_NIEOBECN": "sl3b-calendar-disable",
      "MI_NOTATKA": "sl3b-notes-paper-text",
      "MI_NUMER": "sl3b-arrange-number",
      "MI_OKRES": "sl3b-calendar-date",
      "MI_OKRESZAM": "sl3b-notes-clock",
      "MI_OPERACJA": "fa fa-cog",
      "MI_OPERATOR": "sl3b-people-man-10",
      "MI_OPERMAG": "sl3b-warehouse-truck-delivery-1",
      "MI_OUTSIDE": "sl3b-upload-thick-box",
      "MI_PADLOCK": "fa fa-lock",
      "MI_PAGESETUP": "sl3b-image-file-settings",
      "MI_PANEL": "sl3b-layout-top",
      "MI_PDF": "sl3b-office-file-pdf",
      "MI_PER": "sl3b-multiple-users-1",
      "MI_PKOSOBY": "sl3b-single-neutral-id-card-double",
      "MI_PLANS": "sl3b-calendar-setting",
      "MI_PLATNOSC": "sl3b-credit-card-dollar-1-alternate",
      "MI_PLURLOP": "sl3b-picture-sun",
      "MI_PRDEPART": "sl3b-real-estate-action-building-settings",
      "MI_PRINT": "sl3b-print-text",
      "MI_PRINTPREVIEW": "sl3b-common-file-search",
      "MI_PRIORITY": "sl3b-style-two-pin-warning veryimportantcolor",
      "MI_PRMACHINE": "sl3b-cog-double-1",
      "MI_PRMACHINSPECT": "sl3b-tools-wench-hold",
      "MI_PRMACHREPAIR": "sl3b-hardware-wench-screw",
      "MI_PROD": "fa fa-industry",
      "MI_PROGRAMY": "sl3b-accounting-coins-stack",
      "MI_PROJEKTY": "sl3b-grid-ruler-alternate",
      "MI_PROMOCJA": "sl3b-alert-circle",
      "MI_PROPERRAP": "sl3b-single-neutral-setting",
      "MI_PROPERSRAP": "sl3b-printer-view",
      "MI_PRTOOLSWAREHOUSE": "sl3b-tools-box-1",
      "MI_PRZELEW": "sl3b-cash-payment-bill-1",
      "MI_PULPIT1": "sl3b-app-window-layout",
      "MI_PULPIT2": "sl3b-app-window-user",
      "MI_PUZZLE": "sl3b-module-puzzle",
      "MI_QUESTION": "sl3b-messages-bubble-question-alternate",
      "MI_QUESTION2": "sl3b-question-help-message-alternate",
      "MI_RABATY": "sl3b-discount-coupon",
      "MI_REDPADLOCK": "fa fa-lock veryimportantcolor",
      "MI_REDX": "fa fa-times veryimportantcolor",
      "MI_REFRESH": "sl3b-synchronize-arrows-triangle",
      "MI_REJFAK": "sl3b-folder-cash-1-alternate",
      "MI_REJZAM": "sl3b-notes-flip",
      "MI_REPLIK": "sl3b-database-sync",
      "MI_RIGHT": "fa fa-caret-right",
      "MI_ROZRACH": "sl3b-money-bags",
      "MI_ROZRACHA": "sl3b-cash-payment-bag",
      "MI_ROZRACHD": "sl3b-accounting-invoice",
      "MI_ROZRACHK": "sl3b-business-deal-cash-1",
      "MI_ROZRACHS": "sl3b-cash-search",
      "MI_SCHEDULES": "sl3b-time-clock-file-setting",
      "MI_SENDEMAIL": "sl3b-email-action-send-1",
      "MI_SERIAL": "sl3b-barcode",
      "MI_SETTINGS": "sl3b-notes-checklist-flip",
      "MI_SETTWIT": "sl3b-network-settings",
      "MI_SHORTAGE": "sl3b-common-file-settings-1",
      "MI_SID": "fa fa-truck",
      "MI_SONDY": "sl3b-earth-question",
      "MI_SPEDYTOR": "sl3b-truck-cargo",
      "MI_SPRAWY": "sl3b-tool-box",
      "MI_SPRZEDAWCY": "sl3b-business-deal-handshake-1",
      "MI_STANY": "sl3b-accounting-abacus fa-rotate-right",
      "MI_STOP": "sl3b-stop-sign veryimportantcolor",
      "MI_SUROWIEC": "sl3b-lab-tubes",
      "MI_SZKOLENIA": "sl3b-school-board-maths",
      "MI_SZKOLENIAP": "sl3b-e-learning-monitor",
      "MI_SZUKDOK": "sl3b-common-file-text-search",
      "MI_SZUKFAK": "sl3b-cash-search",
      "MI_SZUKTOW": "sl3b-shipment-search",
      "MI_SZUKZAM": "sl3b-notes-search",
      "MI_TABS": "sl3b-folder-alternate",
      "MI_TECHKARTY": "sl3b-common-file-text-settings",
      "MI_TENEUM": "sl3b-teneum",
      "MI_TOOLBAR": "sl3b-folder-star-alternate",
      "MI_TOOLCENNIK": "sl3b-tags-settings-alternate",
      "MI_TOOLFAK": "sl3b-cash-toggles",
      "MI_TOOLKALK": "sl3b-calculator-app",
      "MI_TOOLMAG": "sl3b-warehouse-settings",
      "MI_TOOLS": "sl3b-tools-wench-screwdriver",
      "MI_TOOLTOW": "sl3b-cog-square-1",
      "MI_TOOLWIAD": "sl3b-email-action-settings",
      "MI_TOOLWIN": "sl3b-cog-browser-alternate",
      "MI_TOOLWIT": "sl3b-network-settings",
      "MI_TOOLZAM": "sl3b-calendar-setting",
      "MI_TOWARY": "sl3b-shipment-package",
      "MI_TRASH": "sl3b-bin-1",
      "MI_TREE": "sl3b-ui-webpage-bullets",
      "MI_TREE2": "sl3b-tree-cloud",
      "MI_TREE3": "fa fa-tree",
      "MI_TYPDOK": "sl3b-common-file-module-alternate",
      "MI_TYPFAK": "sl3b-folder-cash-alternate",
      "MI_TYPYPLIKOW": "sl3b-common-file-question",
      "MI_UP": "fa fa-sort-up",
      "MI_USERS": "sl3b-people-man-10",
      "MI_VAT": "sl3b-discount-circle",
      "MI_VERTICAL": "sl3b-layout-agenda",
      "MI_WALUTY": "sl3b-currency-user-exchange",
      "MI_WFTIME": "sl3b-synchronize-arrow-clock",
      "MI_WIADWIT": "sl3b-ecology-globe-message-1",
      "MI_WINDOWS": "sl3b-app-window-two",
      "MI_WINDOWS2": "sl3b-app-window-two",
      "MI_WITRYNA": "sl3b-earth-edit",
      "MI_WMS": "sl3b-warehouse-truck-delivery-1",
      "MI_WNURLOP": "sl3b-beach-sunbed",
      "MI_WORKFLOW": "sl3b-synchronize-arrows-three",
      "MI_WORKPLACE": "sl3b-single-man-actions-setting",
      "MI_WORLD": "fa fa-globe",
      "MI_WWW": "sl3b-network-www",
      "MI_WYCIAG": "sl3b-saving-bank-cash",
      "MI_WYPADEK": "sl3b-disability-wheelchair-1",
      "MI_ZADANIA": "sl3b-list-to-do",
      "MI_ZAM1": "sl3b-notes-edit",
      "MI_ZAM2": "sl3b-notes-check",
      "MI_ZAM3": "sl3b-notes-remove",
      "MI_ZAM4": "sl3b-notes-disable",
      "MI_ZAM5": "sl3b-notes-give-1",
      "MI_ZAM6": "sl3b-envelope-letter",
      "MI_ZAMGEN": "sl3b-notes-sync",
      "MI_ZBOZE": "sl3b-bread-wheat",
      "MI_ZDROWIE": "sl3b-hospital-house",
      "MI_ZLECPROD": "sl3b-design-tool-shape",
	  "EMPTY": ""
    },

    getSpriteClasses: function (iconName, enabled, dontReserveSpace, showEmptyPlace) {
        if(iconName) {
            iconName = iconName.toUpperCase()
            var Class = "";

            //sprawdzamy czy jest mapowanie na ikonki zewnetrzne
            if (this.iconMap[iconName] != undefined) {
              Class = this.iconMap[iconName].toLowerCase();
              return Class;
            }
        }

        if (!showEmptyPlace) {
            Class = "k-sprite noneIcon";
        }
        else {
            Class = "k-icon k-i-none";
        }

        return Class;
    },

    getSpriteIcon: function (iconName, enabled, spriteCssClasses) {
        var classes = "k-sprite";
        if (!spriteCssClasses) {
            classes = classes + " " + this.getSpriteClasses(iconName, enabled);
        } else {
            classes = spriteCssClasses;
        }
        var $span = $("<span/>");
        $span.addClass(classes);
        return $span;
    },

    getSpriteIconForButton: function (iconName, enabled) {
        var classes = "buttonIcon";
        classes = classes + " " + this.getSpriteClasses(iconName, enabled);
        var $span = $("<span/>");
        $span.addClass(classes);
        return $span;
    },


    getChecked: function (val, val4yes) {
        if (val == val4yes)
            return "checked";
        return "";
    },

    validateValueCheckbox: function (val, val4yes, val4no) {
        if (val != val4yes && val != val4no) {
            console.info("Ustawiona wartość chekboxa nie pasuje do jego wartości")
        }
    },

    createRepositoryFileImgElement: function (beginUrl, repo, file, cssClass) {
      var url = "";
      var hint = "";
      const tokenName = App.getNeosAccessToken().name;
      const newToken = App.localStorage.get(tokenName);
      if(!beginUrl.includes(newToken)) {
          const oldToken = new URL(beginUrl).searchParams.get(tokenName);
          beginUrl = beginUrl.replace(oldToken, newToken);
      }

      if(file != null)
        url = beginUrl + "&rep=" + repo + "&file=" + file;

      var img = "";
      if (url != "")
        img = "<img src='" + url + "' class='" + cssClass + "'>";
      else
        img = "<div class='" + cssClass + "'></div>";

      return img;
    },

    createRepositoryFilePreview: function (beginUrl, repo, filename, displayfilename, cssClass, clickable) {
      var url = "";
      var hint = "";

      if(filename != null)
        url = beginUrl + "&rep=" + repo + "&file=" + encodeURIComponent(filename);
      if (displayfilename == null)
        displayfilename = filename;
      filename = filename.toLowerCase();

      var img = "";
      if (url != "") {
        if (filename.endsWith('.pdf')) {
          img = "<embed src='" + url + "' class='" + cssClass + "' type='application/pdf'>";
          hint = displayfilename;
        } else if (filename.endsWith('.doc') || filename.endsWith('.docx')
                || filename.endsWith('.xls') || filename.endsWith('.xlsx')
                || filename.endsWith('.ppt') || filename.endsWith('.pptx'))
        {
          img = "";
          hint = displayfilename +" &nbsp;<span style='font-size:smaller;'>(kliknij, aby pobrać dokument)</span>";
        } else if (filename.endsWith('.jpg') || filename.endsWith('.jpeg')
                || filename.endsWith('.png') || filename.endsWith('.gif')
                || filename.endsWith('.bmp')) {
          img = "<img src='" + url + "' class='" + cssClass + "'>";
          hint = displayfilename;
        } else {
          img = "";
          hint = displayfilename;
        }
      } else
        img = "<div class='" + cssClass + "'></div>";

      if (url != "" && clickable)
        img = "<a target='_blank' href='" + url + "'>" + hint + img + "</a>";

      return img;
    },

    createBinaryFileImgElement: function (beginUrl, tablename, tablefield, ref, file, cssClass, clickable) {
        var url = "";

        if (ref != null && file != null) {
            var filename = createNewFileName(ref, file);
            var url = beginUrl + "&tn=" + tablename + "&tf=" + tablefield + "&file=" + filename;
        }

        var img = "";
        if (url != "")
            img = "<img src='" + url + "' class='" + cssClass + "'>";
        else
            img = "<div class='" + cssClass + "'></div>";

	if(url != "" && clickable)
		img = "<a target='blank' href='"+url+"'>"+img+"</a>"

        return img;

        function createNewFileName(ref, file) {
            var exstension = "";
            var name = "0000000000";

            if (file)
                exstension = "." + file.split(".")[1];
            if (ref)
                name = name.substring(ref.length) + ref;

            return name + exstension;
        }
    },

    getListStyleSheets: function () {
        //pobieramy liste wszyskich plikow css obecnie podpiętych do aplikacji
        App.listStyleSheets = [];
        var sheets = document.styleSheets
        var len = document.styleSheets.length;

        for (var i = 0; i < len; i++) {
            var sheet = sheets[i];
            if (sheet.href) {
                App.listStyleSheets.push(sheet.href);
            }
        }
    },

    checkIfUseStyleSheet: function (name) {
        //sprawdzamy czy obecnie jest uzywany podany styl
        var len = App.listStyleSheets ? App.listStyleSheets.length : 0;
        var result = false;

        for (var i = 0; i < len; i++) {
            var sheet = App.listStyleSheets[i];
            if (sheet.indexOf(name) >= 0) {
                result = true;
                break;
            }
        }

        return result;
    },

    checkIfBrowserIsSupported: function () {
        // Wspieramy Chrome, Firefox i Safari
        if (navigator.userAgent.indexOf("Chrome") != -1 || navigator.userAgent.indexOf("Safari") != -1 || navigator.userAgent.indexOf("Firefox") != -1) {
            return;
        }
        // Przy każdej innej przegladarce krzyczymy alertem
        alert('Aplikacja może działać nieprawidłowo, gdyż używasz niewłaściwej przeglądarki.\nZalecana przeglądarka to Google Chrome.');
        window.location.replace('about:blank');
    },

    createIconHashMap: function () {
        App.iconHashmap = new Hashmap(function (component) {
            return component;
        });

        var url, separator;
        if (App.Settings.DevMode == "yes") {
            url = App.Settings.CssVirtualPath + "streamline30-bold.css";
            separator = "\n";
        } else {
            url = App.Settings.CssVirtualPath + "streamline30-bold.min.css";
            separator = ".";
        }

        var stringData = $.ajax({
            url: url,//"styles\\sprite.css",
            async: false  //spróbować zrobić jako true bo nie synchronicznie nie wiadomo i le bedzie to zajmowalo
        }).responseText;

        var lines = stringData.split(separator);
        var len = lines.length;

        for (var i = 1; i < len; i++) {
            var line = lines[i];
            if (App.Settings.DevMode == "no")
                line = "." + line;
            if (line.indexOf(".sprite-") >= 0) {
                var iconName = line.split("{")[0].replace(".sprite-", "").replace("disabled", "");
                App.iconHashmap.add(iconName)
            }
        }
    },

    //testowe - nalezy poprawic i to gruntownie
    getAllIconNames: function () {
        var names = [];

        var url, separator;
        if (App.Settings.DevMode == "yes") {
            url = App.Settings.CssVirtualPath + "sprite.css";
            separator = "\n";
        } else {
            url = App.Settings.CssVirtualPath + "sprite.min.css";
            separator = ".";
        }

        var stringData = $.ajax({
            url: url,//"styles\\sprite.css",
            async: false
        }).responseText;

        var lines = stringData.split(separator);
        var len = lines.length;

        for (var i = 1; i < len; i++) {
            var line = lines[i];
            if (App.Settings.DevMode == "no")
                line = "." + line;
            //co z tym 32 jesli bedzie icon_32 to sie posypie
            var line = line.split("{")[0].replace(".sprite-", "").replace("disabled", "").replace("32", "");
        }

        return names;

    },


    // Zestaw funkcji do budowania template'ow wierszy.
    rowTemplate: {

        /* Parsuje ciag formatujacy. Zwraca obiekt postaci
           { kolumna: { wartosc: formatowanie } }, np.
           "NAME=*=%TclGreen*" -> { "NAME": { '*': "%TclGreen*" } }
        */
        parseFormatString: function (format) {
            if (!format)
                return {};

            var result = {};
            var rules = format.split(';');
            for (var i = 0; i < rules.length; i++) {
                var rule = rules[i].trim();
                var colRegexp = /[\*\w]+=/;
                var match = colRegexp.exec(rule);
                if (match && match.index == 0) {
                    var field = match[0].substring(0, match[0].length - 1);
                    rule = rule.slice(match[0].length);
                    var options = rule.split('|');
                    result[field] = {};
                    for (var j = 0; j < options.length; j++) {
                        var o = options[j].split('=');
                        var cond = o[0];
                        var then = o[1];
                        result[field][cond] = then;
                    }
                }
            }

            return result;
        },
        /* 
        Formatuje <value>  zgodnie z ciagiem <formatString>.
        <value>  - nazwa pola w zrodle danych
        Utils.rowTemplate.parseGridValue("NAME=*=%TclGreen*",value,"noIcon")
        */

        // jak w SGridDrawCellInfo.cpp (sdas3)
        parseGridValue: function(formatStr, value, iconMode) {
            var css = {};
            var result = "";
            var bitmap = undefined;

            if (iconMode == 'noIcon')
                value = value.value;
            else {
                bitmap = value.icon;

                if (iconMode == 'onlyIcon')
                    value = "";
                else
                    value = value.value;
            }

            var inpos = 0;
            while (inpos < formatStr.length) {
                var c = formatStr[inpos];
                var end;
                switch (c) {
                    case '%':
                        if (inpos < formatStr.length - 1) {
                            inpos++;
                            c = formatStr[inpos];
                            switch (c) {
                                case '%': result += '%'; break;
                                case 'B': css.fontWeight = 'bold'; break;
                                case 'b': css.fontWeight = 'normal'; break;
                                case 'D': result += new Date().toISOString().slice(0, 10); break; // format daty?
                                case 'I': css.fontStyle = 'italic'; break;
                                case 'i': css.fontStyle = 'normal'; break;
                                case 'U': css.textDecoration = 'underline'; break;
                                case 'S': css.textDecoration = 'line-through'; break;
                                case 'u':
                                case 's': css.textDecoration = 'none'; break;
                                case 'F':
                                    end = nextSpecialChar(inpos + 1);
                                    var size = formatStr.slice(inpos + 1, end);
                                    css.fontSize = size + 'px';
                                    inpos = end - 1;
                                    break;
                                case 'T':
                                case 'C':
                                    end = nextSpecialChar(inpos + 1);
                                    var color = formatStr.slice(inpos + 1, end);
                                    if (color) {
                                        color = color.toLowerCase();
                                        var prefix = color.substring(0, 2);

                                        if (prefix == "cl")
                                            color = Utils.rowTemplate.vclToRGB(color);
                                        else if (prefix == "0x")
                                            color = Utils.rowTemplate.bgrToRgb(color);
                                        else
                                            color = ""; // error...

                                        if (formatStr[inpos] == 'T')
                                            css.color = color;
                                        if (formatStr[inpos] == 'C')
                                            css.backgroundColor = color;
                                    }
                                    inpos = end - 1;
                                    break;
                               case 'r':
                                   css.textAlign = 'right';
                                   break;
                               case 'l':
                                   css.textAlign = 'left';
                                   break;
                               case 'c':
                                   css.textAlign = 'center';
                                   break;
                            }
                        }
                        break;

                    case '*':
                        if (inpos + 1 < formatStr.length && formatStr[inpos + 1] == '*') {
                            result += '*';
                            inpos++;
                        } else {
                            result += value;
                        }
                        break;

                    case '#':
                        if (inpos + 1 < formatStr.length && formatStr[inpos + 1] == '#') {
                            result += '#';
                            inpos++;
                        } else {
                            end = nextSpecialChar(inpos + 1);
                            bitmap = formatStr.slice(inpos + 1, end);
                            inpos = end - 1;
                        }
                        break;

                    default:
                        result += formatStr[inpos];
                        break;
                }

                inpos++;
            }

            return { 'value': result, 'css': css, 'bitmap': bitmap };

            function nextSpecialChar(startpos) {
                var str = formatStr;
                var i = startpos;
                while (i < str.length && " %*#".indexOf(str[i]) == -1) i++;
                return i;
            }
        },
        getCssFromFormatString: function(parsed) {
            var style = "";
            if(parsed.css.fontWeight)
            {
                style += "font-weight: "+parsed.css.fontWeight+";";
            }
            if(parsed.css.fontStyle)
            {
                style += "font-style: "+parsed.css.fontStyle+";";
            }
            if(parsed.css.textDecoration)
            {
                style += "text-decoration: "+parsed.css.textDecoration+";";
            }
            if(parsed.css.fontSize)
            {
                style += "font-size: "+parsed.css.fontSize+";";
            }
            if(parsed.css.color)
            {
                style += "color: "+parsed.css.color+";";
            }
            if(parsed.css.backgroundColor)
            {
                style += "background-color: "+parsed.css.backgroundColor+";";
            }
            if(parsed.css.textAlign)
            {
                style+= "text-align: "+parsed.css.textAlign+";";
            }
            return style;
        },
        // Specyficzne dla szablonow kendoGrid.
        grid: {
            // (!) Ta funkcja jest wywolywana w SGrid.createTemplateRow
            getCellTemplate: function (format, columnName, value, formatstr, rawDataType, magicKey, isTree, firstTreeColumn, iconMode, classes, hidden) {
                var template = `# var td = Utils.rowTemplate.grid.generateTd(${format}, "${columnName}", (typeof ${value} !== 'undefined') ? ${value} : ''`;
                if (formatstr) {
                    //w js nie działa replace dla wszystkich wystąpien znaków.
                    //Jest to potrzebne ponieważ w template nieparzysta ilość # powodowała błąd
                    template += ',"' + formatstr.split("#").join("\\#") + '"';
                }
                else {
                    template += ', ""';
                }
                template += ', "' + rawDataType + '"';
                template += ', "' + magicKey + '"';
                template += ', ' + isTree;
                template += ', ' + firstTreeColumn;
                template += ', "' + iconMode + '"';
                template += ', "' + classes + '"';
                template += ', ' + hidden;
                template += ');';
                template += 'if (td) {# #= td# # } #';

                return template;
            },


            // Generuje HTML pojedynczej komorki na podstawie
            // formatowania i biezacej wartosci.
            generateTd: function (format, key, val, mask, rawDataType, magicKey, isTree, firstTreeColumn, iconMode, classes, hidden) {
                var td = "";
                var field;
                var rule;
                var visibility = "";
                var el = document.createElement("td");
                if(hidden)
                {
                    visibility = "display: none";
                }

                // znajdz regule dla odpowiadajacej kolumny
                if (format['*'])
                    field = format['*'];
                else if (format[key])
                    field = format[key];

                // znajdz regule dla danej wartosci pola
                if (field)
                    if (field['*']) {
                        rule = field['*'];
                    }
                    else if (field['~']) {
                          rule = field['~'];
                    }
                    else if (field[val]) {
                        rule = field[val];
                    }

                // pozbadz sie null-i
                var res = Utils.parseValType(val, rawDataType, mask);

                var cls = res.cls;

                if (isTree && firstTreeColumn) {
                    cls += " inTree";
                }

                if (classes) {
                    cls += " " + classes;
                }

                val = res.newval;
                val = Utils.replaceLineEndingsInNonHtmlTag(val);
                val = this.generateTdValue(val, magicKey, rawDataType, iconMode);
                val.value = res.newval ? DOMPurify.sanitize(Utils.escapeHtml(val.value)) : val.value;

                var style = "";
                if (rule && !hidden){
                    var parsed = Utils.rowTemplate.parseGridValue(rule, val,iconMode);
                    style = Utils.rowTemplate.getCssFromFormatString(parsed);
                    val.value = parsed.value;
                    val.icon = parsed.bitmap;
                }
                if (isTree) {
                    el = document.createElement("span");
                }
                el.dataset.column=key;
                this.addClassesFromString(cls,el);
                if(visibility)
                {
                  el.style=visibility+";"+style;  
                }
                else {
                    el.style=style;
                }
                if (!isTree) {
                    if (iconMode != "noIcon" && val.icon) {
                        var text = null;
                        if (iconMode == "onlyIcon") {
                            text = "";
                        }   
                        else {
                            text = val.value;
                        }
                        var spanElement = document.createElement("span");
                        spanElement.style = "display:inline";
                        spanElement.classList.add("inner-cell");
                        spanElement.innerHTML= text;
                        var iconElement = document.createElement("span");
                        this.addClassesFromString(Utils.getSpriteClasses(val.icon,true),iconElement);
                        var divElement = document.createElement("div");
                        divElement.style = "text-align:left";
                        divElement.innerHTML = iconElement.outerHTML + spanElement.outerHTML + this.addTooltipToGridColumns(val.value); 
                        el.innerHTML = divElement.outerHTML;
                        td = el.outerHTML;
                    } else {
                        var spanElement = document.createElement("span");
                        spanElement.classList.add("inner-cell");
                        spanElement.innerHTML= val.value;                        
                        el.innerHTML = spanElement.outerHTML + this.addTooltipToGridColumns(val.value);                                                                      
                        td = el.outerHTML;                      
                    }
                } else {
                    el.classList.add("inner-cell");
                    el.innerHTML = val.value;
                    td = el.outerHTML;
                }
               
                return td;
            },

            generateTdValue: function (val, magicKey, rawDataType, mode, forGroupHeader) {
                if (val === "" || val == undefined) {
                    val = "&nbsp;"
                }

                val = Utils.setValuFromPickList(magicKey, val);

                if (rawDataType)
                    val.value = Utils.formatDateTimeNumberValue(rawDataType, val.value);

                return !forGroupHeader ? val : val.value;
            },
            addClassesFromString: function (classesToAdd, htmlElement){
                classesToAdd.split(" ").forEach((_class) => {
                    if(_class)
                    {
                        htmlElement.classList.add(_class);
                    }
                });
            },

            addTooltipToGridColumns: function(columnValue) {
                if(columnValue != "&nbsp;")
                {
                    var tooltipElement = document.createElement("span");
                    tooltipElement.classList.add("inner-cell-tooltip");
                    tooltipElement.innerHTML = columnValue;

                    return tooltipElement.outerHTML;
                }    

                return "";
            },
        },

        // Mapuje kolory VCL na RGB
        vclToRGB: function (color) {
            var vclMapping = {
                claqua: "#00FFFF",
                clblack: "#000000",
                clblue: "#0000FF",
                clcream: "#FFFBF0",
                cldkgray: "#808080",
                clfuchsia: "#FF00FF",
                clgray: "#808080",
                clgreen: "#008000",
                cllime: "#00FF00",
                clltGray: "#C8C8C8",
                clmaroon: "#080000",
                clmedGray: "#A0A0A4",
                clmoneyGreen: "#C0DCC0",
                clnavy: "#000008",
                clolive: "#808000",
                clpurple: "#800080",
                clred: "#FF0000",
                clsilver: "#C8C8C8",
                clskyBlue: "#A6CAF0",
                clteal: "#008080",
                clwhite: "#FFFFFF",
                clyellow: "#FFFF00",
                clnone: "#000000"
            };

            return vclMapping[color.toLowerCase()];
        },

        // Mapuje VCL 0xBBGGRR na #RRGGBB
        bgrToRgb: function (colorHexStr) {
            if (colorHexStr.length != 8)
                return "";

            var hex = colorHexStr.substring(2, colorHexStr.length);
            return '#' +
                hex.substring(4, 6) +
                hex.substring(2, 4) +
                hex.substring(0, 2);
        }
    },

    /**
     * Zwraca true, jeśli kolumna danego typu powinna być formatowana do prawej w gridzie
     */
    formatToRight: function (rawDataType) {
        return rawDataType == 2 || rawDataType == 3 || rawDataType == 7 || rawDataType == 101 || rawDataType == 102;
    },


    parseValType: function (val, rawtype, format) {
        var cls = "";
        //dla typów numeric dodajemy klasę numericInGrid która
        //odpowiada za wyrównywanie wartości do prawej strony
        if (this.formatToRight(rawtype)) {
            cls = "numericInGrid";
        }

        //Parsujemy wartość do danego typu
        if (rawtype == 2 || rawtype == 3) {
            newval = parseInt(val);
        } else
            if (rawtype == 7 || rawtype == 101 || rawtype == 102) {
                newval = parseFloat(val);
            }

        if (newval != val) {
            newval = val;
            cls = "";
        }

        //pozbywamy sie null-i
        var newval = val == null ? "" : newval;

        if (format) {
            newval = kendo.format(format, newval);
        }
        return { newval: newval, cls: cls };
    },

    callActionWithShortKey: function (e, shortKey) {
        var actionID;
        var SObject = App.gui.getFirstSObject(e.currentTarget);

        var actions = SObject.dataset.actions.getAll();
        var len = actions.length;
        for (var i = 0; i < len; i++) {
            var action = actions[i]

            if (action.shortkey == shortKey) {
                //jeśli nadaliśmy jakiejś akcji skrót taki sam jak dla akcji standardowej, to jest on nadrzędny, a ten z akcji standardowej będzie obsłużony, tylko, jeśli nie mamy innego skrótu na ten sam klawisz
                if(action.parentActionInstanceId) {
                    var parentAction = SObject.dataset.actions.getItem("actionInstanceID", action.parentActionInstanceId);
                    if(parentAction && parentAction.symbol == "CustomActions") {
                        actionID = action.actionInstanceID;
                    } else {
                        actionID = action.actionInstanceID;
                        break;
                    }   
                } else {
                    actionID = action.actionInstanceID;
                    break;   
                }
            }
        }

        App.gui.communication.BP.callAction(actionID);
    },

    operationsUrlParameters: {
        params: new Hashmap(function (dictElem) {
            return dictElem.key;
        }),

        getURLParameterValue: function (sParam) {

            if (this.params.containsKey(sParam)) {
                return this.params.getValue(sParam).value;
            } else {
                return undefined;
            }
        },

        addParam: function (key, value) {
            var dictElem = {};

            dictElem.key = key;
            dictElem.value = value;
            dictElem.urlParam = key + "=" + value;

            this.params.add(dictElem);
        },

        initParamList: function () {
            if (document.location.search != "") {
                var params = document.location.search.substr(1).split('&');
                var len = params.length;

                for (var i = 0; i < len; i++) {
                    var param = params[i];
                    var tmp = param.split('=');

                    this.addParam(tmp[0], tmp[1]);
                }
            }
        },

        deleteParam: function (key) {
            var dictElem = {};
            dictElem.key = key;

            this.params.remove(dictElem);
        },

        createUrlSearch: function () {
            var params = this.params.getAll();
            var len = params.length;
            var result = "";

            if (len > 0) {
                result = "?";
            }

            for (var i = 0; i < len; i++) {
                var param = params[i];

                result = result + param.urlParam;

                if (i < len - 1) {
                    result = result + "&";
                }
            }

            return result;
        }

    },

    createPickList: function (xml) {
        this.pickItems = new Hashmap(function (pickItem) {
            return pickItem.value;
        });

        var PickItems = $(xml).find("[name='PI']");
        var self = this;
        $(PickItems).each(function () {
            var obj = new Object();
            var tv = $(this).attr("TV");
            var fv = $(this).attr("FV");
            var i = $(this).attr("I");
            obj["text"] = tv || "";
            obj["value"] = fv || "";
            obj["icon"] = i || "";

            self.pickItems.add(obj);
        });

        this.pickList = this.pickItems.getAll();
    },

    setRecordAndCallAction: function (e, actionID, datasetInstanceID, formInstanceID) {
        this.setRecordInGrid(e, datasetInstanceID, formInstanceID);
        App.gui.communication.BP.callAction(actionID, e.id);
    },

    setRecordInGrid: function (e, datasetInstanceID, formInstanceID) {
        var form = App.gui.navigator.formStack.findForm(formInstanceID);
        if (form.form) {
            form = form.form;
            var ds = form.datasets.getValue(datasetInstanceID);
            if (ds) {
                var len = ds.dependentPresenters.length;

                for (var i = 0; i < len; i++) {
                    var presenter = ds.dependentPresenters[i];

                    if (presenter.itemType == "TABLE") {
                        var tr = $(e).parents("tr");
                        if (tr.length == 0) {
                            tr = $(e.target).parents("tr");

                        }

                        if (tr.length > 0) {
                            presenter.immediateDataChange = true;
                            presenter.kendoGrid.select(tr);
                            presenter.immediateDataChange = false;
                        }
                    }
                }
            }
        }
    },

    setValuFromPickList: function (magicKey, val) {
        var newVal = { value: val, icon: null };

        if (magicKey && magicKey != "") {
            var tmp = magicKey.split(";");
            if (tmp.length == 4) {
                var formID = tmp[0];
                var datasetID = tmp[1];
                var gridID = tmp[2]
                var columnID = tmp[3];

                var element = App.gui.navigator.formStack.findForm(formID);
                if (element && element.form) {
                    var ds = element.form.datasets.getValue(datasetID);
                    if (ds) {
                        var grid;

                        var len = ds.dependentPresenters.length;
                        for (var i = 0; i < len; i++) {
                            var presenter = ds.dependentPresenters[i];

                            if (presenter.elementUUID == gridID) {
                                grid = presenter;
                                break;
                            }
                        }

                        if (grid) {
                            var gridColumn;

                            len = grid.columns.length;
                            for (var i = 0; i < len; i++) {
                                var column = grid.columns[i];

                                if (column.uuid == columnID) {
                                    gridColumn = column;
                                    break;
                                }
                            }

                            if (gridColumn) {
                                var value = gridColumn.pickItems.getValue(val);
                                newVal.value = value ? value.text : val;
                                newVal.icon = value ? value.icon : null;


                            }
                            else
                                console.info("Podczas wykonywania funkcji setValuFromPickList() nie znaleziono kolumny grida o uuid: " + columnID);
                        }
                        else
                            console.info("Podczas wykonywania funkcji setValuFromPickList() nie znaleziono grida o UUID '" + gridID + "' w datasecie");
                    }
                    else
                        console.info("Podczas wykonywania funkcji setValuFromPickList() nie znaleziono dataseta o id " + datasetID + " na formie");
                }
                else
                    console.info("Podczas wykonywania funkcji setValuFromPickList() nie znaleziono formy o id: " + formID);
            }
        }

        return newVal;
    },

    getBindingForType: function(type, rawtype, fieldname, dataFormat) {
      var databind = "data-bind='";
      var format = "{0}";
      if(dataFormat)
      {
        format = false;   
      }
      else
      {
          if(rawtype)
          {
            format = this.getFormatForType(rawtype);
          }
          else
          {
            format = this.getFormatForType(rawtype);
          }
      }
      if (format)
      {
        databind += "customformat: "+fieldname;
        databind = "data-customformat='" + format + "' " + databind;
      }
      else
      {
        databind += "value: " + fieldname;
      }



      return databind;
    },

    getFormatForType: function(type) {
      if(type && typeof type === 'string') {
        switch (type.toLowerCase()) {
            case "date":
            case "103":
                return "{0:dd-MM-yyyy}";
            case "time":
            case "104":
                return "{0:T}";
            case "datetime":
            case "8":
                if(kendo.cultures.current.name == "pl-PL") {
                    return "{0:dd-MM-yyyy HH:mm:ss}";
                }
                return "{0:dd-MM-yyyy HH:mm:ss}";
            default:
                return "{0}"
        }
      }
      return "{0}"
    },

    formatDateTimeNumberValue: function (rawDataType, val) {
        switch (rawDataType) {
            case "104":
                if(typeof val == "string") {
                  //aktualnie dla czasu przychodzi z serwera string i to jest lekki problem, bo nieładnie się Formatuje
                  //robimy takie troche dzikie obejścia tematu
                  var dt = new Date("1900-01-01T"+val);
                  if(!isNaN(dt)) {
                    return kendo.format(this.getFormatForType(rawDataType), dt);
                  } else {
                    //jak sie nie uda to spróbuj chociaż pozbyć się .00000 bo to źle wygląda
                    if(typeof val == "string" && val.indexOf('.')>-1) {
                      return val.split('.')[0];
                    }
                  }
                } else if(val instanceof Date) {
                  return kendo.format(this.getFormatForType(rawDataType), val);
                }
                return val;
            case "103":
            case "8":
                val = val == null ? undefined : val;
                return kendo.format(this.getFormatForType(rawDataType), val);   
                break;
            case "7":
                //dla typu number przechowanego w stringu, jeśli w modelu przypadkiem jest kropka to zawsze zamieniamy na przecinek
                //kropka może być na mobilnych bo tam używamy natywnego inputa type=number
                if (typeof val == "string")
                    return val.replace(".", ",");
                else if (typeof val == "number")
                    return val.toLocaleString(undefined, { maximumFractionDigits: 20 });
                break;
            default:
                return val;
                break;
        }
    },

    /*
    * Funkcja zamienia znaki nowej linii na tagi <br/> w przypadku gdy tekst nie zawiera tagów html. W pp. nie robi nic i zwraca ten sam tekst co na wejsciu.
    */
    replaceLineEndingsInNonHtmlTag: function (text) {
        if (text != undefined) {
            text = text + '';
            var textWithBrs = text.replace(/(?:\r\n|\r|\n)/g, '<br />');
            var textWithNoNewLineCharacters = text.replace(/(?:\r\n|\r|\n)/g, '');
            var textWithNoHtmlTags = $('<div></div>').html(textWithNoNewLineCharacters).text();


            if (textWithNoNewLineCharacters == textWithNoHtmlTags) {//są równe znaczy się nie ma tagów html
                return textWithBrs
            } else return text;
        }
    },

    /*
    * Funkcja wejściowa do obsługi escapowanie html w treści komentarzy
    */
    handleHtmlTagsInText: function (data, ignoreArray) {
        return Utils.handleCodeInText(data, ignoreArray);
    },

    /*
    * Funkcja próbuje znaleźć kod HTML w tresci komentarza i następnie opakować kolejne fragmenty
    */
    handleCodeInText: function (data, ignoreArray) {
        var re = new RegExp('<(?!br)(\\S*)[^>]*>.*<\\/\\1>', 'gs');
        var result = "";

        var matches;
        do {
            matches = re.exec(data);
            if (matches) {
                var matchIdx = data.indexOf(matches[0]);
                var preMatchData = data.substring(0, matchIdx);
                result += preMatchData;
                if (ignoreArray.length > 0) {
                    if(ignoreArray.some(function(i){
                        return matches[0].indexOf(i) > -1;
                    })) {
                        result += matches[0]
                    } else {
                        result += Utils.wrapHtmlInCodeTag(matches[0]);
                    }
                } else {
                    result += Utils.wrapHtmlInCodeTag(matches[0]);
                }

                data = data.substring(matchIdx + matches[0].length);
            } else {
                result += DOMPurify.sanitize(Utils.escapeHtml(data));
            }
        } while (matches);
        return result;
    },

    /*
    * Funkcja escapuje kod HTML i opakowuje w tag code
    */
    wrapHtmlInCodeTag: function(html) {
        html = DOMPurify.sanitize(html.replace(/<br>/g, "\n").replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;'));
        return "<code>" + html + "</code>";
    },

    escapeHtml: function(text){
        return text.replace(/<br>/g, "\n").replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, "<br>")
    },

    /**
    * Funkcja służy do uruchamiania strony podanej w parametrze
    * Zamieniłem assign na replace aby niepotrzebnie nie tworzyć wpisów w historii po logowaniu
    * @param {string} url - url strony którą chcemy otworzyć
    */
    refreshURL: function (url) {
        var newurl = url + this.operationsUrlParameters.createUrlSearch()
        if(window.location.href != newurl) {
            window.location.replace(newurl);          
        }  
    },

    /**
    * Funcja służy do uruchamiania strony podanej w parametrze, jednocześnie robiąc wpis w historii przeglądarki
    * Dzięki temu poprawne jest działanie przycisków wstecz i dalej w przeglądarce
    * @param {string} url - url strony którą chcemy otworzyć    
    **/
    redirectWithHistory: function (url) {
        var newurl = url + this.operationsUrlParameters.createUrlSearch()
        if(window.location.href != newurl) {
            window.location.assign(newurl);
        }
    },

    removeTokenAndRefreshURL: function (url) {
        this.operationsUrlParameters.deleteParam("token");
        var newurl = url + this.operationsUrlParameters.createUrlSearch()
        if(window.location.href != newurl)
          window.location.replace(newurl);
    },

    getCurrentDate: function () {
        var date = new Date();
        date = kendo.format("{0:"+App.dateFormat+"}", date);
        return date;
    },

    cssImportant: function(element, property, value) {
        if (!(element instanceof jQuery)) {
            element = $(element);
        }
        var style = property + ': ' + value + ' !important';
        element.each(function(){
           var $this = $(this);
           var current = $this.attr('style');
           if (current && current.length > 0) {
               style = current + '; ' + style;
           }
           $this.attr('style', style);
        });
    },

    // Funkcja służy do mierzenia elementów ze stylem 'display: none' oraz takich, które dopiero zostaną podczepione do drzewa DOM i wyświetlone
    measure: function (element, property, ...params) {
        if (!(element instanceof jQuery)) {
            element = $(element);
        }
        var measure = function (element) {
            if (property instanceof Function) {
                return property.apply(element, params);
            } else {
                return element[property].apply(element, params);
            }
        }
        var setDisplay = function (element) {
            var display = element.show().css('display');
        	if (display && display != 'none') {
        		Utils.cssImportant(element, 'display', display);
        	} else {
        		Utils.cssImportant(element, 'display', 'block');
        	}
        }

        var result;
        if (element.length > 0 && document.contains(element[0]))
        {
            if (element.is(':visible')) {
                return measure(element);
            } else {
                var $hidden = element.parents(':hidden').addBack();
                var backup = [];
        		$hidden.each(function (index) {
        			var $this = $(this);
        			backup[index] = $this.attr('style');
        			Utils.cssImportant($this, 'visibility', 'hidden');
        			setDisplay($this);
        		});
                Utils.cssImportant($hidden[0], 'position', 'absolute');
        
        		var result = measure(element);
         		$hidden.each(function (index) {
    			    var $this = $(this);
           			if (backup[index] !== undefined) {
        				$this.attr('style', backup[index]);
        			} else {
        				$this.removeAttr('style');
        			}
                });
            }
        } else {
            var clone = element.clone(false);
        	Utils.cssImportant(clone, 'visibility', 'hidden');
        	Utils.cssImportant(clone, 'position', 'absolute');
        	Utils.cssImportant(clone, 'display', 'block');
        	clone.appendTo('body');
            
            result = measure(clone);
            clone.remove();
        }
        return result
    },

    //Funkcja kopiuje przekazaną wartość do schowka
    copyToClipboard: function (val) {
        var textArea = document.createElement("textarea");
        textArea.value = val;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try
        {
            var successful = document.execCommand('copy');
            if(!successful)
                console.error("Couldn't copy to clipboard!!!");
        }
        catch (err)
        {
            console.error('Unable copy to clipboard', err);
        }

        document.body.removeChild(textArea);
    },

    generateUUID: function() { // Public Domain/MIT
      var d = new Date().getTime();
      if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
          d += performance.now(); //use high-precision timer if available
      }
      return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
    },

    getBrowserLanguage: function() {
        var lang = window.navigator.languages[0] || App.defaultLanguage;
        if(lang.includes("-")){
            lang = lang.substring(0, lang.indexOf("-"));
        }
        return lang || App.defaultLanguage;
    },

    
   whenVisible: function(element, func)
   {
       if (!(element instanceof jQuery)) {
           element = $(element);
       }
       if (element.is(':visible')){
           func();
       } else {
           var data = element.data('when-visible-data');
           if (!data || !(data.observer instanceof MutationObserver))
           {
               data = {};
               data.observer = new MutationObserver(function(mutations) {
                   mutations.forEach(function(mutationRecord) {
                       if (element.is(':visible')){
                           func();
                           data.observer.disconnect();
                           data.connected = false;
                       }
                   });    
               });
               element.data('when-visible-data', data);
           }
           if (!data.connected) {
               data.connected = true;
               var setting = { attributes : true, attributeFilter : ['style'] };
               var $hidden = element.parents(':hidden').addBack();
               $hidden.each(function(){
                   data.observer.observe(this, setting);
               });
           }
       }
   },

   getLanguageFromUrl: function() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get('lang');
   }
};
/// <reference path="../jquery/jquery-1.10.1-vsdoc.js"/>
/// <reference path="../kendo/vsdoc/kendo.all-vsdoc.js"/>
'use strict';
/**
 * Główny obiekt aplikacji
 * @constructor
 */
function App(login) {
    this.debug = {
        /*
            coloredBoxes: false,
            layoutDebug:false
        */
        odata: {
            stat: false
        },
        perf: {
            forms: false
        },
        counters: {
            datasets: false
        }
    };
    this.gui = undefined;
    this.communication = undefined;
    this.login = login;
    this.activeChars = "`~1234567890-=qwertyuiop[]\\asdfghjkl;'zxcvbnmę€óąśłżźćń,./!@#$%^&*()_+{}|:\"<>?"

    this.initialize();

}

App.prototype = {
    /*
    * Metoda inicjalizująca instancję aplikacji. Tworzy obiekty współpracujące.
    */
    initialize: function () {
        this.Resources = new Hashmap(function (resource) {
        return resource.Id;
        });
        this.LanguageResources = {
            emptyComboboxItemText: "(brak)",
            noDataTemplate: "Brak danych do wyświetlenia",
            unhandledStatement: "Nieobsługiwany komunikat",
            noDivisionForLogin: "brak oddziałów dla loginu",
            unhandledLoginStatement: "Nieobsługiwany komunikat logowania",
            search: "Szukaj...",
            connectionLost: "Utracono połączenie z serwerem",
            clickToReload: "Kliknij tutaj aby przeładować stronę i odnowić połączenie",
            doReload: "Czy przeładować stronę?",
            redPlug: "Kliknij ikonę czerwonej wtyczki.",
            resetPasswordMailSent: "Jeżeli podałeś poprawny login powinien zostać wysłany email z linkiem służącym do zmiany hasła.",
            passwordsDontMatch: "Podane hasła muszą być identyczne",
            passwordResetFailed: "Nie udało się zmienić hasła",
            passwordResetSucceeded: "Zmiana hasła zakończona pomyślnie",
            copy: "Kopiuj wartość komórki",
            rowCount: "Liczba wierszy",
            clearAllSelection: "Odznacz wszystko",
            choose: "Wybierz",
            notAvailableSearchCharacter: "Wyszukiwany tekst posiada nieobsługiwany znak '?'. Nie zostanie on uwzględniony przy wyszukiwaniu."
        };
        this.sessionStorage = SessionStorage.init();
        this.kendoFixes = KendoFixes;
        this.localStorage = LocalStorage.init();
        //this.requestLog = RequestLog.init();

        if(this.login || oemToken){
          this.sessionStorage.set('reloadConfirmationShown', 'false')
        }

        this.messageTextValues = MessageTextValues;
        this.dateFormat = "yyyy-MM-dd";
        this.UUID = Utils.generateUUID();
        if(typeof SGlobalSearch != 'undefined')
          this.globalSearch = SGlobalSearch;

        this.profile = Utils.operationsUrlParameters.getURLParameterValue("profile") || "";
        this.communication = new Communication(this, this.profile);

        //handle oem
        var oemToken = Utils.operationsUrlParameters.getURLParameterValue("oem");
        if(oemToken){
          this.communication.Common.whoAmI(oemToken);
          this.oemMode = true;
        }

        this.odata = new ODataProxy();
        this.notificationInterval = 60000;
        this.communication.BP.getDefaultLanguage();
        this.communication.BP.getLanguageResources();

        var self = this;
        window.onresize = function () {
            self.gui.resizing();
        }

        $(document).on("keydown", function (e) {
            var keyId = e.originalEvent ? e.originalEvent.keyCode : e.keyCode;
            if (keyId == 116) {
                return confirm("Wcisnięto F5, które spowoduje odświeżenie strony i zamknięcie wszystkich otwartych okien. Na pewno kontynuować?");
            }
            if (keyId == 70 && e.ctrlKey) {
                SGlobalSearch.visible(true);
                e.preventDefault();
            }
        });

        //Zdarzenie do wykrywania kliknięcia środkowym przyciskiem.
        $(document).on("mousedown", function (e1) {
            $(document).one("mouseup", function (e2) {
                if (e1.which == 2 && e1.target == e2.target) {
                    var e3 = $.event.fix(e2);
                    e3.type = "middleclick";
                    $(e2.target).trigger(e3)
                }
            });
        });

        if(!this.oemMode) {
          //nie wczytuj od razu tokenów bo jak
          //przyjdzie response od whoami to i tak się zmienią
          this.setPreemptiveAccessTokenRefreshal();
          this.setPreemptiveRefreshTokenRefreshal();
        }
    },

    setOemMode: function() {
      $("#SNAVIGATOR").addClass("oem-mode");
      $("#mainMenu").addClass("oem-mode");
      $("#middle-pane").addClass("oem-mode");
    },

    /*
     * Metoda włącza mechanizm przejmujący i zarządzający przyciskiem "Wstecz" w przeglądarce
     */
    addhistoryhelper: function () {
        this.historyHelper = HistoryHelper;
        this.historyHelper.init();
    },

    addgui: function () {
        this.gui = new Gui(this);
        this.communication.Gui = this.gui;
    },

    /*
    *Funkcja uruchamia się po wczytaniu strony i uruchamia akcje, które mają się wtedy wykonać.
    */
    postinitialize: function () {


        var odataUrl = this.getRelativeODataURL();
        this.odata.init(odataUrl)
        this.gui.getUnit();
        this.communication.BP.getNeosVersion();
        Utils.getListStyleSheets();
        Utils.createIconHashMap();
        this.communication.BP.getResources("SYSTEM.ICONS", "SMALLICON");
        this.gui.navigator = /*App.gui.selectedStructure == "loose" ? Object.create(SLooseNavigator) :*/ Object.create(SNavigator);
        this.action = Utils.operationsUrlParameters.getURLParameterValue("action") || "";

        this.communication.BP.getNavigator();

        this.gui.registerDatavizTheme();
        this.gui.setFooterInCorrectPlace();
        var self = this;

        Utils.hideProgress();
        if (this.Settings.WebsocketUrl)
            this.communication.addWebsocket(this.Settings.WebsocketUrl);

        $("#middle-pane").on("scroll", function () {
            if (App.gui.navigator.formStack.currentMDIForm) {
                App.gui.navigator.formStack.currentMDIForm.refreshScrollbar();
            }
        });

        if(this.oemMode){
          this.setOemMode();
        }

        this.pingSession();
        if(this.requestLog)
            this.requestLog.loadFromLocalStorage()
    },

    runAction: function () {
        var self = this
        if (this.action != "") {
            setTimeout(function () { self.communication.BP.showActionURL(self.action); }, 50);
        } else {
            this.getNotifications();
        }
    },

    getNotifications: function () {
        var self = this;
        this.notificationTimer = window.setInterval(function () { self.communication.BP.getNotifications() }, this.notificationInterval);
    },

    pingSession: function () {
        var interval = this.Settings["SessionKeepaliveTime"];
        if (interval > 0 && !App.login) {
            var self = this;
            this.pingSessionTimer = window.setInterval(function () { self.communication.BP.pingSession() }, interval * 1000 * 60);
        }
    },

    getTokenName:function(key) {
      var tokenName = 'Neos'+key+'Token';
      if(this.profile !== "") {
        tokenName += '-'+this.profile;
      }
      return tokenName;
    },

    getReloadConfirmationShown: function() {
      var val = this.sessionStorage.get('reloadConfirmationShown')
      return val == "true"
    },

    setReloadConfirmationShown: function(val) {
      this.sessionStorage.set('reloadConfirmationShown', val)
    },

    storeRefreshToken: function(token, expiration) {
      var name = this.getTokenName('Refresh');
      if(this.oemMode)
      {
        this.sessionStorage.set(name,token);
        this.sessionStorage.set(name+"-expiration",expiration);            
      }
      else
      {
        this.localStorage.set(name,token);
        this.localStorage.set(name+"-expiration",expiration);
      }

      this.setPreemptiveRefreshTokenRefreshal(expiration);
    },

    loadRefreshToken: function() {
      var name = this.getTokenName('Refresh');
      var self = this;
      if(this.oemMode)
      {
        return {
            val : self.sessionStorage.get(name),
            expiration : self.sessionStorage.get(name+"-expiration")
        };
      }
      else
      {
        return {
            val : self.localStorage.get(name),
            expiration : self.localStorage.get(name+"-expiration")
        };
      }
    },

    storeAccessToken: function(token, expiration) {
      var name = this.getNeosAccessTokenName();
      if(this.oemMode)
      {
        this.sessionStorage.set(name,token);
        this.sessionStorage.set(name+"-expiration",expiration);
      }
      else
      {
        this.localStorage.set(name,token);
        this.localStorage.set(name+"-expiration",expiration);
      }
      this.setPreemptiveAccessTokenRefreshal(expiration);
    },

    calculatePeriodToRenewal:function(dataString, whenToStart, randomFactor) {
      var expirationDate = new Date(dataString)
      var now = Date.now()
      var period = expirationDate - now;
      var periodToRenewal = Math.floor(Math.max(period * whenToStart, 0))
      periodToRenewal += periodToRenewal * randomFactor * Math.random()
      var dateOfRenewal = now+periodToRenewal
      return { period : periodToRenewal, date : dateOfRenewal }
    },

    setPreemptiveAccessTokenRefreshal : function(dataString) {
      if(this.login) return; //nie wysyłamy na stronie logowania
      if(this.getReloadConfirmationShown()) return;

      if(typeof dataString == 'undefined') {
        dataString = this.getNeosAccessToken().expiration;
      }
      if(!dataString)
        return;

      var periodToRenewal = this.calculatePeriodToRenewal(dataString, 0.7, 0.02)

      if(this.preemptiveTokenRefreshTimeout) {
        clearTimeout(this.preemptiveTokenRefreshTimeout)
      }
      if(periodToRenewal.period > 0) {
        var self = this
        console.info("Access token will be refreshed on "+new Date(periodToRenewal.date))
        this.preemptiveTokenRefreshTimeout = setTimeout(function() {
          console.info("Preemtive refresh of access token")
          self.performAccessTokenRefresh(dataString)
        }, periodToRenewal.period)
      } else {
        if(this.accessTokenResultChecker){
          clearTimeout(this.accessTokenResultChecker)
        }
        console.info("Access token will be refreshed immediately")
        this.performAccessTokenRefresh()
      }
    },

    performAccessTokenRefresh : function(dataString) {
      if(!this.login &&
         !this.getReloadConfirmationShown()){//nie robimy tego na stronie logowania
          var token = this.loadRefreshToken();
          var delay = 5000;//domyslnie 5 sekund na odpowiedz serwera
          if(dataString) {
            var calcdelay = this.calculatePeriodToRenewal(dataString,0.02).period
            if(calcdelay > delay) {
                delay = calcdelay
            }
          } else {
            dataString = this.getNeosAccessToken().expiration;
          }

          var self = this
          var check = new Date();
          check.setSeconds(check.getSeconds() + (delay/1000));
          console.info("Access token refresh status will be confirmed at "+check)
          this.accessTokenResultChecker = setTimeout(function(){
            clearTimeout(this.accessTokenResultChecker)
            if(dataString != self.getNeosAccessToken().expiration) { //udało się odświeżyć
              console.info("Access token refreshed")
              return
            }

            console.info("Access token refresh failure")
            var now = new Date()
            var refreshToken = new Date(self.loadRefreshToken().expiration)
            var datediff = refreshToken - now;

            if(datediff > 0) {//możemy jeszcze raz spróbować
              console.info("Access token refresh rescheduled")
               self.performAccessTokenRefresh(dataString)
            } else {
              //nope, sad stories and total failure
              console.info("Access token refresh: guru meditation")
            }
          },delay)
        this.communication.Common.refreshToken(token.val)
      }
    },

    setPreemptiveRefreshTokenRefreshal : function(dataString) {
      if(this.login) return; //nie wysyłamy na stronie logowania
      if(this.getReloadConfirmationShown()) return; //nie aktualizujemy po prompcie

      if(typeof dataString == 'undefined') {
        dataString = this.loadRefreshToken().expiration;
      }
      if(!dataString)
        return;

      var periodToRenewal = this.calculatePeriodToRenewal(dataString, 0.85, 0.02)
      var tooFarAwayToRefresh = 30 * 24 * 60 * 60 * 1000;//a month

      if(this.preemptiveRefreshTokenRefreshTimeout) {
        clearTimeout(this.preemptiveRefreshTokenRefreshTimeout)
      }

      if(periodToRenewal.period > 0) {
        if(periodToRenewal.period < tooFarAwayToRefresh) {
          var self = this
          console.info("Refresh token will be refreshed on "+new Date(periodToRenewal.date))
          this.preemptiveRefreshTokenRefreshTimeout = setTimeout(function() {
            console.info("Preemtive refresh of refresh token")
            self.performRefreshTokenRefresh()
          }, periodToRenewal.period)
        } else {
          //refreshToken won't be refreshed because it is highly unlikely for
          //the app to survive whole month in one instance
        }
      } else {
        console.info("Refresh token will be refreshed immediately")
        this.performRefreshTokenRefresh()
      }
    },

    performRefreshTokenRefresh : function() {
      if(!this.login &&
         !this.getReloadConfirmationShown()) {//nie robimy tego na stronie logowania
          var token1 = this.getNeosAccessToken();
          var token2 = this.loadRefreshToken();
          var delay = 5000;//domyslnie 5 sekund na odpowiedz serwera
          if(token2 && token2.expiration) {
            var calcdelay = this.calculatePeriodToRenewal(token2.expiration,0.02).period
            if(calcdelay > delay) {
                delay = calcdelay
            }
          } else {
            return
          }

          var self = this
          var check = new Date();
          check.setSeconds(check.getSeconds() + (delay/1000));
          console.info("Refresh token refresh status will be confirmed at "+check)
          this.refreshTokenResultChecker = setTimeout(function(){
            clearTimeout(this.refreshTokenResultChecker)
            var new_token = self.loadRefreshToken();
            if(token2.expiration != new_token.expiration) { //udało się odświeżyć
              console.info("Refresh token refreshed")
              return
            }

            console.info("Refresh token refresh failure")
            var now = new Date()
            var refreshToken = new Date(self.getNeosAccessToken().expiration)
            var datediff = refreshToken - now;

            if(datediff > 0) {//możemy jeszcze raz spróbować
              console.info("Refresh token refresh rescheduled")
               self.performRefreshTokenRefresh()
            } else {
              //nope, sad stories and total failure
              console.info("Refresh token refresh: guru meditation")
            }
          },delay)

          this.communication.Common.refreshToken2(token1.val, token2.val)
      }
    },

    getNeosAccessToken: function() {
      var self = this;    
      var name = this.getNeosAccessTokenName();
      if(this.oemMode)
      {
        return {
            val : self.sessionStorage.get(name),
            expiration : self.sessionStorage.get(name+"-expiration")
        };
      }
      else
      {
        return {
            val : self.localStorage.get(name),
            expiration : self.localStorage.get(name+"-expiration")
        };   
      }
    },

    getNeosAccessTokenName: function() {
      var tokenName = "";
      if(location.protocol.includes('https')) {
          tokenName = "__Host-";
      }
      tokenName += 'NeosAccessToken';
      if(this.profile !== "") {
        tokenName += '-' + this.profile;
      }

      return tokenName;
    },

    getSettings: function (val) {
        var xml = $(val);
        this.Settings = {};
        var keys = $('setting', xml);
        var len = keys.length;
        for (var i = 0; i < len; i++) {
            var item = $(keys[i]);
            var key = item.attr('key');
            var value = item.attr('value');
            this.Settings[key] = value;
        }

        this.Settings.IconVirtualPath = this.Settings.VirtualPath + "/Icon/"
        this.Settings.CssVirtualPath = this.Settings.VirtualPath + "/styles/";
    },

    getRelativeODataURL: function () {
        if (typeof (this.Settings.ODataUrl) == 'string' && this.Settings.ODataUrl.length > 0)
            return this.Settings.ODataUrl;

        var port = parseInt(location.port) + 1;
        if (parseInt(this.Settings.ODataPort) != 0)
            port = this.Settings.ODataPort;

        return location.protocol + '//' + location.hostname + ":" + port + "/";
    },

    getRelativeCurrentURL: function (portDelta) {
        var port = parseInt(location.port);
        portDelta = portDelta || 0;
        var newPort = port + portDelta;
        if (isNaN(newPort))
            return location.protocol + '//' + location.hostname + "/";
        else
            return location.protocol + '//' + location.hostname + ":" + newPort + "/";
    },

    loadScript: function (script) {
      var neosVersion = $('meta[name*="' + "neos-version" +'"]')[0].content;
      var scriptPath = neosVersion + script;
      if( $('script[src*="' + scriptPath + '"]').length == 0)
      {
        var script = document.createElement('script')
        script.src = scriptPath;
        document.head.append(script);
      } 
    },
    
    getLanguageResources: function (val) {
        var xml = $(val);
        this.LanguageResources.emptyComboItemText = xml.attr('emptyComboboxItemText');
        this.LanguageResources.noDataTemplate = xml.attr('noDataTemplate');
        this.LanguageResources.unhandledStatement = xml.attr('unhandledStatement');
        this.LanguageResources.noDivisionForLogin = xml.attr('noDivisionForLogin');
        this.LanguageResources.search = xml.attr('search');
        this.LanguageResources.culture = xml.attr('culture');
        this.LanguageResources.connectionLost = xml.attr('connectionLost');
        this.LanguageResources.clickToReload = xml.attr('clickToReload');
        this.LanguageResources.doReload = xml.attr('doReload');
        this.LanguageResources.redPlug = xml.attr('redPlug');
        this.LanguageResources.resetPasswordMailSent = xml.attr('resetPasswordMailSent');
        this.LanguageResources.passwordResetSucceeded = xml.attr('passwordResetSucceeded');
        this.LanguageResources.passwordsDontMatch = xml.attr('passwordsDontMatch');
        this.LanguageResources.passwordResetFailed = xml.attr('passwordResetFailed');
        this.LanguageResources.copy = xml.attr('copy');
        this.LanguageResources.rowCount = xml.attr('rowCount');
        this.LanguageResources.clearAllSelection = xml.attr('clearAllSelection');
        this.LanguageResources.choose = xml.attr('choose');
        this.LanguageResources.notAvailableSearchCharacter = xml.attr('notAvailableSearchCharacter');
        this.LanguageResources.favorites = xml.attr("favorites");
        if(Utils.getLanguageFromUrl() == "pl") {
          this.loadScript("/js/kendo/lang/kendo.pl-PL.js");
          this.loadScript("/js/kendo/messages/kendo.messages.pl-PL.min.js");
        }
        kendo.culture(this.LanguageResources.culture);
      }
}

/// <reference path="../jquery/jquery-1.10.1-vsdoc.js"/>
/// <reference path="../kendo/vsdoc/kendo.all-vsdoc.js"/>
'use strict';

function BP(communication) {
    this.communication = communication;
};

BP.prototype = {
    //Funkcje z pliku AppSrvBPFuncs
    showForm: function(objectname, formname, contexts, mode, formstyle, callback) {
        var url = 'BPShowForm?objectname=' + objectname + '&formname=' + formname + '&contexts=' + contexts + '&mode=' + mode + '&formstyle=' + formstyle + '/';
        if (callback)
            this.communication.afterNextFormShown = callback;
        this.communication.sendRequest(url);
    },

    getSettings: function() {
        var url = 'BPGetSettings';
        this.communication.sendRequest(url);
    },

    pingSession: function() {
        var url = 'BPPingSession';
        this.communication.sendRequest(url);
    },

    selectionChanged: function(datasetinstanceid, xml) {
        var url = 'BPSelectionChanged';
        this.communication.sendRequest({
            url: url,
            method: 'post',
            value: {
                datasetinstanceid: datasetinstanceid,
                xml: xml
            }
        });
    },

    datasetChanged: function(datasetinstanceid, state) {
        var url = 'BPDatasetChanged?datasetinstanceid=' + datasetinstanceid + '&state=' + state + '/';
        this.communication.sendRequest(url);
    },

    dropFiles: function(datasetinstanceid, files) {
        var url = 'BPDropFiles?datasetinstanceid=' + datasetinstanceid + '&files=' + files + '/';
        this.communication.sendRequest(url, undefined, true);
    },

    removeFiles: function(datasetinstanceid, files) {
        var url = 'BPRemoveFiles?datasetinstanceid=' + datasetinstanceid + '&files=' + files + '/';
        this.communication.sendRequest(url);
    },

    fileTransferEnd: function(transferid, xml) {
        var url = 'BPFileTransferEnd?transferid=' + transferid + '&xml=' + xml + '/';
        this.communication.sendRequest(url);
    },

    dataChanged: function(datasetinstanceid, xml, usercall) {
        //console.info("Komunikat 'dataChanged' z klienta WWW. datasetinstanceid: '" + datasetinstanceid + "', xml: '" + xml + "'");
        var url = `BPDataChanged?user-call=${usercall}`;
        this.communication.sendRequest({
            url: url,
            method: 'post',
            value: {
                datasetinstanceid: datasetinstanceid,
                xml: xml
            }
        });
    },

    dragAndDrop: function(xml) {
        var url = 'BPDragAndDrop';
        this.communication.sendRequest({
            url: url,
            method: 'post',
            value: {
                xml: xml
            }
        });   
    },

    refreshDatasetInstance: function(datasetinstanceid) {
        var url = 'BPRefreshDatasetInstance?datasetinstanceid=' + datasetinstanceid + '/';
        this.communication.sendRequest(url);
    },

    closeForm: function(forminstanceid, forceclose) {
        var extendedinfo = "QUERY"
        if (forceclose)
            extendedinfo = " ";

        var url = 'BPCloseForm?forminstanceid=' + forminstanceid + '&extendedinfo=' + extendedinfo + '/';
        this.communication.sendRequest(url);
    },

    callAction: function(actioninstanceid, elementUUID) {
        var url = 'BPCallAction?actioninstanceid=' + actioninstanceid + '&elementuuid=' + elementUUID + '/';
        this.communication.sendRequest(url, actioninstanceid);

    },

    getNotificationsNow: function() {
        var url = 'BPGetNotificationsNow';
        this.communication.sendRequest(url);
    },

    /**
    * Funkcja pobiera nowe wiadmości z kolejki.
    */
    getMessages: function() {
        var url = 'BPGetMessages';
        this.communication.sendRequest(url);
    },

    /**
    * Funkcja pobiera notyfikacje z neosa. Używa BPGetNotifications,
    * bo dzięki temu jej wywołanie nie liczy się do czasu bezczynności
    */
    getNotifications: function() {
        var url = 'BPGetNotifications';
        this.communication.sendRequest(url);
    },

    getResources: function(tablename, tableident) {
        var url = 'BPGetResources?objectname=' + tablename + '&resourcetype=' + tableident + '/';
        this.communication.sendRequest(url);
    },

    getNavigator: function() {
        var url = 'BPGetNavigator';
        this.communication.sendRequest(url);
    },

    staticMethod: function(objectname, methodname, parameters, xmlparameters) {
      if(!App.getReloadConfirmationShown()) {
        var url = 'BPMethod?objectname=' + objectname + '&methodname=' + methodname + '&parameters=' + parameters + '&xmlparams=' + xmlparameters + '/';
        this.communication.sendRequest(url);
      } else {
          $(".user-info-wrapper").vibrate({
             speed: 50,             // Vibration speed in milliseconds
             stopAfterTime: 3,       // Stop Vibrating after a fixed time in seconds
             callBack: function(){
               App.gui.showBalloonHint(this.App.LanguageResources.connectionLost + ".<br>" + this.App.LanguageResources.redPlug ,"0")
             } // Function to call when vibration stops
          })
      }
    },

    /**
    * Funkcja prosi serwer o przesłąnie formy startowej dla profilu
    */
    runStartUp: function() {
        var url = 'BPRunStartUp';
        this.communication.sendRequest(url);
        App.sessionStorage.set("StartMethodExecuted", true);
    },

    getProfileName: function(profile) {
        var lang = Utils.getLanguageFromUrl() || App.lang || App.defaultLanguage || Utils.getBrowserLanguage();
        var url = 'BPGetProfileName?profile=' + profile + '&language=' + lang + '/';
        this.communication.sendRequest(url);
    },

    getNeosVersion: function() {
        var url = 'BPGetNeosVersion';
        this.communication.sendRequest(url);
    },

    /**
    * Funkcja buduje XML z informacją o zmianie wartości pola w kontrolce.
    * @param {object} sobject - Obiekt kontrolki dla której zmieniła się wartość
    * @param {bool} manualedit - czy zmiana wartości pola nastąpiła w wyniku ręcznej edycji pola.
    * @param {bool} sendBaseValue - czy dla pól słownikowanych wysyłać także wartość pola bazowego.
    * Zykle wysyłamy pole bazowe, ale nie robimy tego w sytuacji, gdy ktoś ręcznie wpisze wartość do pola EDIT ze słownikiem
    */
    createXml: function(object, manualEdit, sendBaseValue) {
        var dataset = object.dataset;
        var fields = dataset.fields;
        var value;
        var result = {};

        var len = fields.length;
        var xml = new XmlBuilder();
        xml.openNode("DataChanges");
        if (object.dataFieldUUID == null) {
            //wysy�ane kiedy chcemy przes�a� informacje o wszystkich polach np. NotificationData
            for (var i = 0; i < len; i++) {
                xml.openNode("DataChange");
                var field = object.findTraceField(fields[i].fieldUUID);
                xml.addTextNode('fielduuid', field.dataFieldUUID);

                var dict = field.dictFieldUUID != null && !object.isCheckComboboxFromParameter ? field.dictFieldUUID : "";
                xml.addTextNode('dictfielduuid', dict);

                var modelFieldName = dataset.getFieldModelKey(field.dataFieldUUID);
                if (dict) {
                    modelFieldName = dataset.getFieldModelDictKey(field.dataFieldUUID, dict);
                }

                value = dataset.model[modelFieldName];
                if (fields[i].convertToServerFormat)
                    value = fields[i].convertToServerFormat(value);

                xml.addTextNode("val", value);
                xml.closeNode();
            }
        } else {
            var field = object.findTraceField(object.dataFieldUUID);
            xml.openNode("DataChange");
            xml.addTextNode('fielduuid', field.dataFieldUUID);

            var dict = field.dictFieldUUID != null && !object.isCheckComboboxFromParameter ? field.dictFieldUUID : "";

            sendBaseValue = sendBaseValue && (field.dictFieldUUID != null);

            xml.addTextNode('dictfielduuid', dict);

            var modelFieldName = dataset.getFieldModelKey(field.dataFieldUUID);
            var fieldName = field.dataFieldUUID;
            if (dict) {
                modelFieldName = dataset.getFieldModelDictKey(field.dataFieldUUID, dict);
                fieldName += "." + dict;
            }

            value = dataset.model[modelFieldName];
            if (value === null)
                value = "";

            var datasetfield = dataset.fields.getValue(fieldName);
            if (datasetfield.convertToServerFormat)
                value = datasetfield.convertToServerFormat(value);

            xml.addTextNode("val", value);
            xml.addTextNode("manualedit", manualEdit == true ? "Y" : "N");
            xml.addTextNode("datasetinstanceid", field.datasetInstanceID);
            xml.closeNode();

            if (sendBaseValue) {
                //jeśli wysyłamy pole słownikowane, to wkładamy drugi node z polem złączeniowym słownika
                xml.openNode("DataChange");
                xml.addTextNode('fielduuid', field.dataFieldUUID);
                xml.addTextNode('dictfielduuid', "");

                modelFieldName = dataset.getFieldModelKey(field.dataFieldUUID);
                fieldName = field.dataFieldUUID;

                value = dataset.model[modelFieldName];
                if (value === null)
                    value = "";

                datasetfield = dataset.fields.getValue(fieldName);
                if (datasetfield.convertToServerFormat)
                    value = datasetfield.convertToServerFormat(value);

                xml.addTextNode("val", value);
                xml.addTextNode("manualedit", "N");
                xml.addTextNode("datasetinstanceid", field.datasetInstanceID);
                xml.closeNode();
            }

        }
        xml.closeNode();
        result.value = xml.toString();

        return result;

    },

    closeAllForms: function(profile) {
        var url = 'BPCloseAllForms';
        this.communication.sendRequest(url);
    },

    showActionURL: function(action) {
        var url = 'BPShowActionUrl?action=' + action + '/';
        this.communication.sendRequest(url);
    },

    globalSearchTextChanged: function(text) {
        var url = 'BPGlobalSearchTextChanged?text=' + text + '/';
        this.communication.sendRequest(url);
    },

    updateFavorites: function(operation, uuid) {
        var url = 'BPUpdateFavorites?operation=' + operation + '&uuid=' + uuid + '/';
        this.communication.sendRequest(url);
    },

    loadViewSettings: function(datasetinstanceid, uuid) {
        var url = 'BPLoadViewSettings?datasetinstanceid=' + datasetinstanceid + '&uuid=' + uuid + '/';
        this.communication.sendRequest(url);
    },

    showViewSettings: function(datasetinstanceid) {
        var url = 'BPShowViewSettings?datasetinstanceid=' + datasetinstanceid + '/';
        this.communication.sendRequest(url);
    },

    requestLogs: function(logs) {
        var url = 'BPRequestLogs';
        this.communication.sendRequest({
            url: url,
            method: 'post',
            value: {
                logs: logs
            }
        });

        this.communication.sendRequest(url);
    },

    getNumberOfRows: function(datasetinstanceid) {
        var url = 'BPGetNumberOfRows?datasetinstanceid=' + datasetinstanceid + '/';
        this.communication.sendRequest(url);
    },

    dashboardChanged: function(formid, xml) {
        var url = 'BPDashboardChanged';
        this.communication.sendRequest({
            url: url,
            method: 'post',
            value: {
                forminstanceid: formid,
                xml: xml
            }
        });
    },
    showStartingDashboard: function() {
        var url = 'BPShowStartingDashboard';
        this.communication.sendRequest(url);
    },
    changeViewSettings: function(datasetinstanceid, triggeredbyuser, xml) {
        var url = 'BPChangeViewSettings';
        this.communication.sendRequest({
            url: url,
            method: 'post',
            value: {
                datasetinstanceid: datasetinstanceid,
                triggeredbyuser: triggeredbyuser ? "Y" : "N",
                xmlcolumns: xml
            }
        });
    },
    getDefaultLanguage: function() {
        var url = 'BPGetDefaultLanguage?profile='+this.communication.getProfile();
        this.communication.sendRequest(url);
    },
    getLanguageResources: function() {
        var lang = Utils.getLanguageFromUrl() || this.communication.App.defaultLanguage || Utils.getBrowserLanguage();
        var url = 'BPGetLanguageResources?lang=' + lang;
        this.communication.sendRequest(url);
    }
}

function Common(communication) {
    this.communication = communication;
}
;
Common.prototype = {
    //Funkcje z pliku AppSrvCommonFuncs

    debugLog: function(msg, useMainLog) {
        var url = 'DebugLog?msg=' + msg + '&useMainLog=' + useMainLog + '/';
        this.communication.sendRequest(url);
    },

    /**
    * Funkcja logująca nas do aplikacji
    */
    loginUserByLogin: function(login, password, clientVersion, profile, oldPassword, device, department, lang) {
        App.sessionStorage.set("StartMethodExecuted", false);
        var url = 'LoginUserByLogin';
        this.communication.sendRequest({
            url: url,
            method: 'post',
            value: {
                login: login,
                password: password,
                clientVersion: clientVersion,
                profile: profile,
                oldPassword: oldPassword,
                device: device,
                department: department,
                lang: lang || Utils.getBrowserLanguage()
            }
        });
    },

    /**
    * Funkcja logująca nas do aplikacji
    */
    loginUserByGoogle: function(login, token, clientVersion, profile, oldPassword, device) {
        App.sessionStorage.set("StartMethodExecuted", false);
        var url = 'LoginUserByGoogle';
        this.communication.sendRequest({
            url: url,
            method: 'post',
            value: {
                login: login,
                token: token,
                clientVersion: clientVersion,
                profile: profile,
                oldPassword: oldPassword,
                device: device,
                lang: Utils.getBrowserLanguage()
            }
        });
    },

    sendChangePasswordEmail: function(login, profile, lang) {
        var url = 'SendChangePasswordEmail';
        this.communication.sendRequest({
            url: url,
            method: 'post',
            value: {
                login: login,
                profile: profile,
                lang: lang || Utils.getBrowserLanguage()
            }
        });
    },

    /**
     * Funkcja wylogująca użytkownika i zamykająca wszystkie otwarte formy
     */
    logoutUser: function() {
        var url = 'LogoutUser';
        this.communication.sendRequest(url);
    },

    refreshToken: function(token) {
        var url = 'RefreshToken';
        this.communication.sendRequest({
            url: url,
            method: 'post',
            value: {
                token: token,
                lang: Utils.getLanguageFromUrl()
            }
        });
    },

    refreshToken2: function(token1, token2) {
        var url = 'RefreshToken2';
        this.communication.sendRequest({
            url: url,
            method: 'post',
            value: {
                atoken: token1,
                rtoken: token2,
                lang: Utils.getLanguageFromUrl()
            }
        });
    },

    whoAmI: function(oemToken) {
        var url = 'WhoAmI';
        this.communication.sendRequest({
            url: url,
            method: 'post',
            value: {
                otoken: oemToken
            }
        });
    },

    getDivisionsForUser: function(login) {
      var url = 'GetDivisionsForUser';
      this.communication.sendRequest({
          url: url,
          method: 'post',
          value: {
              userlogin: login
          }
      });
    },

    getLanguagesForProfile: function(profile) {
      var url = 'GetLanguagesForProfile';
      this.communication.sendRequest({
          url: url,
          method: 'post',
          value: {
              userProfile: profile
          }
      });
    },

    resetUserPassword: function(token, password, clientVersion, profile) {
        var url = 'ResetUserPassword';
        this.communication.sendRequest({
           url: url,
           method: 'post',
           value: {
             token: token,
             password: password,
             clientVersion: clientVersion,
             profile: profile
           } 
        });
    }
};

/**
 * Obiekt odpowiedzialny za komunikację z serwerem, przy pomocy tak zwanego websocketa.
 * Dzięki temu możemy odbierać komunikaty z serwera w czasie rzeczywistym.
 * @param {string} url - adres websocketa po stronie serwera
 * @param {object} communication - obiekt wspolpracujacy, głowny obiekt komunikacji
 * @constructor
 */
function NeosWebsocket(url, communication) {
    this.url = url;
    this.communication = communication;
}

NeosWebsocket.prototype = {
    /**
     * Połączenie z websocketem na zadanym adresie, przypięcie głównego zdarzenia, na otwarcie połączenia.
     * @instance
     */
    connect: function() {
        var self = this;
        this.socket = new WebSocket(this.url);
        this.socket.onopen = this.onopen.bind(this);
        this.socket.onerror = this.onerror.bind(this);
    },

    /**
     * Po uzyskaniu połączenia rejestruje w Neosie, jaki ma token sesji
     * @param {object} e - zdarzenie wygenerowane przy otwarciu websocketa
     * @instance
     */
    onopen: function(e) {
        if(!this.communication.App.getNeosAccessToken())
        {
          this.setTimerToConnect();
          return;
        }
        if (this.watcher) {
            clearInterval(this.watcher);
            delete this.watcher;
        }
        this.socket.send("CONNECT " + this.communication.App.getNeosAccessToken().val
          + " " + this.communication.App.UUID + " " + this.communication.profile);
        this.socket.onmessage = this.onmessage.bind(this);
        this.socket.onclose = this.onclose.bind(this);
    },

    /**
     * Obsługuje odebranie komunikatu od websocketa. Po poprawnej rejestracji dostajemy komunikat zwrotny
     * CONNECT OK. Następnie oczekujemy na komunikaty refreshclient, po ktorych wiemy, że musimy pobrać
     * nową kolejkę komunikatów. Metoda ustawia flagę logiczną this.sustain po to, aby się zorientować, że
     * połączenie po zerwaniu należy próbować odświeżyć
     * @param {object} e - zdarzenie wygenerowane przy odebraniu komunikatu z websocketa
     * @instance
     */
    onmessage: function(e) {
        if (e.data == "CONNECT OK")
            this.sustain = true;
        else if (e.data == "REFRESHCLIENT")
            this.refreshclient();
        else if (e.data == "DISCONNECT") {
            this.sustain = false;
            this.close();
        } else if (e.data == "ERROR") {
            console.error(e);
            this.sustain = false;
        } else if (e.data == "LOGOUT") {
            var self = this.communication;
            self.App.gui.signOut(function() {
                      self.redirectToPageIfNeeded("login.html");
                  });
        } else {
            console.info("Unknown websocket command " + e.data);
        }
    },

    /**
     * Zamyka połączenie
     * @instance
     */
    close: function() {
        if (this.socket) {
            this.socket.close();
            delete this.socket;
        }
    },

    /**
     * Obsługuje błąd websocketa.
     * @param {object} e - zdarzenie wygenerowane przy błędzie websocketa
     * @instance
     */
    onerror: function(e) {
        console.error(e);
        this.close();
    },

    /**
     * Metoda reaguje na zamknięcie socketa. Jeżeli powinniśmy próbować
     * utrzymać połączenie, ponawia próbę co pięć sekund
     * @param {object} e - zdarzenie wygenerowane przy zamknięciu websocketa
     * @instance
     */
    onclose: function(e) {
        if (this.sustain) {
            this.close();
            this.setTimerToConnect();
        }
    },

    setTimerToConnect: function() {
      if (!this.watcher) {
          var self = this
          this.watcher = setInterval(function() {
              console.info("attempt to reconnect to websocket " + self.url)
              self.connect();
          }, 5000);
      }
    },

    /**
     * Metoda reaguje na żądanie odświeżenia komunikatów z serwera
     * @instance
     */
    refreshclient: function() {
        this.communication.refreshclient();
    }
};

/**
 * Obiekt odpowiedzialny za komunikację z serwerem, wykonywanie zdalnych funkcji i interpretację przychodzących komunikatów.
 * @param {object} application - obiekt reprezentujący całą aplikację
 * @param {string} profile - od PR69526 cała komunikacja podaje na jaki profil jest zalogowany użytkownik
 * @constructor
 */
function Communication(application, profile) {
    //QuequeMessage - globalna kolejka do przechowywania komunikat�w
    this.quequeMessage = [];
    this.App = application;
    this.BP = new BP(this);
    this.Common = new Common(this);
    this.Gui = undefined;
    this.message_profiler = false;
    //firebug specific
    this.profile = profile;
}
;
Communication.prototype = {
    /**
   * Metoda inicjuje połączenie websocketowe
   * @instance
   */
    addWebsocket: function(url) {
        this.Websocket = new NeosWebsocket(url,this);
        this.Websocket.connect();
    },
    /**
     * Metoda inicjuje połączenie websocketowe
     * @instance
     */
    refreshclient: function() {
        console.info("refreshclient");
        this.BP.getMessages();
    },

    /**
     * Metoda zwraca dodatkowy nagłowek autoryzujący dla odata
     * @param {boolean} omiń X- w nazwie nagłówka
     * @return {object} obiekt zawierający nagłówek jako klucz
     */
    getOdataCustomHeaders: function() {
        var neosAccessToken = this.App.getNeosAccessToken();
        var neosTokenName = neosAccessToken.name;
        var neosToken = neosAccessToken.val;
        var customHeaders = {}
        customHeaders[neosTokenName] = neosToken;
        customHeaders['_ssid_'] = App.UUID;
        
        return customHeaders;
    },

    getCookie: function(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2)
            return parts.pop().split(";").shift();
    },

    /**
     * Metoda zwraca nazwę aktywnego profilu
     * @return {string} Nazwa aktywnego profilu
     */
    getProfile: function() {
        if (this.profile && this.profile != "") {
            return this.profile;
        } else if (App.Settings && App.Settings.Profile) {
            return App.Settings.Profile;
        }
        return "";
    },

    /**
     * Metoda potrafi wząć url taki jak zwracane są z wszystkich funkcji BP itp i jeżeli nie ma tam
     * parametru profile to dodaje taki parametr
     */
    addProfileParameter: function(url) {
        if (!this.profile && !App.settings)
            return url;

        if (url.indexOf("profile=") >= 0)
            return url;

        var len = url.length;
        var si = url.indexOf("/", len - 1) !== -1;
        var newurl = url;
        if (si)
            newurl = newurl.substring(0, len - 1);
        var queryparampresent = url.indexOf('?') >= 0;
        if (!queryparampresent)
            newurl += "?";
        else
            newurl += "&";

        newurl += 'profile=' + this.getProfile();

        if (si)
            newurl += '/';

        return newurl;
    },

    /**
     * Metoda potrafi wząć url taki jak zwracane są z wszystkich funkcji BP itp i jeżeli nie ma tam
     * parametru clientuuid to dodaje taki parametr
     */
    addUUIDParameter: function(url) {
        if (!App.UUID)
            return url;

        if (url.indexOf("clientuuid=") >= 0)
            return url;

        var len = url.length;
        var si = url.indexOf("/", len - 1) !== -1;
        var newurl = url;
        if (si)
            newurl = newurl.substring(0, len - 1);
        var queryparampresent = url.indexOf('?') >= 0;
        if (!queryparampresent)
            newurl += "?";
        else
            newurl += "&";

        newurl += '_ssid_=' + App.UUID;

        if (si)
            newurl += '/';

        return newurl;
    },

    /**
     * Metoda odpowiada za wysłanie ajaxowego zapytania z danymi pod wskazany adres (serwera Neosa) i obsługę odpowiedzi.
     * Niejawnie dokleja parametr z nazwą profilu którego dotyczy dane żądanie, chyba że jest on podany explicite.
     * @instance
     * @param {string} [param] - parametry do przesłania, jeżeli są stringiem są przesyłane jako querystring przez GET, w przeciwnym wypadku jako json metodą POST
     */
    sendRequest: function(param, messageId, specialPlusHandling) {
        var url, method, val;
        if (typeof param === 'string' || param instanceof String) {
            url = this.addProfileParameter(param);
            method = 'get';
        } else {
            url = this.addProfileParameter(param.url);
            method = 'post';
            val = param.value;
            if (typeof val !== 'string' && !(val instanceof String)) {
                val = JSON.stringify(val);
            }
        }
        url = this.addUUIDParameter(url)

        var hasHash = (url.indexOf("#") > -1)
        if(hasHash) {
          url = url.replace(/#/g,"%23")
        }

        var hasLastSlash = url.indexOf("/", url.length - 1) !== -1;
        //specjalnie dla https by apache by dragonfly
        if (hasLastSlash)
            url = url.substring(0, url.length - 1);

        if(specialPlusHandling) {
            if(!this.pileOf) {
                this.pileOf = $('<div>').html("&#x1F4A9;").text();
            }
            url = url.replace(/[+]/g,this.pileOf);
        }

        url = encodeURIComponent(url);

        if (this.message_profiler) {
            console.info('profiling start');
            console.profile('message profiling');
            //firebug specific
        }

        var request;

        if (method == 'get') {
            this.lastRequest = {
                url: url,
                async: false,
                beforeSend: function(xhr){  
                    xhr.overrideMimeType( "text/plain; charset=utf-8" );
                }
            }
        } else if (method == 'post') {
            this.lastRequest = {
                type: "POST",
                url: url,
                data: val,
                contentType: 'application/json; charset=utf-8',
                beforeSend: function(xhr) {  
                    xhr.overrideMimeType( "text/plain; charset=utf-8" );
                }
            }
        }
        this.lastMessageId = messageId;

        //dla obsługi odswiezania tokenu dostepowego
        //musimy umiec ponowic wywolanie poprzedniej
        //funkcji

        this.executeRequest(this.lastRequest, messageId);
    },

    executeRequest: function(requestBody, messageId) {
        var request = $.ajaxq("MessegeQueue", requestBody);
        var self = this;

        request.done(function(data) {
            self.addMessageToQueue(data);
            self.executeMessages(messageId);
            if (self.message_profiler) {
                console.profileEnd();
                //firebug specific
                console.info('profiling stop');
            }
            if(App.requestLog)
                App.requestLog.add(true)
        }).fail(function(data) {
            if(App.requestLog)
                App.requestLog.add(false, data.responseText)
            if (data.status == 403) {
                Utils.refreshURL("login.html");
            } else if (data.status == 401) {
                self.addMessageToQueue(data.responseText);
                self.executeMessages(messageId);
            } else
                console.error(data.responseText);
        });
    },

    //Funkcja wyci�ga z przes�anego komunikatu wiadomo�ci i umieszcza je w kolejce komunikat�w
    addMessageToQueue: function(data) {
        var startTagPosition = data.indexOf(':- ');

        if (startTagPosition >= 0) {
            var val = data.substring(startTagPosition + 3), xmlDoc;
            var isXML = false;

            //Sprawdzamy czy warto�� wiadomo�ci jest w postaci XML
            try {
                val = val.trim();
                if(val.startsWith('<') && !val.startsWith('<Div'))
                {   
                    xmlDoc = $.parseXML(val)   
                    isXML = true;
                }
            } catch (e) {
                isXML = false;
            }
            var self = this;
            //dodanie wiadomo�ci do kolejki z xmla

            if (isXML) {
                var messages = $(xmlDoc).find('message');
                var len = messages.length;
                for (var i = 0; i < len; i++) {
                    var formUUID = $(messages[i]).children('UUID').text();
                    self.quequeMessage.push({
                        data: messages[i],
                        isXML: true,
                        formUUID: formUUID
                    });
                }

            }//dodanie wiadomo�ci do kolejki w przypdaku gdy	 widomo�� nie jest w postaci xml
            else {
                //dodanie wiadomo�� na ostatni� pozycj� listy wiadomo�ci
                this.quequeMessage.push({
                    data: val,
                    isXML: false
                });
                //console.info("Polecenie nie XML: " + data);
            }
        } else
            console.error("Nierozpoznane polecenie " + data);
    },

    /**
     * Zmienna do blokowania wykonywania komunikatów z kolejki.
     * Jeżeli jest większa od zera to komunikaty nie będą przetwarzane
     */
    blockMessageQueue: 0,

    /**
     * Funkcja blokuje wykonywanie komunikatów poprzez zwiększanie wartości zmiennej blockMessageQueue
     */
    lockMsgQueue: function() {
        this.blockMessageQueue++;
    },

    /**
     * Funkcja blokuje wykonywanie komunikatów poprzez zmniejszanie wartości zmiennej blockMessageQueue
     */
    unlockMsgQueue: function() {
        this.blockMessageQueue--;
    },

     needGui: function(messageName) {
        return messageName == 'SSHOWFORM' || messageName == 'SCLOSEFORM' || messageName == 'SUPDATEFORM' || messageName == 'SUPDATEDATA' ||
            messageName == 'SLOCATERECORD' || messageName == 'SUPDATEDATASET' || messageName == 'SUPDATEDATASETSTATE' || messageName == 'SDATASETACTION' ||
            messageName == 'SNOTIFYDATA' || messageName == 'SCALLFUNCTION' || messageName == 'SCALLACTION' || messageName == 'SGETRESOURCES' ||
            messageName == 'SHOWPOPUPMENU' || messageName == 'SSHOWMESSAGE' || messageName == 'SCHANGENOTIFICATION' || messageName == 'UPDATEFLOATINGBUTTONSLIST' ||
            messageName == 'VIEWSETTINGSCHANGED' || messageName == 'UPDATEFAVORITES' || messageName == 'SFORMCUSTOMACTION' ;
    },

    /**
     * Główna pętla obsługi komunikatów przychodzących z serwera.
     * @param {number} [messageId] - opcjonalny parametr związany z obsługą przetwarzania ShowForm
     * @param {string} [formsToRefresh] - opcjonalny parametr związany z obsługą przetwarzania ShowForm
     * @return {bool} false oznacza, że zostały przetworzone wszystkie komunikaty, true oznacza, że niektóre komunikaty zostały dodane do zachowania na później
     */
    executeMessages: function(messageId, formsToRefresh) {
        if (this.blockMessageQueue > 0)
            return false;

        var self = this;
        if (!formsToRefresh) {
            formsToRefresh = new Hashmap(function(form) {
                return form.formInstanceID;
            }
            );
        }

        while (this.quequeMessage.length > 0) {
            var command, val, MessageInstanceID, id, uuid, symbol, xmlDoc;

            var currentMessage = this.quequeMessage.shift();
            if (currentMessage) {
                if (currentMessage.isXML) {
                    command = $(currentMessage.data).find('Command').text();
                    val = $(currentMessage.data).find('Val').text().split("&#x20;").join(" ");
                    MessageInstanceID = $(currentMessage.data).find('MessageInstanceID').text();
                    id = $(currentMessage.data).find('ID').text();
                    uuid = $(currentMessage.data).find('UUID').text();
                    symbol = $(currentMessage.data).find('Symbol').text();

                    var form;
                    if (App.gui && App.gui.navigator && command != "SSHOWFORM" && // Showform inaczej jest obs�ugiwany i on sam doda form� do kolekcji
                    command != "SSHOWMESSAGE" && // SSHOWMESSAGE wy�wietla okno komunikatu, kt�re jest wy�wietlane jednorazowo
                    id != "0") {
                        form = App.gui.navigator.formStack.findForm(id).form;

                        if (!form) {
                            form = App.gui.getFormContainingDataset(id, "executeMessages");
                            if (!form)
                                console.info("nie uda�o si� znale�� formy z datasetem o id " + id);
                        }

                        if (form) {
                            if (command == "SCLOSEFORM") {
                                //skoro formę zamykamy to na pewno nie będzie sensu jej layoutować
                                formsToRefresh.remove(form);
                            } else if (form.formStyle != "M" || (form.formStyle == "M" && form.stackCurrentForm)) {
                                //jeśli forma jest modalna lub MDI ale tylko ta na wierzchu, to będzie potrzeba aby ją layoutować
                                //po zakończeniu przetwarzania komunikatów
                                formsToRefresh.add(form);
                            }
                        }
                    }
                    
                    if(this.Gui == undefined && command != 'SGETSETTINGS' && this.needGui(command)){
                        continue;
                    }

                    //console.info("MessageInstanceID: " + MessageInstanceID + ". command: " + command + ". val: " + val + ". id: " + id + ". uuid: " + uuid + ". symbol: " + symbol)
                    //wywolanie komendy przeslanej w komunikacie
                    switch (command) {
                    case 'SDEBUGLOG':
                        this.showDebug(val);
                        break;
                    case 'SGETSETTINGS':
                        this.App.getSettings(val);
                        this.App.addgui();
                        this.App.gui.init(window.run);
                        break;
                    case 'SSHOWFORM':
                        this.lockMsgQueue();
                        var deferredExecution = App.gui.showForm(id, uuid, val, currentMessage.formUUID, messageId, formsToRefresh);
                        //jeśli nowa forma będzie pokazana, to ona wywoła przetworzenie pozostałych komunikatów w kolejce
                        if (deferredExecution) {
                            return true;
                        }
                        break;
                    case 'SCLOSEFORM':
                        App.gui.navigator.closeForm(id, symbol);
                        break;
                    case 'SUPDATEFORM':
                        new Promise(resolve => {
                            this.Gui.updateFormByID(id, uuid, symbol, val);
                        });
                        break;
                    case 'SUPDATEDATA':
                        this.Gui.updateData(id, uuid, symbol, val);
                        break;
                    case 'SVALIDATEDATA':
                        this.Gui.validateData(id, uuid, val);
                        break;
                    case 'SLOCATERECORD':
                        this.Gui.locateRecord(id, uuid, val, symbol);
                        break;
                    case 'SUPDATEDATASET':
                        this.Gui.updateDataset(id, symbol, val);
                        break;
                    case 'SUPDATEDATASETSTATE':
                        this.Gui.updateDatasetState(id, symbol, val);
                        break;
                    case 'SDATASETACTION':
                        this.Gui.datasetAction(id, symbol, val);
                        break;
                    case 'SNOTIFYDATA':
                        this.Gui.notifyData(id);
                        break;
                    case 'SCALLFUNCTION':
                        this.Gui.callFunction(id, uuid, symbol, val);
                        break;
                    case 'SCALLACTION':
                        this.Gui.callAction(id, uuid, symbol, val);
                        break;
                    case 'SGETRESOURCES':
                        this.Gui.getResources(val);
                        break;
                    case 'SHOWPOPUPMENU':
                        this.Gui.showPopupMenu(id, uuid, val);
                        break;
                    case 'SSHOWMESSAGE':
                        if (this.Gui)
                            this.Gui.showMessage(val);
                        break;
                    case 'SCHANGENOTIFICATION':
                        if (this.Gui && this.Gui.navigator && this.Gui.navigator.kendoToolbar)
                            this.Gui.navigator.addNotificationElement(val, true);
                        break;
                    case 'SGETPROFILENAME':
                        $("#labelProfileName").text(val);
                        $("#labelProfileName").removeClass("Hide");
                        $("#mainMenuTitle").text(val);
                        this.App.ProfileNameTranslated = val;
                        break;
                    case 'SFILETRANSFER':
                        this.sFileTransfer(val);
                        break;
                    case 'SGETNEOSVERSION':
                        App.Version = val;
                        console.info("!!! Wersja Neosa: '" + val + "' !!!");
                        break;
                    case 'SREQUESTLOGSAVED':
                        if (val == 'Y') {
                            window.clearInterval(App.requestLog.timerSendToServer)
                            App.requestLog.markLogsAsSent();
                        }
                        break;
                    case 'COPYTOCLIPBOARD':
                        Utils.copyToClipboard(val);
                        break;
                    case 'UPDATEFLOATINGBUTTONSLIST':
                            this.Gui.updateFloatingButtonsList(id, val);
                        break;
                    case 'VIEWSETTINGSCHANGED':
                        this.Gui.viewSettingsChanged(id, val);
                        break;
                    case 'UPDATEFAVORITES':
                        this.Gui.updateFavorites(symbol, val);
                        break;
                    case 'SFORMCUSTOMACTION':
                        this.Gui.formCustomAction(id, val);
                        break;
                    case 'SGETDEFAULTLANGUAGE':
                        this.App.defaultLanguage = val;
                        var url = new URL(window.location);
                        if (!url.searchParams.get('lang') && val) {
                            url.searchParams.set("lang", val);
                            window.location = url.href;
                        }
                        break;
                    case 'SGETLANGUAGERESOURCES':
                        this.App.getLanguageResources(val);
                        break;
                    default:
                        console.info("Nierozpoznane polecenie XML:" + command);
                        break;
                    }
                } else {
                    this.customCommand(currentMessage);
                }
            }
        }
        //wykonaj layout form w kolekcji, ale tylko tych, które tego potrzebują
        this.refreshForms(formsToRefresh);
        return false;
    },

    /*
     * Metoda wyświetla w konsoli javascriptu komunikat przysłany z serwera za pomocą funkcji DEBUG.Log
     */
    showDebug: function(val) {
        var doc = $.parseHTML("<debug>" + val + "</debug>");
        var type = $("img", doc).attr("src");
        $("img", doc).attr("src", "");
        var text = $(doc).text();
        switch (type) {
        case "MI_STOP":
            console.error(text);
            break;
        case "MI_EXCLAMATION":
            console.warn(text);
            break;
        default:
            console.info(val);
            break;
        }
    },

    sFileTransfer: function(xml) {
        if (xml) {
            var $xml = $(xml);

            var id = $xml.attr('transferid');
            var direction = $xml.attr('direction');
            var repository = $xml.attr('repository');
            var filename = $xml.attr('filename');
            var localfilepath = $xml.attr('localfilepath');
            var filesize = $xml.attr('filesize');

            if (direction == "D") {
                //download
                this.sDownloadFile(id);
            }
        }
    },

    sDownloadFile: function(id) {
        var url = App.getRelativeCurrentURL();
        var self = this;
        url += 'download?transferid=' + id;
        url = this.addProfileParameter(url);

        if (this.Gui.device.ios) {
            var windowReference = window.open(url);
            windowReference.onload = function() {
                onSuccess();
            }
            if (!windowReference || windowReference.closed || typeof windowReference.closed == 'undefined') {
                onError("Wyskakujące okna są zablokowane. Odblokuj je w ustawieniach");
            }
        } else {
            var timer = window.setInterval(checkSuccess, 1000);

            var $idown = $('<iframe>', {
                id: 'idown',
                src: url
            }).hide().appendTo('body');
            $idown.load(function() {
                //gdy sukces, nie odpali się w chromie
                window.clearInterval(timer);
                var content = $idown.contents().text();
                if (content.length > 0) {
                    onError(content);
                } else {
                    onSuccess();
                }
            });
        }

        function checkSuccess() {
            var cookie = self.getCookie('file_' + id);
            if (cookie && cookie == 'ok') {
                window.clearInterval(timer);
                onSuccess();
            }
        }

        function onSuccess() {
            sendMessage(id, 'ok');
        }

        function onError(error) {
            var xml = $('<div/>');
            xml.attr('T', error);
            xml.attr('C', "Błąd transferu plików");
            xml.attr('Ic', '3');
            xml.attr('B', 'True');
            self.Gui.showMessage(xml);
            sendMessage(id, 'error', error);
        }

        function sendMessage(id, status, message) {
            var xml = new XmlBuilder();
            xml.openNode('FileInfo');
            xml.addTextNode('status', status);
            if (message)
                xml.addTextNode('message', message);
            xml.closeNode();
            var xmlstr = xml.toString();

            self.BP.fileTransferEnd(id, xmlstr);
        }
    },

    /**
     * Metoda dla form w przekazanej kolekcji wyłącza znacznik blokowania layoutu i wykonuje layout (rozmieszczenie komponentów na ekranie)
     * Efektywnie jest to wykonywane tylko dla tych form, które otrzymały znacznik, że tego potrzebują (needLayout)
     */
    refreshForms: function(fs) {
        var forms = fs.getAll();
        var len = forms.length;

        for (var i = 0; i < len; i++) {
            var form = forms[i];
            form.refreshLayout();
            if (!form.form.hideForFirstTime) {
                form.form.hideForFirstTime = true;
            }
        }
    },

    handleNavigator: function(navData) {
        var self = this;
        if (!this.Gui.navigator.initialized) {
            this.Gui.initNavigator($(navData));
            if(App.Settings.Profile) {
                self.BP.getProfileName(App.Settings.Profile);
            }
            this.App.runAction();
            this.Gui.resizeMiddlePane();
            this.Gui.navigator.addBackButton();
            this.Gui.navigator.hideBackButton();
            this.Gui.navigator.addLabel("profileName", this.App.ProfileNameTranslated || "");
            if (this.Gui.navigator.setCss) {
                this.Gui.navigator.setCss();
            }
            Utils.hideProgress();
    
            if (!this.App.methodExecuted && !this.App.oemMode) {
                this.App.methodExecuted = true
                if (!this.App.sessionStorage.getBool("StartMethodExecuted") && this.App.action == "") {
    
                    //opóźniamy pokazanie pierwszego okna aż się załadują wszystkie style. Inaczej mogą źle działać pomiary.
                    setTimeout(function() {
                        self.BP.runStartUp();
                    }, 50);
                    setTimeout(function() {
                        self.BP.showStartingDashboard();
                    }, 50);
                }
                this.BP.getNotificationsNow();
            }
        } else {
            this.Gui.navigator.rebuildMenu($(navData));
        }
    },

    locationIs:function(page) {
      var url = location.href.replace(location.search, "");
      return url.endsWith(page);
    },

    redirectToPageIfNeeded: function(page) {
        if (!this.locationIs(page)) {
            var url = location.origin + "/" + page;
            Utils.refreshURL(url);
            return true
        }
        return false
    },

    handleLogin: function(params) {
       if (params.AccessToken && this.refreshTokenChallenge) {
            //sama odpowiedz powoduje ustawienie ciasteczka
            this.refreshTokenChallenge = false;
            if (params.AccessToken) {
                this.App.storeAccessToken(params.AccessToken, params.AccessET);
            }
            if (params.Reload && !this.App.login && params.UserLogged != "True") {
                this.clearTimersAfterCancelingReload();
                if (!this.App.getReloadConfirmationShown()) {
                    var self = this;
                    this.App.setReloadConfirmationShown(true);

                    App.gui.confirm(params.Reload,
                    this.App.LanguageResources.connectionLost,"",
                    {
                      onClick:function() {
                        self.App.storeAccessToken(null, null)
                        location.reload();
                      }
                    }, {
                      onClick:function() {
                        self.reloadConfirmationResigned = true
                        self.App.setReloadConfirmationShown(true);
                      }
                    })
                }
            } else {
                //ponawiamy poprzednie wywołanie
                this.executeRequest(this.message, this.messageId);
                this.message = null;
                this.messageId = null;
            }
        } else if (params.UserLogged == "True") {
            this.App.setReloadConfirmationShown(false)
            if (params.RefreshToken) {
                this.App.storeRefreshToken(params.RefreshToken, params.RefreshET);
            }
            if (params.AccessToken) {
                this.App.storeAccessToken(params.AccessToken, params.AccessET)
            }
            if(!this.redirectToPageIfNeeded("index.html")){
              var self = this;
              this.handleReload(params, function() {
                if(!self.redirectToPageIfNeeded("index.html"))
                {
                    location.reload();
                }
              })
            }
        } else if (params.UserLogged == "False") {
            var self = this
            this.refreshTokenChallenge = false;
            if (this.App.gui) {
                self.handleReload(params, function() {
                  self.App.gui.signOut(function() {
                      self.redirectToPageIfNeeded("login.html");
                  });
                })
            }
        } else if (params.UserLogged == "Challenge") {
            if(!this.refreshTokenChallenge) {
              var refreshToken = this.App.loadRefreshToken();
              this.refreshTokenChallenge = true;
              this.message = this.lastRequest;
              this.Common.refreshToken(refreshToken.val);
            } else {
                console.info("Consecutive challenges are ignored");
            }
        } else {
            alert(this.App.LanguageResources.unhandledLoginStatement + params.Message);
            this.refreshTokenChallenge = false;
        }
    },

    clearTimersAfterCancelingReload: function() {
      if(this.timersCleared) return
      this.timersCleared = true
      //nie wysyłamy bezsensownych komunikatów na serwer bo one przeładowują stronę
      if(this.App.requestLog && this.App.requestLog.timerSendToServer) {
        clearInterval(this.App.requestLog.timerSendToServer);
        this.App.requestLog.timerSendToServer = undefined
      }
      if(this.App.pingSessionTimer) {
        clearInterval(this.App.pingSessionTimer)
        this.App.pingSessionTimer = undefined
      }
      if(this.App.preemptiveTokenRefreshTimeout) {
        clearTimeout(this.App.preemptiveTokenRefreshTimeout)
        this.App.preemptiveTokenRefreshTimeout = undefined
      }
      if(this.App.preemptiveRefreshTokenRefreshTimeout) {
        clearTimeout(this.App.preemptiveRefreshTokenRefreshTimeout)
        this.App.preemptiveRefreshTokenRefreshTimeout = undefined
      }
      if(this.App.notificationTimer) {
        clearInterval(this.App.notificationTimer)
        this.App.notificationTimer = undefined
      }
      //pokażmy na ekranie że utracono połączenie
      $(".user-info-wrapper > i").attr('class', 'fa fa-plug clred')
      $(".user-info-wrapper > i").kendoTooltip({
          autoHide: true,
          position: "top",
          content: this.App.LanguageResources.clickToReload,
      })
      $("#user-menu").remove();
      $(".user-info-wrapper").click(function() {
        App.gui.confirm(App.LanguageResources.doReload,
        App.LanguageResources.connectionLost,"",
        {
          onClick:function() {
            location.reload();
          }
        })
      })
    },

    handleLoginError: function(params) {
      if(this.App.login) {
        var $logbtn = $("#btnLogin1")

        if($logbtn.length > 0) {
          /*$logbtn.vibrate({
             speed: 50,             // Vibration speed in milliseconds
             stopAfterTime: 1       // Stop Vibrating after a fixed time in seconds
          })*/
        }
      }
      alert(params.Message)
    },

    handleReload: function(params, onSuccess) {

        if(params.LanguageResources === "1")
		{
		   this.App.LanguageResources.emptyComboboxItemText = params.emptyComboboxItemText;
		   this.App.LanguageResources.noDataTemplate = params.noDataTemplate;
		   this.App.LanguageResources.unhandledStatement = params.unhandledStatement;
		   this.App.LanguageResources.noDivisionForLogin = params.noDivisionForLogin;
		   this.App.LanguageResources.search = params.search;
		   this.App.LanguageResources.connectionLost = params.connectionLost;
		   this.App.LanguageResources.clickToReload = params.clickToReload;
		   this.App.LanguageResources.doReload = params.doReload;
		   this.App.LanguageResources.redPlug = params.redPlug;
		   this.App.LanguageResources.culture = params.culture;	
		   this.App.LanguageResources.yes = params.yes;
		   this.App.LanguageResources.no = params.no;
		   this.App.LanguageResources.copy = params.copy;
		   this.App.LanguageResources.rowCount = params.rowCount;
		   this.App.LanguageResources.clearAllSelection = params.clearAllSelection;
           this.App.LanguageResources.unhandledLoginStatement = params.unhandledLoginStatement;
           this.App.LanguageResources.resetPasswordMailSent = params.resetPasswordMailSent;
           this.App.LanguageResources.passwordsDontMatch = params.passwordsDontMatch;
           this.App.LanguageResources.passwordResetFailed = params.passwordResetFailed;
           this.App.LanguageResources.passwordResetSucceeded = params.passwordResetSucceeded;
           this.App.LanguageResources.choose = params.choose;
		}
        if(params.Message)
        {
          console.warn(params.Message);
        }
        if(params.Reload) {
          console.warn(params.Reload);
        }
        if(this.refreshTokenChallenge && this.App.getReloadConfirmationShown()) {
          console.info("Reloading skipped on user request")
          return false;
        }
        if (params.Reload) {
            if(this.App.login) return false;

            this.clearTimersAfterCancelingReload();
            var shown = this.App.getReloadConfirmationShown();
            if (!shown) {
                this.App.setReloadConfirmationShown(true);

                var self = this;
                App.gui.confirm(params.Reload,
                this.App.LanguageResources.connectionLost,"",
                {
                  onClick:function() {
                    if(onSuccess) onSuccess()
                  },
                  text: self.App.LanguageResources.yes
                }, {
                  onClick:function() {
                    self.reloadConfirmationResigned = true
                    self.App.setReloadConfirmationShown(true);
                  },
                  text: self.App.LanguageResources.no
                })
                return
            }
            if(params.UserLogged != "True"  && !this.reloadConfirmationResigned){
                if(onSuccess) onSuccess()
            }
            return
        } else
        if(!params.Reload && params.Message) {
            this.handleLoginError(params)
        } else
        if(params.Message && params.Message.includes("logowa") && this.App.login) {
          this.handleLoginError(params)
          location.reload();
        }
        return
    },

    handleDivision:function(divisions) {
      var $poddzial = $("#pOddzial")

      if(divisions) {
        var datasource = [App.LanguageResources.noDivisionForLogin];

        var $divisions = $("#tbOddzial")
        var dropDown = $divisions.data("kendoDropDownList")

        if(divisions.replace(/;/g,"").length >0) {
          datasource = [];

          var divtab = divisions.split(';')
          for (var el in divtab) {
            if(divtab[el] && divtab[el].length>0)
              datasource.push(divtab[el]);
          }
        }

        if(dropDown) {
          if(datasource.length > 1) {
            $("#pOddzial").css('visibility','visible');
          } else {
            $("#pOddzial").css('visibility','hidden');
          }

          dropDown.setDataSource(datasource)

          var selected = false
          if (App.Settings.ProfileUsesDepartments == "yes") {
            var department = App.localStorage.get("department" + this.getProfile())
            if(department && department.length > 0) {
              dropDown.select(function(item) {
                  var val = item == department
                  if(val) selected = true
                  return val
              })
            }
          }

          if(!selected) {
             dropDown.select(0)
          }
        }
      }
    },

    /**
     * Funkcja do obsługi komunikatów, które nie są wymienione w switch głównej pętli przetwarzania komunikatów
     * @param {string} currentMessage - wiadomość przesłana komunikatem od serwera
     */
    customCommand: function(currentMessage) {
        //pobranie parametr�w z komunikatu
        var tokens = currentMessage.data.split(String.fromCharCode(13, 10));

        //Poniższy kod opiera się na założeniu, że jedynym custom command zaczynającym się od "<Div"
        //jest komunikat zwracany przez BPGetNavigator. To może ulec zmianie, dlatego zostawiam ten komentarz.
        if (tokens.length > 0 && tokens[0].indexOf('<Div') == 0) {
            this.handleNavigator(tokens[0]);
            return;
        }

        var params = {};
        $(tokens).each(function() {
            if (this != "") {
                var record = this.split(":- ");
                params[record[0]] = record[1];
            }
        });

        //obs�uga funkcji loguj�cej
        if (params.UserLogged) {
            this.handleLogin(params);
        } else if (params.UserLoggedOut) {
          //je�li dostajemy komunikat zwrotny dla fukcji logoutUser to nic nie robimy
        } else if (params.Divisions) {
          this.handleDivision(params.Divisions)
        } else if (params.PasswordReset) {
            this.afterPasswordReset(params.PasswordReset);
        } else {
            //sprawdzenie czy klient zalogowany
            alert(this.App.LanguageResources.unhandledStatement + currentMessage.data);
        }
    },

    getViewSettingsXML: function(columns) {
        var xml = new XmlBuilder();
        xml.openNode('Columns');
        columns.forEach(function(column) {
            xml.openNode('ColumnSettings');
            xml.addAttribute('formfielduuid', column.ffuuid);
            if (column.width != null) { xml.addTextNode('columnwidth', column.width); }
            if (column.sortdir != null) { xml.addTextNode('sortdirection', column.sortdir); }
            if (column.sortorder != null) { xml.addTextNode('sortindex', column.sortorder); }
            if (column.order != null) { xml.addTextNode('columnindex', column.order); }
            if (column.ishidden != null) { xml.addTextNode('ishidden', column.ishidden ? "Y" : "N"); }
            xml.closeNode();
        });
        xml.closeNode();
        var result = xml.toString();
        return result;
    },

    afterPasswordReset: function(result) {
        if(result == "True") {
            alert(this.App.LanguageResources.passwordResetSucceeded);
            Utils.removeTokenAndRefreshURL(location.origin + "/" + "login.html"); 
        } else {
            alert(this.App.LanguageResources.passwordResetFailed);
        }
    }
}

/// <reference path="../jquery/jquery-1.10.2-vsdoc.js"/>
/// <reference path="../kendo/vsdoc/kendo.all-vsdoc.js"/>
'use strict';

function Gui(application) {
    if (application)
        this.communication = application.communication;

    this.divWindows = $("#windows");

    this.device = Device();
    this.device.handleOrientation = function () {
        this.resizing();
    }
    this.device.init();

    this.margin = 2;
    this.border = 1;
    this.idgenerator = 1;
    this.paddingForArtificialPanel = 2;
    this.mobileVersion = this.device.tablet || this.device.mobile;
    this.maxPanelHeaderHeight = 0; //zmierzona maksymalna wysokosc nagłówka panela
    this.maxTabStripHeaderHeight = 0; //zmierzona maksymalna wysokość nagłówka zwykłego tabstripa
    this.maxBigTabStripHeaderHeight = 0; //zmierzona maksymalna wysokość nagłówka dużego tabstripa
    this.maxControlHeaderHeight = 0;

    this.maxTimeStampWrapperBorderHeight = 0;
    this.maxTimeStampWrapperBorderWidth = 0;
    this.maxTimeStampWrapperPadding = 0;

    this.maxDateWrapperBorderHeight = 0;
    this.maxDateWrapperBorderWidth = 0;
    this.maxDateWrapperPadding = 0;

    this.maxDropdownWrapperBorderHeight = 0;
    this.maxDropdownWrapperBorderWidth = 0;
    this.maxDropdownWrapperPadding = 0;

    this.mouseLastPosX = 0;
    this.mouseLastPosY = 0;
  }

Gui.prototype = {

    layout: function (btns, okno) {

        var n = btns.length;
        var self = this;
        var icon_name, image, button_label, button_id, button, panel, width, text_but;

        var firstbutton;
        panel = $('<div class="panel">');
        for (var i = 0; i < n; i++) {

            //ID przycisku
            button_id = btns[i].id;
            text_but = "<button class='button k-button' id='" + button_id + "'>";


            // Label przycisku
            button_label = btns[i].label;
            text_but = text_but + " " + button_label + "</button>";
            button = $(text_but);

            var btnOnClick = btns[i].onClick
            if(!btnOnClick) {
              btnOnClick = function(arg) {
                self.communication.BP.callAction(arg);
              }
            }

            if(btns[i].methodName){
                btnOnClick = function(arg){
                    self.communication.BP.staticMethod(btns[arg].objectName, btns[arg].methodName, btns[arg].params);
                }
            }

            var btnArg = {
                id : button_id,
                click : btnOnClick
            }

            button.on("click", (function () {
                okno.setOptions({modal:false})
                okno.close();
                //DN: dodałem destory aby usuwać okno z drzewa DOM.
                okno.destroy();
                this.click(this.id);
            }).bind(btnArg));

            if(!firstbutton) {
              firstbutton = button
            }

            //Ikona
            if (btns[i].icon) {
                icon_name = btns[i].icon;
                image = Utils.getSpriteIconForButton(icon_name, true);
                button.prepend(image);
            }
            //Dodaj przycisk do panelu
            panel.append(button);
        }

        if(firstbutton)
        {
          setTimeout(
            function()
            {
              firstbutton.focus()
            }
          ,1);
        }

        return panel;
    },

    //pobranie sentecent
    getUnit: function () {
        if (!this.unit) {
            this.unit = 26;
        }
        return this.unit;
    },

    /**
     * Fukcja przelicza wysokość podaną w SM na pixele
     * @param {int} val - wartość w SM
     * @param {boolean} noMin - flaga, która mówi czy mamy zwracać minVal w przypadku
                                gdy wyliczona wartość jest mniejsza od minimalnej
     */
    scaleHeight: function (val, noMin) {
        if (typeof val !== 'undefined') {
          var minValue = this.getUnit();
          var value = this.getUnit() * val;
          if (value < minValue && !noMin) {
            return minValue;
          } else {
            return value;
          }
        }
    },

    /**
     * Fukcja przelicza szerokość podaną w SM na pixele
     * @param {int} val - wartość w SM
     * @param {boolean} noMin - flaga, która mówi czy mamy zwracać minVal w przypadku
                                gdy wyliczona wartość jest mniejsza od minimalnej
     */
    scaleWidth: function (val, noMin) {
        if (typeof val !== 'undefined') {
          var minValue = this.getUnit();
          var value = this.getUnit() * val;
          if (value < minValue && !noMin) {
            return minValue;
          } else {
            return value;
          }
        }
    },

    nextId: function () {
        return (this.idgenerator++);
    },

    _today : function() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1;

      if (dd < 10) {
          dd = '0' + dd
      }

      if (mm < 10) {
          mm = '0' + mm
      }

      var yyyy = today.getFullYear();
      var yy = (yyyy + '').substring(2, 4);
      today = yy + mm + dd;
      return today;
    },

    //funkcja do logowania uzytkownika
    login: function (user, password, department, lang) {
        App.localStorage.set("login" + this.communication.getProfile(), user);
        App.localStorage.set("department" + this.communication.getProfile(), department)
        App.localStorage.set("language" + this.communication.getProfile(), lang)

        var login = user;
        var oldPassword = CryptoJS.MD5(password).toString().toUpperCase();
        var password = CryptoJS.MD5("SENTE_" + password + "_NewPass").toString().toUpperCase();
        App.lang = lang;
        App.profile = Utils.operationsUrlParameters.getURLParameterValue("profile") || "";
        var device = this.getCurrentDevice();

        this.communication.Common.loginUserByLogin(login, password, this._today(), App.profile, oldPassword, device, department, App.lang)
    },

    reset: function (token, password) {
        var pass = CryptoJS.MD5("SENTE_" + password + "_NewPass").toString().toUpperCase();
        App.profile = Utils.operationsUrlParameters.getURLParameterValue("profile") || "";

        this.communication.Common.resetUserPassword(token, pass, this._today(), App.profile);
    },

    loginByGoogle: function(googleUser) {
      var id_token = googleUser.credential;
      var token = jwt_decode(id_token);
      var email = token.email;

      App.profile = Utils.operationsUrlParameters.getURLParameterValue("profile") || "";
      var device = this.getCurrentDevice();

      this.communication.Common.loginUserByGoogle(email, id_token, this._today(), App.profile, "", device)
    },

    sendChangePasswordEmail: function(login, lang){
      var profile = Utils.operationsUrlParameters.getURLParameterValue("profile") || "";
      this.communication.Common.sendChangePasswordEmail(login, profile, lang);
    },

    signOut:function(callback) {
      if(callback()){
        if(typeof(gapi) == "undefined"){
          return;
        }
        var auth2 = gapi.auth2.getAuthInstance();
        if(auth2)
          auth2.signOut();
      }
    },

    getCurrentDevice: function () {
        if (this.device.mobile)
            return 'phone';
        else if (this.device.tablet)
            return 'tablet';
        else return 'www';
    },

    getFirstSObject: function (element, type) {
        element = $(element);
        do {
            var sobj = getSObject(element, type);
            element = element.parent();
        } while (!sobj && element && element.length > 0)
        return sobj;

        function getSObject(element, type) {
              var result = element.data('SObject');
              if (!type || result instanceof type.constructor) {
                  return result;
              }
              return;
        };
    },


    /**
     * Funkcja pokazuje prosty balloon hint z tekstem (komunikat na dole ekranu)
     */
    showBalloonHint: function (text, icon) {
      var xml = $('<div/>');
      xml.attr('T', text);
      xml.attr('C', "");
      if(!icon) {
        icon = ""
      }
      xml.attr('Ic', icon);
      xml.attr('B', 'True');
      this.showMessage(xml);
    },

    /**
     * Funkcja służy do wyświetlania wiadomości w postaci okienka albo baloonHint
     * @param {object} xml - xml z wiadomością
     */
    showMessage: function (xml) {
        var caption = $(xml).attr("C") || "";
        var text = $(xml).attr("T") || "";
        var icon = $(xml).attr("Ic");
        var balloon = $(xml).attr("B");
        var formInstance = $(xml).attr("Fi");
        var action = $("Action", xml);
        var staticAction = $("StaticAction", xml);

        switch (icon) {
            case "0": //WARNING
                icon = "MI_EXCLAMATION";
                break;
            case "1": //INFORMATION
                icon = "MI_INFORMATION";
                break;
            case "2": //QUESTION
                icon = "MI_QUESTION";
                break;
            case "3": //STOP
                icon = "MI_STOP";
                break;
        }

        var img = Utils.getSpriteIcon(icon, true);

        if (balloon == "True") {
            var self = this;
            var wrapper = $("<div class='notificationBox'/>");

            img = Utils.getSpriteClasses(icon, true);
            var buttonImg = '\\Icon\\x-mark.png';
            if (!this.notifications) {
                var position = {
                  bottom: 30,
                  right: 5,
                  pinned: true,
                };
                var stacking = "up";

                this.notifications = wrapper.kendoNotification({
                    stacking: stacking,
                    button: true,
                    position: position,
                    autoHideAfter: 16000,
                    hideOnClick: true,
                    show: function (e) {
                        $(".notificationBox", e.element).click(function (e) {
                            if ($(e.target).attr("id") != "notificationBoxCloseButton") {
                                var actionUUID = "";
                                if (App.gui.navigator.hasAction(actionUUID)) {
                                    var action = App.gui.navigator.getAction(actionUUID);
                                    App.communication.BP.staticMethod(action.objectUUID, action.methodName, "");
                                }
                                else {
                                    self.communication.BP.callAction(actionUUID);
                                }
                            }
                        });
                    },
                    templates: [{
                        type: "info",
                        template: "<div class='notificationBox' actionUUID='#: actionUUID#'><div class='notificationBoxButtons'><img id='notificationBoxCloseButton' src='#: buttonImg#'></div><div class = 'notificationBoxBody'><div class='notificationBoxBodyIcon'><span class='#: image#'><div class = 'notificationBoxIcon'></div></div><div class='notificationBoxBodyMessage'><span>#= title #</span><p>#= message#</p></div></div></div>"
                    }]
                }).data("kendoNotification");
            }

            if (caption.length > 0) {
                caption = caption.replace(/\n/g, '<br/>');
            }
            if (text.length > 0) {
                text = text.replace(/\n/g, '<br/>');
            }

            this.notifications.show({
                image: img,
                title: caption,
                message: text,
                buttonImg: buttonImg,
                actionUUID: ""
            }, "info");
        }
        else if (balloon == "False") {
            var buttons = [], id_b = "", label_b = "", icon_b = "";
            if (action.length > 0) {
              for (var i = 0; i < action.length; i++) {
                id_b = $(action[i]).attr("ID");
                label_b = $(action[i]).attr("L");
                icon_b = $(action[i]).attr("aIc");
                buttons.push({ icon: icon_b, label: label_b, id: id_b });
              }
            }
            else {
              buttons.push({icon: "", label: "ok", id: "messageBoxOkButton"});
            }

            this.showMessageBox(text, caption, img, buttons)
        }
        else if (staticAction.length > 0)
        {
            var buttons = [], label_b = "", icon_b = "", objectName_b ="", methodName_b = "", id_b = "", params_b = "";
            for (var i = 0; i < staticAction.length; i++) {
                id_b = i;
                label_b = $(staticAction[i]).attr("Label");
                icon_b = $(staticAction[i]).attr("Icon");
                objectName_b = $(staticAction[i]).attr("ObjectName");
                methodName_b = $(staticAction[i]).attr("MethodName");
                params_b = "";
                var params = $("Parameters", staticAction[i]);
                if(params.length>0)
                {
                    for(var par of $("Parameter", params))
                    {
                       params_b = params_b + ";" + $(par).attr("Name") + "=\"" + $(par).attr("Value") +"\"";
                    }
                    if(params_b.length > 0)
                      params_b = params_b.slice(1, params_b.length); // obcinamy ';' kt�ry jest na pierwszej pozycji
                }
                buttons.push({id: id_b, label: label_b, icon: icon_b, objectName: objectName_b, methodName: methodName_b, params: params_b});
            }
            this.showMessageBox(text, caption, img, buttons);
        }
        else
        {
          var buttons = [], label_b = "", icon_b = "", objectName_b ="", methodName_b = "", id_b = "", params_b = "";  
          buttons.push({icon: "", label: "ok", id: "messageBoxOkButton"});  
          this.showMessageBox(text, caption, img, buttons);  
        }

    },

    confirm: function(text, caption, icon, yes, no) {
      var yesid = Utils.generateUUID()
      var noid = Utils.generateUUID()

      var fn = function(){}
      var fnyes = fn;
      if(yes && yes.onClick)
        fnyes = yes.onClick
      var fnno = fn;
      if(no && no.onClick)
        fnno = no.onClick

      var buttons = [
        {id: yesid,
        icon: (!!yes) ? (yes.icon || "") : "",
        label: (!!yes) ? (yes.text || "Tak") : "Tak",
        onClick: fnyes},
        {id: noid,
        icon: (!!no) ? (no.icon || "") : "",
        label: (!!no) ? (no.text || "Nie") : "Nie",
        onClick: fnno},
      ]

      this.showMessageBox(text, caption, icon, buttons, (!!no) ? no.OnClick : function(){})
    },

    showMessageBox: function(text, caption, img, buttons, onCloseFunc) {
      var msgForm = $("<div class='message'>");
      var div_text = "<div class = 'text' style = \"white-space: pre-line;\">" + text + "</div>";
      var text_gotowy = $(div_text);

      msgForm.append(text_gotowy);

      $("#msgs").append(msgForm);
      msgForm.kendoWindow({
          maxWidth: "700px",
          maxHeight: "350px",
          width: "75%",
          title: caption,
          modal: true,
          actions: [
              "Close"
          ],
          resizable: false,
          close: function(e) {
            if(onCloseFunc) {
              onCloseFunc();
            }
            this.setOptions({modal:false})
            this.destroy();
          }
      });
      var okno = $(msgForm).kendoWindow().data("kendoWindow");
      okno.center();
      var lay = this.layout(buttons, okno);
      msgForm.append(lay);
      //dodawanie ikony do tytułu w odpowiednim oknie
      $(".k-window-title", okno.element.parent()).prepend(img);
    },

    /**
     * Funkcja do modyfikowania istniejącej formy.
     * @param {string} id - id formy
     * @param {string} uuid - uuid kontrolki, która ma być modyfikowana
     * @param {string} symbol - symbol właściwości, która ma być zmodyfikowana (enabled, visible, hint, value, itd.)
     * @param {string} val - wartość jaka ma zostać ustawiona dla właściwości
     */
    updateFormByID: function (id, uuid, symbol, val) {
        var form = App.gui.navigator.formStack.findForm(id);
        if (form.form) {
            form = form.form;
            var tmp = form.findAllControlWithElementUUID(uuid);

            var tmplen = tmp.length;
            for (var i = 0; i < tmplen; i++) {
                var object = tmp[i];
                this.updateForm(form, object, uuid, symbol, val);
            }

            if (tmplen == 0) {
                console.info("updateFormByID: Nie znaleziono elemntu o uuid: '" + uuid + "' na formie o id: '" + id + "' podczas próby ustawienia '" + symbol + "' na '" + val + "'");
            }
        }
        else {
            console.info("updateForm: Brak formy o id = " + id);
        }

    },

    /**
     * Funkcja służy do otwierania form z stosu
     * @param {string} id - id formy
     * @param {string} uuid - obecnie nie wykorzystywany
     * @param {string} formXml - xml z opisem formy którą mamy pokazać
     * @param {string} formUUID - uuid formy
     * @param {string} messageId - id komunikatu, który wywołał komendę showForm
     * @param {string} formsToRefresh - kolekcja form do odświeżenia po zakonczeniu kolejki komunikatów
     */
    showForm: function (id, uuid, formXml, formUUID, messageId, formsToRefresh) {
        var form = this.navigator.formStack.findForm(id);
        var self = this;
        var deferredExecution = !form.form;
        Utils.showProgress(function () {
            console.time("ShowForm");
            App.gui.navigator.showForm(id, uuid, formXml, formUUID, messageId);
            var form = App.gui.navigator.formStack.findForm(id);
            formsToRefresh.add(form.form);
            App.communication.unlockMsgQueue();
            deferredExecution = App.communication.executeMessages(messageId, formsToRefresh);

            form.form.datasets.getAll().forEach(function(ds) {
                if (ds.dependentPresenters) {
                    ds.dependentPresenters.forEach(function(element) {
                        if (element.itemType == "TABLE" && element.showProgress) {
                            element.showProgress();
                        }
                    });
                }
            });
            
            console.timeEnd("ShowForm");
          //rekurencyjne wywołanie przetwarzania dalszej części kolejki
        }, !form.form);
        return deferredExecution;
    },

    updateForm: function (form, object, uuid, symbol, val, sym) {
        if (!object) {
            if (!this.updateValueActionDataset(form, uuid, symbol, val)) {
                console.info("nie znaleziono elementu o uuid #" + uuid + " w funkcji 'updateForm'");
            }
        }
        else {
            object.update(symbol, val, sym);
        }
    },

    searchActionFromDataset: function (form, uuid) {
        var datasets = form.datasets.getAll();
        var len = datasets.length;

        for (var i = 0; i < len; i++) {
            var dataset = datasets[i];
            if (dataset.actionsByUUID.containsKey(uuid)) {
                return dataset.actionsByUUID.getValue(uuid);
            }
        }
        return null;
    },

    updateValueActionDataset: function (form, uuid, symbol, val) {
        var action = this.searchActionFromDataset(form, uuid);
        if (action != null) {
            switch (symbol) {
                case "ENABLED":
                    action.enabled = val;
                    break;
                case "VISIBLE":
                    action.visible = val;
                    break;
                default:
                    console.info("Brak obsługi pola '" + symbol + "' przy zmianie wartości akcji w dataset");
            }
            return true;
        }
        else {
            return false;
        }
    },

    getFormContainingDataset: function (id, callingFuncName) {
        //szukamy w formach na stosie
        var form = $.grep(App.gui.navigator.formStack.stackForms, function (e) { return e.datasets.containsKey(id) });

        //szukamy w słownikach dla form na stosie
        if (form.length < 1) {
            form = this.getDictFormContainingDataset(App.gui.navigator.formStack.stackForms, id);
        }

        //szukamy w formach okienek
        if (form.length < 1) {
            form = $.grep(App.gui.navigator.formStack.windowForms, function (e) {
                if(e.datasets) {
                    return e.datasets.containsKey(id)
                }
            });
        }

        //szukamy w słownikach dla form okienek
        if (form.length < 1) {
            form = this.getDictFormContainingDataset(App.gui.navigator.formStack.windowForms, id);
        }

        if (form.length == 1) {
            return form[0];
        } else if (form.length == 0) {
            console.info(callingFuncName + ": Brak formy z datasetem o id = " + id);
        }
        else {
            console.info(callingFuncName + ": Wiecej niz jedna forma z datasetem o id = " + id);
        }
        return null;
    },

    getDictFormContainingDataset: function (collection, id) {
        var form = [];
        var len = collection.length || 0;

        for (var i = 0; i < len ; i++) {
            var item = collection[i];
            form = $.grep(item.dictForms, function (e) {
                return e.datasets.containsKey(id)
            });

            if (form.length > 0) {
                break;
            }
        }

        return form;
    },

    /**
     * funkcja parsuje przekazanego XMLa oraz aktualizuje przyciski filtrów
     */
    updateFloatingButtonsList: function(id, xml){
        var buttons = [];
        $('ViewSettings', xml).each(function(index, filter) {
            buttons.push({uuid: $(filter).attr('UUID'), name: $(filter).attr('Name')});
        })

        var form = this.getFormContainingDataset(id, 'updateFloatingButtonsList');
        if(form && form.gridFound) {
            var ds = form.datasets.getValue(id);

            //Wyszukuję wszystkie podpięte tabele aby podświeltić na nich przyciski wybranych filtrów
            if (ds.dependentPresenters) {
                ds.dependentPresenters.forEach(function(element) {
                    if (element.itemType == "TABLE" && element.filter) {
                        element.filter.updateFilterButtons(buttons);
                    }
                });
            }
        }
    },

    /**
     * Obsługa komunikatu pochodzącego z metody SetFocus
     */
    formCustomAction: function(id, xml){
        var form = this.navigator.formStack.findForm(id);
        if(form){
            form.form.customAction(xml);
        } else {
            console.info("Nie znaleziono formy o id"+id);
        }
    },

     /**
     * funkcja parsuje przekazanego XMLa oraz aplikuje przekazane w nim ustawienia widoku
     */
    viewSettingsChanged: function(id, xml) {
        var uuid = $(xml).attr('UUID');

        var form = this.getFormContainingDataset(id, 'viewSettingsChanged');
        if(form && form.gridFound) {
            var ds = form.datasets.getValue(id);

            //Wyszukuję wszystkie podpięte tabele aby podświeltić na nich przyciski wybranych filtrów
            if (ds.dependentPresenters) {
                ds.dependentPresenters.forEach(function(element) {
                    if (element.itemType == "TABLE") {
                        if (element.filter) element.filter.setCurrentFilter(uuid);
                        if (element.setViewSettings) element.setViewSettings(xml);
                    }
                });
            }
        }
    },

    updateFavorites: function(operation, uuid) {
        switch(operation.toLowerCase()) {
            case 'add':
                this.navigator.addMenuFavorite(uuid);
                break;
            case 'remove':
                this.navigator.removeMenuFavorite(uuid);
                break;
        }
    },

    /**
     * funkcja aktualizuje dataset danej formy
     * @param {string} id - id datasetu
     * @param {string} uuid - uuid pola które modyfikujemy
     * @param {string} symbol - uuid pola słownikowanego
     * @param {string} val - wartość którą chcemy ustawić
     */
    updateData: function (id, uuid, symbol, val) {
        var form = this.getFormContainingDataset(id, 'updateData');
        if (form) {
            var ds = form.datasets.getValue(id);

            //update controlek
            var objects = form.findControlByDataFieldUUID(uuid, id);
            if (objects.length < 1) {
              //sprawdzamy czy w modelu danych jest pole do zaktualizowania
              if (uuid == "$SEARCHFIELD") {
                form.setSearchValue(val);
              }
              else if (uuid == "$SEARCHFIELDNAME") {
                 //$SEARCHFIELDNAME pod WWW jest nieobsługiwany
              }
              else if (!updateModel(ds, uuid, symbol, val)) {
                console.info("nie znaleziono elementow o uuid #" + uuid + " na formie oraz w modelu danych w funkcji 'updateData'");
              }
            }
            else {
                var self = this;
                objects.forEach(function (object) {
                    //object.lockFieldUUID jest flagą która nie ma wartości true false.
                    //W tej fladze znajduje się liczba wywołań funkcji updateData dla
                    //danego obiektu. Możemy dostać dla jedengo obiektu więcej niż 1
                    //updateData (np. zmiana wartości słownikowanej i wartość klucza relacji
                    //w odzielnych komunikatach) wykonywane asynchronicznie przez co flaga
                    //typu bool się nie sprawdzała.
                    if (!object.lockFieldUUID) {
                        object.lockFieldUUID = 0;
                    }

                    object.lockFieldUUID++;
                    self.updateForm(form, object, object.elementUUID, "VALUE", val, symbol);
                    object.lockFieldUUID--;
                });
            }

            //update pol grida
            if (ds.state == "I") {
                //do zrobienia obsługa wielu gridów
                if (ds.dependentPresenters) {
                    var len = ds.dependentPresenters.length;

                    for (var i = 0; i < len; i++) {
                        var grid = ds.dependentPresenters[i];
                        if (grid._effectiveVisible && grid.modelForControl[grid.dataset.getFieldModelKey(uuid)]) {
                            grid.pumpFieldModelToRow(uuid);
                        }
                    }
                }
                else {
                    console.info("Nie znaleziono dependentPresenters dla dataseta");
                }
            }
        }
        else {
            console.info("Nie znaleziono formy zawierającej dataset o podanym ID w funkcji Gui.updateData");
        }

        function updateModel(ds, uuid, duuid, val) {
            var result = false;
            if (ds) {
                //ustalamy klucz
                var key;
                if (duuid && duuid != "") {
                    key = ds.getFieldModelDictKey(uuid, duuid);
                }
                else {
                    key = ds.getFieldModelKey(uuid);
                }

                //sprawdzamy czy istnieje klucz w modelu
                if (ds.model[key] !== undefined) {
                    //aktualizujemy wartość modelu
                    result = true;
                    ds.model.set(key, val);
                }
                else {
                    console.info("Nie znaleziono w modelu danych szukanego pola");
                }
            }
            else {
                console.info("Nie znaleziono dataseta o podanym ID");
            }

            return result;
        }
    },

    //po nazwie zakładam że sprawdza poprawność danych po stronie klienta pisze:
    //"funkcja aktualizuje wartosc pola, gdyz otrzymala je z serwera. W fielduuid moze byc tez uuid elementu formy"
    validateData: function (id, uuid, val) {
        var form = this.getFormContainingDataset(id, 'validateLayoutData');
        if (form) {
            var objects = form.findControlByDataFieldUUID(uuid, id);
            if (!objects) {
                console.info("nie znaleziono elementow o uuid #" + uuid + " w funkcji 'validateLayoutData'");
            }
            else {
                console.info("Funkcja findControlByDataFieldUUID znalezla " + objects.length + " element(ow) o uuid #" + uuid);
                $(objects).each(function () {
                    this.updateValidate(val);
                });
            }
        }
    },

    locateRecord: function (id, uuid, val, symbol) {
        var form = this.getFormContainingDataset(id, 'locateLayoutRecord');
        if (form) {
            var position = parseInt(symbol);
            var ds = form.datasets.getValue(id);
            ds.locateRecord(uuid, val, position);
        }
        else {
            console.error("Nie udało się znaleźć formy zawierającej dataset o id: " + id);
        }
    },

    updateDataset: function (id, symbol, val) {
        var form = this.getFormContainingDataset(id, 'updateDataset');
        if (form) {
            var ds = form.datasets.getValue(id);
            if (ds) {
                if (ds.ourFilter || ds.ourFilter === undefined) {
                    ds.oldFilter = ds.ourFilter;
                }

                ds.ourFilter = $(val).attr("F");
                if(ds.oldFilter != ds.ourFilter)
                    ds.filterWasChanged = true;
                ds.setState($(val).attr("S"), true);
                ds.update();
            }
        }
    },

    notifyData: function (id) {
        var form = this.getFormContainingDataset(id, 'notifyData');
        if (form) {
            var ds = form.datasets.getValue(id);
            if (ds) {
                ds.notifyData(false);
            }
        }
    },

    updateDatasetState: function (id, symbol, val) {
        console.error("Brak jeszcze implemenatcji funkcji: UpdateDatasetState");
    },

    /**
    * Metoda odpowiada za przekazanie sterowania do konkretnych metod reagujących na zdarzenia,
    * które reagują na zmiany zachodzące w datasecie. Zmiany stanu (I,B,E,C), przesuwanie rekordów itp.
    * Zmiany te są również wysyłane do wszystkich zależnych od tego dataseta prezenerów (gridy, drzewa, itp.)
    * @memberof Gui
    * @instance
    * @param {number} id - id dataseta do którego adresowana jest akcja
    * @param {string} symbol - kod akcji którą należy wykonać, zgodnie ze switchem
    * @param {string} [val] - opcjonalnie dodatkowe argumenty do danej akcji
    */
    datasetAction: function (id, symbol, val) {
        var form = this.getFormContainingDataset(id, 'datasetAction');
        var ds = form.datasets.getValue(id);

        switch (symbol) {
            case "I":
                ds.setState(symbol, true);
                break;
            case "B":
            case "E":
                ds.setState(symbol, true);
                break;
            case "C":
                ds.setState("B", true);
                ds.refreshModel();
                break;
            case "P":
                ds.dontNotifyServerAboutChange = true;
                ds.setState("B", true);
                ds.refresh();//tu było niby żeby się na tym rekordzie ustawił ale to i tak dobrze nie działało
                ds.dontNotifyServerAboutChange = false;
                break;
            case "NEXT":
                ds.next();
                break;
            case "PREV":
                ds.prev();
                break;
            case "FIRST":
                ds.first();
                break;
            case "LAST":
                ds.last();
                break;
            default:
                console.info("Przekazano nieznany symbol '" + symbol + "' akcji dataseta");
                break;
        }

        if (ds.dependentPresenters) {
            var len = ds.dependentPresenters.length;
            for (var j = 0; j < len; j++) {
                var presenter = ds.dependentPresenters[j];
                if (presenter._effectiveVisible) {
                    switch (symbol) {
                        case "I":
                            presenter.datasetActionAdd();
                            break;
                        case "D":
                            presenter.datasetActionDelete();
                            break;
                        case "E":
                            presenter.datasetActionEdit();
                            break;
                        case "P":
                            presenter.datasetActionPost();//tu było niby żeby się na tym rekordzie ustawił ale to i tak dobrze nie działało
                            break;
                        case "C":
                            presenter.datasetActionCancel();
                            break;
                        case 'SR':
                            DatasetActionSelect(ds, presenter);
                            break;
                        case 'DR':
                            DatasetActionDeselect(ds, presenter);
                            break;
                        case 'SA':
                            DatasetActionSelectAll(ds, presenter);
                            break;
                        case 'CS':
                            DatasetActionClearSelection(ds, presenter);
                            break;
                        default:
                            console.info("Przekazano nieznany symbol '" + symbol + "' akcji dataseta");
                    }
                }
            }
        }

        function DatasetActionSelect(ds, presenter) {
            presenter.multiselectRow();
        }

        function DatasetActionDeselect(ds, presenter) {
            presenter.multideselectRow();
        }

        function DatasetActionSelectAll(ds, presenter) {
            presenter.selectAll();
        }

        function DatasetActionClearSelection(ds, presenter) {
            presenter.clearAllSelection();
        }
    },

    notifyLayoutData: function (id) {
        console.info("Brak jeszcze implemenatcji funkcji: NotifyLayoutData");
    },

    callFunction: function (id, uuid, symbol, val) {
        //sprawdzamy czy mamy otworzyc plik
        if (symbol == "ShellExecute") {
            var params = {};
            val.split(';').forEach(p => {
                var index = p.indexOf("=");
                var key = p.substring(0, index).toUpperCase();
                var value = p.substring(index + 1);
                params[key] = value;               
            });
            var file = params["FILE"];
            var command = params["COMMAND"];

            if (file.match("^http") || file.match("^mailto")) {
                if (command == 'open') {
                    window.open(file);
                } else if (command == 'print') {
                    var win = window.open(file);
                    win.print();
                }
            } else {
                alert("Nie można otworzyć pliku z poziomu przeglądarki");
            } 
        }
        if (symbol == "RefreshNavigator") {
            this.communication.BP.getNavigator();
        }
    },

    callAction: function (id, uuid, symbol, val) {
        console.info("Brak jeszcze implemenatcji funkcji: CallAction");
    },

    getResources: function (val) {
        var resource = {
            objectName: $(val).attr("objectname"),
            resourceType: $(val).attr("resourcetype"),
            resource: val,
            Id: $(val).attr("objectname") + "." + $(val).attr("resourcetype"),
        };

        App.Resources.add(resource);
    },

    copySizeFromParent: function (selector, parent) {
        var matched = $(selector, parent);
        matched.attr("style", "width:100%;height:100%;")
    },

    //Funkcja odpowiedzialna za obsługe klikniecia w przycisk
    buttonClick: function (actioninstanceid) {
        this.communication.BP.callAction(actioninstanceid);
    },

    initNavigator: function (xml) {
        this.navigator.init($('#SNAVIGATOR'), xml);
        this.navigator.formStack = FormStack;
        this.navigator.formStack.init(this.navigator);
        this.navigator.initialized = true;
    },

    getNotifications: function () {
        var self = this;
        window.setInterval(function () { self.communication.BP.getNotifications() }, this.notificationInterval);
    },

    createRandomData: function (count) {
        var imiona = ["Marcin", "Kuba", "Bartek", "Lukasz", "Piotrek", "Justyna", "Monika", "Gosia"];
        var nazwiska = ["Kowalski", "Nowak", "Bond"];
        var lata = ["12", "23", "34", "45", "56", "67", "78", "89"];
        var miasta = ["Wrocław", "Kraków", "Warszawa", "Poznań", "Katowice", "Gdańsk", "Lublin", "Częstochowa"];
        var ulice = ["Gwiazdzista", "Rychtalska", "Traugutta", "Jedności Narodowej", "Kazimierza Wielkiego", "Puławksiego", "Prądzyńskiego", "Tadeusza Kościuszki"];
        var datasource = [];

        for (var i = 0; i < count; i++) {
            var rnd = Math.ceil(Math.random() * 8) - 1;
            var imie = imiona[rnd];
            var rnd = Math.ceil(Math.random() * 3) - 1;
            var nazwisko = nazwiska[rnd];
            var rnd = Math.ceil(Math.random() * 8) - 1;
            var wiek = lata[rnd];
            var rnd = Math.ceil(Math.random() * 8) - 1;
            var miasto = miasta[rnd];
            var rnd = Math.ceil(Math.random() * 8) - 1;
            var ulica = ulice[rnd];

            var osoba = new Object();
            osoba.imie = imie;
            osoba.nazwisko = nazwisko;
            osoba.wiek = wiek;
            osoba.miasto = miasto;
            osoba.ulica = ulica;

            datasource.push(osoba)
        }

        var object = { attr: 'a', attr1: 'b' }
        return datasource;
    },

    /**
     * Funkcja ustawia rozmiar glownego obszaru okien
     */
    resizeMiddlePane: function (width) {
        var bodyHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        var bodyWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        var navHeight = $(".STOOLBAR").outerHeight(true) || 0;
        var menuWidth = $("#mainMenu").outerWidth(true) || 0;

        $("div#middle-pane").height(bodyHeight - navHeight);
        $("div#middle-pane").css('min-width', width || bodyWidth - menuWidth);
    },

    /**
     * Funkcja przełącza przeglądarkę w tryb pełnego ekranu
     */
    requestFullScreen: function () {
      var doc = window.document;
      var docEl = doc.documentElement;

      var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;

      if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
        requestFullScreen.call(docEl);
      }
    },

    /**
     * Funkcja wychodzi z trybu pełnego ekranu
     */
    cancelFullScreen: function () {
      var doc = window.document;
      var docEl = doc.documentElement;

      var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

      if (doc.fullscreenElement || doc.mozFullScreenElement || doc.webkitFullscreenElement || doc.msFullscreenElement) {
        cancelFullScreen.call(doc);
      }
    },


    /**
     * Funkcja po zakonczeniu zmiany rozmiaru okna odpala funkcje resizeAll
     */
    resizing: function () {
        if (this.resizeTimer) {
            window.clearTimeout(this.resizeTimer);
        }
        this.resizeTimer = window.setTimeout(this.resizeAll, 200, this);
    },

    /**
     * Funkcja zmienia wymiary middle-pane po czym odświeża wszystkie formy
     * @param {object} target - obiekt gui
     */
    resizeAll: function (target) {
        if (target.resizeTimer) {
            window.clearTimeout(target.resizeTimer);
        }
        target.resizeMiddlePane();
        if (App.gui.navigator) {
          App.gui.navigator.resizeContent();
        }

    },

    setCssStyle: function () {
      var newStyle = Utils.operationsUrlParameters.getURLParameterValue("style");
      if (newStyle) {
        //ustalamy styl wg atrybutu ustawionego recznie
        if (newStyle == "tablet") {
          this.mobileVersion = true;
          this.device.tablet = true;
          this.device.mobile = false;
        } else if (newStyle == "mobile") {
          this.mobileVersion = true;
          this.device.tablet = false;
          this.device.mobile = true;
        } else {
          this.mobileVersion = false;
          this.device.tablet = false;
          this.device.mobile = false;
        }
      }
      this.currentCss = $('<link type="text/css" rel="stylesheet"/>');

      if (this.mobileVersion) {
          if (App.Settings.DevMode == "yes")
            this.currentCss.attr('href', App.Settings.CssVirtualPath + 'mobile.css');
          else
            this.currentCss.attr('href', App.Settings.CssVirtualPath + 'mobile.min.css');
          this.selectedCss = "mobileCss";
        //}
      } else {
        if (App.Settings.DevMode == "yes")
          this.currentCss.attr('href', App.Settings.CssVirtualPath + 'desktop.css');
        else
          this.currentCss.attr('href', App.Settings.CssVirtualPath + 'desktop.min.css');
        this.selectedCss = "desktopCss";
      }

      $('head').append(this.currentCss);
    },

    /**
     * Funkcja sluzy do dynamicznego dodawania plikow css skorek. Wybrane pliki css
     * zaleza od urzadzenia na jakim strona jest uruchomiona oraz czy aplikacja
     * zostala uruchomiona w trybie deweloperskim (nie uzywa plikow min).
     */
    setSkin: function () {
        //pobieramy z parametru skorke którą mamy wyświetlić
        var newSkin = Utils.operationsUrlParameters.getURLParameterValue("skin");
        var kendosentecss = 'kendo.sente.min.css';
        var kendosentedarkcss = 'kendo.sente-dark.min.css';
        var desktopbasiccss = 'sente.desktop.extension.min.css';
        var desktopdarkcss = 'sente.desktop.extension.dark.min.css';
        if (App.Settings.DevMode == "yes") {
            kendosentecss = 'kendo.sente.css';
            kendosentedarkcss = 'kendo.sente-dark.css';
            desktopbasiccss = 'sente.desktop.extension.css';
            desktopdarkcss = 'sente.desktop.extension.dark.css';
        }
        this.currentSkinCss = $('<link type="text/css" rel="stylesheet" />');
        this.currentSkinCss2 = $('<link type="text/css" rel="stylesheet" />');

        //Jeżeli w url nie podano skórki to ustawiamy domyślnie jasną
        if (!newSkin) {
            this.currentSkinCss.attr('href', App.Settings.CssVirtualPath + kendosentecss);
            if (!App.gui.mobileVersion) {
                this.currentSkinCss2.attr('href', App.Settings.CssVirtualPath + desktopbasiccss)
            }
            this.selectedSkin = "sente";
        } else {
            //tworzymy style dla podanej skórki
            var skin = $('<link type="text/css" rel="stylesheet" />');

            if (newSkin == "dark") {
                skin.attr('href', App.Settings.CssVirtualPath + kendosentedarkcss);
                this.currentSkinCss2.attr('href', App.Settings.CssVirtualPath + kendosentedarkcss)
                if (!App.gui.mobileVersion) {
                    this.currentSkinCss2.attr('href', App.Settings.CssVirtualPath + desktopdarkcss)
                }
                this.selectedSkin = "dark";
            }
            else {
                skin.attr('href', App.Settings.CssVirtualPath + kendosentecss);
                this.currentSkinCss2.attr('href', App.Settings.CssVirtualPath + kendosentecss)
                if (!App.gui.mobileVersion) {
                    this.currentSkinCss2.attr('href', App.Settings.CssVirtualPath + desktopbasiccss)
                }
                this.selectedSkin = "sente";
            }

            //usuwamy style dla obecnej skórki
            this.currentSkinCss.remove();

            //podpinamy style dla nowej skórki
            this.currentSkinCss = skin;
        }

        //dodajemy style do strony
        $('head').append(this.currentSkinCss);
        $('head').append(this.currentSkinCss2);
    },

    setProfileCss: function() {
        var profile = App.communication.getProfile();
        if (profile != "") {
            this.currentProfileCss = $('<link type="text/css" rel="stylesheet" />');
            this.currentProfileCss.attr('href', App.Settings.VirtualPath+'/$CSS$PROFILE/profile.css?profile='+profile);
            $('head').append(this.currentProfileCss);
        }
    },

    setStructure: function () {
        this.selectedStructure = "loose";
        this.structureMargin = 2 * this.margin;
        this.paddingForArtificialPanel = 16;
        this.setGlobalSearch();
    },

    setGlobalSearch: function() {
      if(typeof App.Settings.ProfileGlobalSearch != 'undefined'
        && typeof this.navigator != 'undefined') {
        if(App.Settings.ProfileGlobalSearch == 'yes') {
          this.navigator.enableSearch();
        } else {
          this.navigator.disableSearch();
        }
      }
    },

    fillLoginFromLocalStorage: function()
    {
        if ($("#tbLogin").length > 0) {
            var loginFromAppStorage = App.localStorage.get("login"+this.communication.getProfile());
            if (loginFromAppStorage && loginFromAppStorage.length > 0) {
                $("#tbLogin").val(loginFromAppStorage);
            } 
        }
    },

    setFocusOnLoginForm: function () {
        if ($("#tbLogin").length > 0) {

            var login = $("#tbLogin").val();
            if(login.length > 0)
            {
                $("#tbHaslo").focus();
            }
            else
            {
                $("#tbLogin").focus();
            }
            
        }
    },

    handleLoginChange: function() {
      var self = this
      var $divisions = $("#tbOddzial")
      var login = $("#tbLogin").val();
      var loginButton = $("#btnLogin1");
      if(!login && App.Settings.ProfileUsesDepartments == "yes")
      {
        loginButton.prop("disabled", true);
      }
      else
      {
        loginButton.prop("disabled", false);
      }
      if ($(this).data("lastval") != login) {
        $(this).data("lastval", login);
        if(self.timerid)
          clearTimeout(self.timerid);

        if(!login || loginButton.length==0) {
          var dropDown = $divisions.data("kendoDropDownList")
          if(dropDown) {
            var datasource = [];
            dropDown.setDataSource(datasource)
            dropDown.select(0)
          }
        } else {
          self.timerid = setTimeout(function() {
            App.communication.Common.getDivisionsForUser(login)
          }, 500);
        }
      };
    },

    enableDepartments: function() {
      var self = this
      if(App.Settings.ProfileUsesDepartments == "yes") {
        $("#pOddzial").removeClass('Hide')
        var datasource = [];
        var $divisions = $("#tbOddzial")
        $divisions.kendoDropDownList({
            dataSource: datasource
          });
        $("#pOddzial").css('visibility','hidden');
        $("#tbLogin").on("input", self.handleLoginChange.bind(this));

      } else {
        //domyslnie ukryte
      }
    },
    
    enableLanguages: function() {
        $(document).ready(function() {
            var $languages = $("#tbLanguages")
            $languages.kendoDropDownList({
                change: function(e) {
                    var url = new URL(window.location);
                    url.searchParams.set("lang", this.value());
                    window.location = url.href;
                }
            });
            var url = new URL(window.location);
            const urlParams = new URLSearchParams(url.search);
            const lang = urlParams.get("lang");
            if(!lang) {
                url.searchParams.set("lang", $languages.val());
                window.location = url.href;
            } else if($languages.val() !== lang) {
                var dropdown = $languages.getKendoDropDownList();
                dropdown.value(lang);
                dropdown.trigger("change");
            }
        });
    },

    init: function (callback) {
        if (!this.initialized) {
            //ważna kolejność. Ostatni ma być setCssStyle ponieważ w nim jest wywoływana funkcja run
            this.setSkin();
            this.setProfileCss();
            this.setStructure();
            this.setCssStyle();
            this.initialized = true;
            
            if(callback)
            {   // Uruchamiamy callback po pełnym załadowaniu css oraz js (strony)
                // Rozwiązuje problem ładowania storny na Firefox
                $(document).ready(function() { callback() });   
            }
        }
    },

    registerDatavizTheme: function () {
        kendo.dataviz.ui.registerTheme('SenteDark', {
            "chart": {
                "title": {
                    "color": "#ffffff"
                },
                "legend": {
                    "labels": {
                        "color": "#ffffff"
                    }
                },
                "chartArea": {
                    "background": "#070707"
                },
                "seriesDefaults": {
                    "labels": {
                        "color": "#8a8a8a"
                    }
                },
                "axisDefaults": {
                    "line": {
                        "color": "#3f3f3f"
                    },
                    "labels": {
                        "color": "#8a8a8a"
                    },
                    "minorGridLines": {
                        "color": "#3f3f3f"
                    },
                    "majorGridLines": {
                        "color": "#3f3f3f"
                    },
                    "title": {
                        "color": "#8a8a8a"
                    }
                },
                "seriesColors": [
                    "#ffa810",
                    "#cc2167",
                    "#d5d7d8",
                    "#686868",
                    "#00b050",
                    "#00b0f0"
                ],
                "tooltip": {
                    "background": "",
                    "color": "",
                    "opacity": 1
                }
            },
            "gauge": {
                "pointer": {
                    "color": "#ffa810"
                },
                "scale": {
                    "rangePlaceholderColor": "#3f3f3f",
                    "labels": {
                        "color": "#8a8a8a"
                    },
                    "minorTicks": {
                        "color": "#3f3f3f"
                    },
                    "majorTicks": {
                        "color": "#3f3f3f"
                    },
                    "line": {
                        "color": "#3f3f3f"
                    }
                }
            }
        });
    },
    /**
    Funkcja ustawia stopkę na samym dole strony
    */
    setFooterInCorrectPlace: function () {
      var middlePaneHeight = $("#middle-pane").height() || 0;
      var footerHeight = $('.footer').height() || 0;
      var margin_top = middlePaneHeight - footerHeight;
        $('.footer').css('margin-top', margin_top);
    },

    validateLoginPage: function() {
      var $login = $('#tbLogin')
      var login = $login.val()
      var loginok = false
      var $pass = $('#tbHaslo')
      var pass = $pass.val()
      var passok = false

      if(!pass || pass.length==0) {
        $pass.addClass("validationError");
      } else {
        passok = true
        $pass.removeClass("validationError");
      }

      if(!login || login.length==0) {
        $login.addClass("validationError");
      } else {
        loginok = true
        $login.removeClass("validationError");
      }

      return loginok && passok;
    },

    /**
    * Metoda odpowiada za wygląd strony logowania. Jeżeli logowano się na profil pobiera jego nazwę
    * z serwera. Jeżeli znajdzie dla profilu ciasteczko wydłużonego logowania, ukrywa pola do wpisywania
    * loginu i hasła i wyświetla stosowny komunikat.
    * @instance
    */
    handleLoginPage: function () {
        var profile = Utils.operationsUrlParameters.getURLParameterValue("profile");

        if (!profile) {
            profile = App.Settings.Profile;
            if (profile) {
              Utils.operationsUrlParameters.addParam("profile", profile);
              //MS: tu był refreshUrl ale jest niepotrzebny bo w sytuacji istnienia profilu domyślnego w pliku smd pojawiało się wywołanie:
              //adres:9001//?profile=xxx
            }
        }

        if (profile) {
            App.communication.BP.getProfileName(profile);
        }
        else {
            $("#labelProfileName").text("");
            $("#labelProfileName").addClass("Hide");
            $("#mainMenuTitle").text("");
        }

        var self = this;

        $('#tbHaslo').pressEnter(doLog);

        $('#btnLogin1').click(doLog);

        $('#tbLogin-RetrievePassword').pressEnter(doSendChangePassword);

        $('#btnSend-RetrievePassword').click(doSendChangePassword);

        $('#retrievepassword').click(function(){ Utils.redirectWithHistory(location.origin + "/" + "retrievepassword.html");});

        var $btnSwitch = $('#btnSwitchLoginMetod');

        if($btnSwitch.length>0) {
          $('#loginButtons').css('margin-bottom','40px');
        }

        $btnSwitch.click(function(){
          $('#loginButtons').css('margin-bottom','0px');
          $("#standardLogin").show();
          $('#btnSwitchLoginMetod').hide();
          $('#btnLogin1').text('Zaloguj kontem Sente');
        })

        /*
        * funkcja loguje do aplikacji, albo zakłada, że mamy prawo od razu wyświetlić stronę główną
        */
        function doLog(event) {
          if(self.validateLoginPage()) {
            var $login = $('#tbLogin')
            var login = $login.val()
            var $pass = $('#tbHaslo')
            var pass = $pass.val()
            var $oddzial = $('#tbOddzial')
            var oddzial = $oddzial.val();
            var $lang = $('#tbLanguages')
            var lang = $lang.val();
            self.login(login, pass, oddzial, lang);
          }
        }

        function doSendChangePassword(event) {
            var login = $('#tbLogin-RetrievePassword').val();
            var lang = Utils.operationsUrlParameters.getURLParameterValue("lang");
            self.sendChangePasswordEmail(login,lang);
            alert(App.LanguageResources.resetPasswordMailSent);
            Utils.redirectWithHistory(location.origin + "/login.html");
        }
        this.enableDepartments();
        this.enableLanguages();

        this.fillLoginFromLocalStorage();
        this.setFocusOnLoginForm();
        var login = $("#tbLogin").val();
        this.handleLoginChange();
        if(login)
        {
            App.communication.Common.getDivisionsForUser(login);
        }
    },
    handleResetPage: function () {
        var profile = Utils.operationsUrlParameters.getURLParameterValue("profile");
        var resetToken = Utils.operationsUrlParameters.getURLParameterValue("token");

        if (!profile) {
            profile = App.Settings.Profile;
            if (profile) {
              Utils.operationsUrlParameters.addParam("profile", profile);
            }
        }

        if (profile) {
            App.communication.BP.getProfileName(profile);
        }
        else {
            $("#labelProfileName").text("");
            $("#labelProfileName").addClass("Hide");
            $("#mainMenuTitle").text("");
        }

        var self = this;

        $('#loginParams-PasswordReset').pressEnter(doReset);

        $('#btnChange-PasswordReset').click(doReset);

        var $btnSwitch = $('#btnSwitchLoginMetod');

        if($btnSwitch.length>0) {
          $('#loginButtons').css('margin-bottom','40px');
          $('#loginButtons').css('margin-top','20px');
        }

        function doReset(event) {
            var pass = $('#tbHaslo-PasswordReset').val()
            var passRepeated = $('#tbPowtorz-PasswordReset').val()
            if(!pass || !passRepeated || pass != passRepeated) {
                alert(App.LanguageResources.passwordsDontMatch);
            }
            else if(pass == passRepeated){
                self.reset(resetToken, pass);
            }
        }

        this.fillLoginFromLocalStorage();
        this.setFocusOnLoginForm();
    },
    /**
    * funkcja wyświetla dynamicznie zbudowane popupMenu.
    */
    showPopupMenu: function(id, elementUUID, val) {

        var form = this.getFormContainingDataset(id, 'showPopupMenu');
        var ds = form.datasets.getValue(id);
        var parentObj = ds.findInActionsObjectByElementUUID(elementUUID);
        var xml = $('div',val);
        var actID, actionuuid, label, icontext;

        // Jeśli nie znalazłem parenta to szukaj w drzewie DOM jako <button ....
        if(!parentObj){
            parentObj = $(document.getElementById(elementUUID))[0];
        } else
            parentObj = parentObj.objectElement[0];

        // Jeśli nie udało się wyszukać elementu który kliknąłem to dodaje nowy pusty
        // a pozycje X, Y pobiorę z ostatniego kliknięcia. Dynamiczne menu wywołane z popupMenu.
        if(!parentObj)
            parentObj = $('');

        parentObj.menu = $('<ul id="dynamicPopupMenu"/>');

        for (var i = 0; i < xml.length; i++) {
            actID = ($(xml[i]).attr('ID'));
            actionuuid = ($(xml[i]).attr('UUID'));
            label = ($(xml[i]).attr('L'));
            icontext = ($(xml[i]).attr('I'));

            // Tworze DatasetAction oraz dodaje do dataseta formy
            var action = ds.createDatasetAction(xml[i]);
            var li = $("<li data-bind='events:{ click: action_" + actID + "}' id='" + actID + "' actionInstanceId='" + actID + "' uuid = '" + actionuuid + "'><label>" + label + "</label></li>");
            var icon = Utils.getSpriteIcon(icontext, true);
            li.prepend(icon);
            $(li).data("SMenuButton", action);
            if (action.beginGroup == "Y") {
                li.addClass('begin-group');
            }
            parentObj.menu.append(li);

            //Dodajemy akcję do modelu aby kendo.bind działało
            ds.model[ds.getFieldModelActionKey(action.actionInstanceID)] = ds.model.modelFunction;
        }

        kendo.bind(parentObj.menu, ds.model);

        parentObj.popup = $("<div class='menuPopup'/>");
        parentObj.popup.append(parentObj.menu);

        $(parentObj.popup).kendoPopup({
            anchor: parentObj.menu,
            animation: false,
            origin: "bottom right",
            position: "top right",
            deactivate: function(e) {
                //usuwam z drzewa DOM dodane popupMenu
                //usuwam parenta bo kendo diva z animacją
                parentObj.popup.parent().remove();
            },
            activate: function(e) {
                parentObj.popup.parent().css("zIndex", 100000);
            }
        }).data("kendoPopup");

        $(document.body).append(parentObj.popup);

        parentObj.kendoPopup = parentObj.popup.data("kendoPopup");
        $(parentObj.menu).kendoMenu(
        {
            orientation: "vertical",
            animation: false,
            select: function onselect(e) {
                parentObj.kendoPopup.wrapper.hide();
                // event deactive nie jest uruchamiany po wykonaniu hide()
                parentObj.popup.parent().remove();
            }
        });

        // Pobieram pozycje elementu na oknie
        var myPos = this.getPosition(parentObj);
        if(myPos.x > 0 || myPos.y > 0)
            parentObj.kendoPopup.open(myPos.x, myPos.y);
        else
         // Jeśli nie udało się znaleść x, y to biorę ostatnie kliknięcie X, Y
            parentObj.kendoPopup.open(this.mouseLastPosX, this.mouseLastPosY);
    },

    //Zamyka popup menu.
    closePopupMenu: function() {
        $("ul.popupMenu").hide();
    },

    // Funkcja wylicza pozycje X Y przekazanego elementu
    getPosition: function(el) {
      var xPos = -1;
      var yPos = -1;
      // Dodaję wysokość samego elementu oraz odejmuję pixel aby dokleić do ramki przycisku.
      yPos += el.offsetHeight;
      while (el) {
        if (el.tagName == "BODY") {
          var xScroll = el.scrollLeft || document.documentElement.scrollLeft;
          var yScroll = el.scrollTop || document.documentElement.scrollTop;

          xPos += (el.offsetLeft - xScroll + el.clientLeft);
          yPos += (el.offsetTop - yScroll + el.clientTop);
        } else {
          xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
          yPos += (el.offsetTop - el.scrollTop + el.clientTop);
        }

        el = el.offsetParent;
      }
      return {
        x: xPos,
        y: yPos
      };
    }
}

function run() {//nie zmieniać nazwy funkcji
  App.communication.BP.getNeosVersion();
  $(document).ready(function () {
      App.gui.handleLoginPage();
      App.gui.handleResetPage();
  })
}

function onSignInByGoogle(googleUser) {
App.gui.loginByGoogle(googleUser);
}

function initialize(context) {
  Utils.checkIfBrowserIsSupported();
  Utils.operationsUrlParameters.initParamList();

  $.fn.pressEnter = function (fn) {
      return this.each(function () {
          $(this).bind('enterPress', fn);
          $(this).keyup(function (e) {
              if (e.keyCode == 13) {
                  $(this).trigger("enterPress");
              }
          })
      });
  };

  context.App = new App(true);
  context.App.setReloadConfirmationShown(true);
  context.App.communication.BP.getSettings();
}

initialize(window);
